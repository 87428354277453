export const PUT_CLIENTDETAILS_REQUEST = "PUT_CLIENTDETAILS_REQUEST";
export const PUT_CLIENTDETAILS_SUCCESS = "PUT_CLIENTDETAILS_SUCCESS";
export const PUT_CLIENTDETAILS_FAILURE = "PUT_CLIENTDETAILS_FAILURE";

export const GET_PREVIEW_SUCCESS = "GET_PREVIEW_SUCCESS";

export const GET_CLIENT_REQUESTS_FOR_CLIENT_REQUEST =
  "GET_CLIENT_REQUESTS_FOR_CLIENT_REQUEST";
export const GET_CLIENT_REQUESTS_FOR_CLIENT_SUCCESS =
  "GET_CLIENT_REQUESTS_FOR_CLIENT_SUCCESS";
export const GET_CLIENT_REQUESTS_FOR_CLIENT_FAILURE =
  "GET_CLIENT_REQUESTS_FOR_CLIENT_FAILURE";

export const GET_LIST_OF_PROFILES_REQUEST = "GET_LIST_OF_PROFILES_REQUEST";
export const GET_LIST_OF_PROFILES_SUCCESS = "GET_LIST_OF_PROFILES_SUCCESS";
export const GET_LIST_OF_PROFILES_FAILURE = "GET_LIST_OF_PROFILES_FAILURE";

export const GET_LIST_OF_SHORTLISTED_PROFILES_REQUEST =
  "GET_LIST_OF_SHORTLISTED_PROFILES_REQUEST";
export const GET_LIST_OF_SHORTLISTED_PROFILES_SUCCESS =
  "GET_LIST_OF_SHORTLISTED_PROFILES_SUCCESS";
export const GET_LIST_OF_SHORTLISTED_PROFILES_FAILURE =
  "GET_LIST_OF_SHORTLISTED_PROFILES_FAILURE";    


