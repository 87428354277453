/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_ALL_REFERENCE_REQUEST,
  GET_ALL_REFERENCE_SUCCESS,
  GET_ALL_REFERENCE_FAILURE,
} from "./referenceTypes";

import axios from "axios";
import Config from "../../config/index";
let auth3Url = Config.auth3Url;
let baseUrl = Config.baseUrl;

const getAllReferenceRequest = () => {
  return {
    type: GET_ALL_REFERENCE_REQUEST,
  };
};

const getAllReferenceSuccess = (payload) => {
  return {
    type: GET_ALL_REFERENCE_SUCCESS,
    payload: payload,
  };
};

const getAllReferenceFailure = (error) => {
  return {
    type: GET_ALL_REFERENCE_FAILURE,
    payload: error,
  };
};

const getAllReference = () => {
  return (dispatch) => {
    dispatch(getAllReferenceRequest());
    let url = `${baseUrl}/references/get_all_references`;
    return axios
      .get(url)
      .then((response) => {
        dispatch(getAllReferenceSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getAllReferenceFailure(err.message));
        return { status: false };
      });
  };
};

export { getAllReference };
