// /*********************
//  * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
//  * __________________
//  *
//  *  Mobillor Technologies Pvt. Ltd.
//  *  All Rights Reserved.
//  *
//  * NOTICE:  All information contained herein is, and remains
//  * the property of Mobillor Technologies Pvt. Ltd.
//  * The intellectual and technical concepts contained
//  * herein are proprietary to Mobillor Technologies Pvt. Ltd.
//  * may be covered by Rebublic Of India and Foreign Patents,
//  * patents in process, and are protected by trade secret or copyright law.
//  * Dissemination of this information or reproduction of this material
//  * is strictly forbidden unless prior written permission is obtained
//  * from Mobillor Technologies Pvt. Ltd.
//  */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import TextError from "utils/TextError";

//  formik and yup
import { useFormik } from "formik";
import * as Yup from "yup";

//  react select and styling
import Select from "react-select";
import "../../index.css";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import {
  getAssignClient,
  clientRegister,
  getProfiles,
  createPofile,
  assignProfiles,
} from "../../store";

// Aws & uuid
import AWS from "aws-sdk";
import { v4 } from "uuid";

//  Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

const AssignClient = ({
  assignClientReducer,
  getAssignClient,
  clientRegister,
  createPofile,
  getProfiles,
}) => {
  const styleForCreateClient = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    boxShadow: 24,
    p: 1,
    pt: 2,
  };

  const styleForAssign = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    boxShadow: 24,
    p: 1,
    pt: 2,
  };

  const deleteStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    boxShadow: 24,
    p: 2,
    pt: 3,
  };

  let data = cookies.get("mobillor-talent-accelerator-user-data");
  let email = data?.data?.data?.email;
  let jwtToken = data?.data?.jwtToken;

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearch(globalFilter);
  };

  useEffect(() => {
    getAssignClient(email, jwtToken, pageNo, rowsPerPage);
  }, [getAssignClient, pageNo, rowsPerPage]);

  useEffect(() => {
    getProfiles(jwtToken);
  }, [getProfiles]);

  const [state, setState] = useState({
    columns: [
     
      { Header: "Company Name", accessor: "companyName"},
      { Header: "Contact  Person", accessor: "contactPerson" ,alignment: "center"},
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Contact  Number", accessor: "contactNo", align: "center" },
      // { Header: "Action", accessor: "action", align: "center" },
    ], 
    rows: [], 
  });

  const { columns, rows } = state;

  let loading = assignClientReducer.loading;
  const [paginationObj, setPaginationObj] = useState({});
  useEffect(() => {
    let tempClients = [];
    let data = assignClientReducer?.clientData?.data
      ? assignClientReducer?.clientData?.data
      : [];
    if (assignClientReducer?.clientData?.data?.length) {
      setPaginationObj(assignClientReducer?.clientData?.pagination);
      data?.map((data) => {
        const singledata = {
          email: data.email,
          companyName: data.companyName,
          contactPerson: data.contactPerson ,
          contactNo: data.contactDetails.contactNo , 
          dataName: data.userName,
        };
        tempClients.push(singledata);
      });
    }
    setState({ ...state, rows: tempClients });
  }, [assignClientReducer]);

  const [openCreateClientModal, setOpenCreateClientModal] = useState(false);

  const handleOpenCreateClientModal = () => {
    setOpenCreateClientModal(true);
  };

  const handleCloseCreateClientModal = () => {
    setOpenCreateClientModal(false);
  };

  const [isSuccessCreateClient, setIsSuccessCreateClient] = useState(false);
  const [isErrorCreateClient, setIsErrorCreateClient] = useState(false);
  useEffect(() => {
    if (isSuccessCreateClient) {
      setTimeout(() => {
        setIsSuccessCreateClient(false);
      }, 3000);
    }
  }, [isSuccessCreateClient]);

  useEffect(() => {
    if (isErrorCreateClient) {
      setTimeout(() => {
        setIsErrorCreateClient(false);
      }, 3000);
    }
  }, [isErrorCreateClient]);

  const initialValues = {
    companyName: "",
    dataName: "",
    email: "",
  };

  const validationSchema = Yup.object({
    companyName: Yup.string().required("Enter the company name"),
    dataName: Yup.string().required("Enter the full name"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the email"),
  });

  const onSubmit = async (values, { resetForm }) => {
    let response = await clientRegister(values, jwtToken);
    if (response.status === true) {
      setIsSuccessCreateClient(true);
      getAssignClient(email, jwtToken, pageNo, rowsPerPage);
      resetForm();
    }
    if (response.status === false) {
      setIsErrorCreateClient(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading ? (
        <>
          <MDBox pt={2} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                    mb={-6}
                  >
                    <MDBox>
                      <MDTypography variant="h4" gutterBottom>
                        Clients
                      </MDTypography>
                    </MDBox>

                    <MDBox display="flex">
                      <MDBox color="text" px={2}>
                        <Tooltip title="Create Client">
                          <MDButton
                            color="info"
                            variant="outlined"
                            type="button"
                            onClick={() => {
                              handleOpenCreateClientModal();
                            }}
                          >
                            Create Client
                          </MDButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      canSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                      serverSidePagination={true}
                      paginationObj={paginationObj}
                      pageNoValue={pageNo}
                      rowsPerPageValue={rowsPerPage}
                      pageNoProp={importPageNo}
                      rowsPerPageProp={importRowsPerPage}
                    />
                  </MDBox>
                  <Modal
                    open={openCreateClientModal}
                    onClose={handleCloseCreateClientModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <MDCard sx={styleForCreateClient}>
                      <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          flexGrow={1}
                        >
                          Create Client
                        </MDTypography>
                        <MDBox>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={handleCloseCreateClientModal}
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                      </MDBox>

                      <hr />

                      <MDBox pt={1} pb={3} px={1}>
                        <MDBox
                          component="form"
                          role="form"
                          onSubmit={formik.handleSubmit}
                        >
                          <MDBox mb={2}>
                            <MDTypography
                              mt={1}
                              variant="h6"
                              fontWeight="medium"
                              flexGrow={1}
                              style={{ fontWeight: "500" }}
                            >
                              Full Name
                            </MDTypography>
                            <MDInput
                              type="text"
                              name="dataName"
                              fullWidth
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.dataName}
                              error={
                                formik.touched.dataName &&
                                formik.errors.dataName &&
                                true
                              }
                            />
                            {formik.touched.dataName &&
                              formik.errors.dataName && (
                                <TextError msg={formik.errors.dataName} />
                              )}
                          </MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              mt={1}
                              variant="h6"
                              fontWeight="medium"
                              flexGrow={1}
                              style={{ fontWeight: "500" }}
                            >
                              Company Name
                            </MDTypography>
                            <MDInput
                              type="text"
                              name="companyName"
                              fullWidth
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.companyName}
                              error={
                                formik.touched.companyName &&
                                formik.errors.companyName &&
                                true
                              }
                            />
                            {formik.touched.companyName &&
                              formik.errors.companyName && (
                                <TextError msg={formik.errors.companyName} />
                              )}
                          </MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              mt={1}
                              variant="h6"
                              fontWeight="medium"
                              flexGrow={1}
                              style={{ fontWeight: "500" }}
                            >
                              Email
                            </MDTypography>
                            <MDInput
                              type="email"
                              name="email"
                              fullWidth
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              error={
                                formik.touched.email &&
                                formik.errors.email &&
                                true
                              }
                            />
                            {formik.touched.email && formik.errors.email && (
                              <TextError msg={formik.errors.email} />
                            )}
                          </MDBox>
                          <MDBox>
                            <Collapse in={isErrorCreateClient}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorCreateClient(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Please check the details. Server error!
                              </Alert>
                            </Collapse>
                            <Collapse in={isSuccessCreateClient}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessCreateClient(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Client created successfully
                              </Alert>
                            </Collapse>
                          </MDBox>
                          <MDBox mt={2}>
                            <MDButton
                              variant="contained"
                              color="info"
                              fullWidth
                              type="submit"
                              disabled={!formik.isValid}
                            >
                              Submit
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDCard>
                  </Modal>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      ) : (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {!loading ? <p className=" font-size text-center text-md-left">
          Developed and maintained by :
          <a className="link" href="http://mobillor.com/">
            <strong style={{ color: "red" }}>
              {" "}
              Mobillor Technologies Pvt. Ltd.
            </strong>
          </a>
        </p> : null}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    allAccountManagerReducer: state.allAccountManagerReducer,
    assignClientReducer: state.assignClientReducer,
    profilesReducer: state.profilesReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getAssignClient: (email, jwtToken, pageNo, rowsPerPage) =>
      dispatch(getAssignClient(email, jwtToken, pageNo, rowsPerPage)),
    clientRegister: (payload, jwtToken) =>
      dispatch(clientRegister(payload, jwtToken)),
    getProfiles: (jwtToken) => dispatch(getProfiles(jwtToken)),

    assignProfiles: (payload, jwtToken) =>
      dispatch(assignProfiles(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(AssignClient);
