import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Formik & Yup
import { Formik, Form } from "formik";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material components
import { Card, CardContent, Grid } from "@mui/material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "../../components/MDInput/index";

// Dashboard layout components
import BasicLayout from "layouts/authentication/components/BasicLayoutForForm";

// Functions from store
import { createClientDetails, getClientsByClientId } from "../../store";

// Router Components
import { useNavigate } from "react-router-dom";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const FormPreviewForClient = ({
  createClientDetails,
  getClientsByClientId,
  getClientsByClientIdReducer,
}) => {
  const cookies = new Cookies();
  let data = cookies.get("mobillor-talent-accelerator-user-data");
  let clientId = data?.data?.data?.clientId;
  let jwtToken = data?.data?.jwtToken;

  const [open, setOpen] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [msg, setMsg] = useState("");

  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] =
    useState("");

  const [clientData, setclientData] = useState({});

  const navigate = useNavigate();

  const clickHandler = () => {
    navigate(-1);
  };

  const handleChange = (id) => () => {
    setExpanded((pre) => {
      return {
        ...pre,
        [id]: !expanded[id],
      };
    });
  };

  const [expanded, setExpanded] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
  });
  let loading = getClientsByClientIdReducer.loading;
  useEffect(() => {
    getClientsByClientId(jwtToken, clientId);
  }, [getClientsByClientId, clientId]);

  useEffect(() => {
    let formdata = getClientsByClientIdReducer.clientsData
      ? getClientsByClientIdReducer.clientsData
      : {};
    setclientData(formdata);
  }, [getClientsByClientIdReducer]);

  const submitHandler = async (values) => {
    let response = await createClientDetails(values, jwtToken);
    if (response.status) {
      setFormSubmitted(true);
      setOpen(true);
      setMsg("Form is submitting... Please wait");
      setTimeout(() => {
        setMsg("Form is submitted.");
      }, 5000);

      cookies.remove("mta-jwtToken");
    }
  };

  const modalCloseHandler = () => {
    setOpen(false);
    setFormSubmittedSuccessfully("Thanks for updating your Company Details. You are all set to share your Talent requests.");
  };

  return (
    <>
      {!loading ? (
        <>
          <Card>
            {!formSubmitted && (
              <CardContent align="center">
                <Formik
                  initialValues={getClientsByClientIdReducer.clientsData}
                  onSubmit={submitHandler}
                >
                  {(formik) => (
                    <Form>
                      <div>
                        <MDBox mt={1}>
                          <Container
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: "auto",
                              marginRight: "auto",
                              textAlign: "center",
                            }}
                          >
                            <MDTypography
                              variant="h3"
                              gutterBottom
                              // color="black"
                              textAlign="center"
                              width="100%"
                              // marginBottom="10px"
                            >
                              Review The Details
                            </MDTypography>
                          </Container>
                        </MDBox>
                        <Accordion
                          style={{ boxShadow: "none", width: "100%" }}
                          expanded={expanded.panel1 === true}
                          onChange={handleChange("panel1")}
                        >
                          <AccordionSummary
                            style={{
                              backgroundColor: "#2196f3",
                              borderBottom: "2px solid white",

                              width: "100%",

                              maxHeight: "50px",
                              minHeight: "50px",
                            }}
                            expandIcon={
                              <ExpandMoreIcon style={{ color: "white" }} />
                            }
                            // aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <MDTypography
                              style={{ color: "white", margin: "0px" }}
                              mt={5}
                              variant="h5"
                              fontWeight="bold"
                            >
                              Business Partner Details
                            </MDTypography>
                          </AccordionSummary>

                          <AccordionDetails>
                            <Card style={{ padding: "15px" }}>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Line of Business
                                    </MDTypography>
                                    <MDInput
                                      inputProps={{ readOnly: true }}
                                      fullWidth
                                      // name="lineOfBusiness"
                                      value={clientData[0]?.lineOfBusiness}
                                      readonly
                                    />
                                  </MDBox>
                                </Grid>

                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Currency
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      // disabled
                                      inputProps={{ readOnly: true }}
                                      value={clientData[0]?.currency}
                                    />
                                  </MDBox>
                                </Grid>

                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Company Name
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      value={clientData[0]?.companyName}
                                      inputProps={{ readOnly: true }}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Building Number/Name
                                    </MDTypography>
                                    <MDInput
                                      inputProps={{ readOnly: true }}
                                      fullWidth
                                      value={
                                        clientData[0]?.address?.buildingNoName
                                      }
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      inputProps={{ readOnly: true }}
                                      style={{ fontWeight: "500" }}
                                    >
                                      Floor
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      inputProps={{ readOnly: true }}
                                      value={clientData[0]?.address?.floor}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Street
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      inputProps={{ readOnly: true }}
                                      value={clientData[0]?.address?.street}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Country
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      inputProps={{ readOnly: true }}
                                      value={clientData[0]?.address?.country}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      State
                                    </MDTypography>
                                    <MDInput
                                      fullWidth
                                      inputProps={{ readOnly: true }}
                                      value={clientData[0]?.address?.state}
                                    />
                                  </MDBox>
                                </Grid>

                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      City
                                    </MDTypography>
                                    <MDInput
                                      inputProps={{ readOnly: true }}
                                      fullWidth
                                      value={clientData[0]?.address?.city}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Pincode
                                    </MDTypography>
                                    <MDInput
                                      inputProps={{ readOnly: true }}
                                      fullWidth
                                      value={clientData[0]?.address?.pincode}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Contact Person
                                    </MDTypography>
                                    <MDInput
                                      inputProps={{ readOnly: true }}
                                      fullWidth
                                      value={clientData[0]?.contactPerson}
                                    />
                                  </MDBox>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Contact Number
                                    </MDTypography>{" "}
                                    <MDInput
                                      fullWidth
                                      inputProps={{ readOnly: true }}
                                      value={
                                        clientData[0]?.contactDetails
                                          ?.countryCode +
                                        "-" +
                                        clientData[0]?.contactDetails?.contactNo
                                      }
                                    />
                                  </MDBox>
                                </Grid>

                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Email
                                    </MDTypography>
                                    <MDInput
                                      inputProps={{ readOnly: true }}
                                      fullWidth
                                      value={clientData[0]?.email}
                                    />
                                  </MDBox>
                                </Grid>

                                {clientData.industrialType &&
                                clientData.industrialType?.length > 0 ? (
                                  <Grid item xl={3} lg={3} md={6} xs={12}>
                                    <MDBox mb={2}>
                                      <MDTypography
                                        variant="h6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        Industrial Type
                                      </MDTypography>
                                      <MDInput
                                        inputProps={{ readOnly: true }}
                                        fullWidth
                                        value={clientData[0]?.industrialType}
                                      />
                                    </MDBox>
                                  </Grid>
                                ) : null}
                              </Grid>
                            </Card>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          style={{ boxShadow: "none", width: "100%" }}
                          expanded={expanded.panel2 === true}
                          onChange={handleChange("panel2")}
                        >
                          <AccordionSummary
                            style={{
                              backgroundColor: "#2196f3",
                              borderBottom: "2px solid white",
                              width: "100%",

                              maxHeight: "50px",
                              minHeight: "50px",
                            }}
                            expandIcon={
                              <ExpandMoreIcon style={{ color: "white" }} />
                            }
                            // aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <MDTypography
                              style={{ color: "white", margin: "0px" }}
                              // mt={5}
                              variant="h5"
                              fontWeight="medium"
                              color="inherit"
                            >
                              Statutory Information
                            </MDTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Card style={{ padding: "15px" }}>
                              <Grid container spacing={2} alignItems="center">
                                {clientData[0]?.pan && (
                                  <Grid item xl={3} lg={3} md={6} xs={12}>
                                    <MDBox mb={2}>
                                      <MDTypography
                                        variant="h6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        PAN/TAX Number
                                      </MDTypography>
                                      <MDInput
                                        fullWidth
                                        value={clientData[0]?.pan}
                                        inputProps={{ readOnly: true }}
                                      />
                                    </MDBox>
                                  </Grid>
                                )}

                                {clientData[0]?.gstNo && (
                                  <Grid
                                    item
                                    xl={3}
                                    lg={3}
                                    md={6}
                                    xs={12}
                                    style={{ fontWeight: "500" }}
                                  >
                                    <MDBox mb={2}>
                                      <MDTypography
                                        variant="h6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        GST Number
                                      </MDTypography>
                                      <MDInput
                                        inputProps={{ readOnly: true }}
                                        fullWidth
                                        value={clientData[0]?.gstNo}
                                      />
                                    </MDBox>
                                  </Grid>
                                )}

                                {clientData[0]?.paymentTerms && (
                                  <Grid item xl={3} lg={3} md={6} xs={12}>
                                    <MDBox mb={2}>
                                      <MDTypography
                                        variant="h6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        Payment Terms
                                      </MDTypography>
                                      <MDInput
                                        inputProps={{ readOnly: true }}
                                        fullWidth
                                        value={clientData[0]?.paymentTerms}
                                      />
                                    </MDBox>
                                  </Grid>
                                )}

                                {clientData[0]?.cinNumber && (
                                  <Grid item xl={3} lg={3} md={6} xs={12}>
                                    <MDBox mb={2}>
                                      <MDTypography
                                        variant="h6"
                                        style={{ fontWeight: "500" }}
                                      >
                                        Company Registration Number
                                      </MDTypography>
                                      <MDInput
                                        inputProps={{ readOnly: true }}
                                        fullWidth
                                        value={clientData[0]?.cinNumber}
                                      />
                                    </MDBox>
                                  </Grid>
                                )}
                              </Grid>
                            </Card>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardContent>
            )}
          </Card>
        </>
      ) : (
        <>
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    clientDetailsReducer: state.clientDetailsReducer,
    getPreviewReducer: state.getPreviewReducer,
    getClientsByClientIdReducer: state.getClientsByClientIdReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createClientDetails: (payload, token) =>
      dispatch(createClientDetails(payload, token)),
    getClientsByClientId: (jwtToken, clientId) =>
      dispatch(getClientsByClientId(jwtToken, clientId)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormPreviewForClient);
