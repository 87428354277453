/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_ASSIGN_CLIENT_FAILURE,
  GET_ASSIGN_CLIENT_SUCCESS,
  GET_ASSIGN_CLIENT_REQUEST,
  GET_PROFILES_REQUEST,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_FAILURE,
  CREATE_PROFILE_REQUEST,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_FAILURE,
  CREATE_ASSIGNPROFILES_REQUEST,
  CREATE_ASSIGNPROFILES_SUCCESS,
  CREATE_ASSIGNPROFILES_FAILURE,
} from "./assignClientTypes";
import axios from "axios";
import Config from "../../config/index";
let auth3Url = Config.auth3Url;
let baseUrl = Config.baseUrl;

const getAssignClientRequest = () => {
  return {
    type: GET_ASSIGN_CLIENT_REQUEST,
  };
};

const getAssignClientSuccess = (payload) => {
  return {
    type: GET_ASSIGN_CLIENT_SUCCESS,
    payload: payload,
  };
};

const getAssignClientFaliure = (error) => {
  return {
    type: GET_ASSIGN_CLIENT_FAILURE,
    payload: error,
  };
};

const getAssignClient = (email, jwtToken, pageNo, rowsPerPage) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAssignClientRequest());
    let url = `${baseUrl}/clients/get_clients_by_account_manager_id?email=${email}&page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAssignClientSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getAssignClientFaliure(err.message));
        return { status: false };
      });
  };
};

const getProfilesRequest = () => {
  return {
    type: GET_PROFILES_REQUEST,
  };
};

const getProfilesSuccess = (payload) => {
  return {
    type: GET_PROFILES_SUCCESS,
    payload: payload,
  };
};

const getProfilesFaliure = (error) => {
  return {
    type: GET_PROFILES_FAILURE,
    payload: error,
  };
}; 

const getProfiles = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    }, 
  }; 
  return (dispatch) => {
    dispatch(getProfilesRequest());
    let url = `${baseUrl}/profiles/get_profiles_without_pagination`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getProfilesSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getProfilesFaliure(err.message));
        return { status: false };
      });
  };
};

const createPofileRequest = () => {
  return {
    type: CREATE_PROFILE_REQUEST,
  };
};

const createPofileSuccess = (payload) => {
  return {
    type: CREATE_PROFILE_SUCCESS,
    payload: payload,
  };
};

const createPofileFaliure = (error) => {
  return {
    type: CREATE_PROFILE_FAILURE,
    payload: error,
  };
};

const createPofile = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(createPofileRequest());
    let url = `${baseUrl}/profiles/upload`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createPofileSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createPofileFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const assignProfilesRequest = () => {
  return {
    type: CREATE_ASSIGNPROFILES_REQUEST,
  };
};

const assignProfilesSuccess = (payload) => {
  return {
    type: CREATE_ASSIGNPROFILES_SUCCESS,
    payload: payload,
  };
};

const assignProfilesFaliure = (error) => {
  return {
    type: CREATE_ASSIGNPROFILES_FAILURE,
    payload: error,
  };
};

const assignProfiles = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(assignProfilesRequest());
    let url = `${baseUrl}/client_profile_mapping/send_profile_to_client`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(assignProfilesSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(assignProfilesFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { getAssignClient, getProfiles, createPofile, assignProfiles };
