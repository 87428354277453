/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

function Profile() {
  let user = cookies.get("mobillor-talent-accelerator-user-data");
  let profileInfo = user.data.data;

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card
        sx={{
          width: {xs:"100%", sm:"100%", md:"60%", lg:"50%", xl:"50%"},
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "50px",
        }}
      >
        <MDBox ml={2} mt={2}>
          <MDTypography variant="h4" fontWeight="medium">
            Profile
          </MDTypography>
        </MDBox>
        <Grid container spacing={3} alignItems="center">
          <Grid item style={{ marginLeft: "1.5rem", marginTop: "1.5rem" }}>
            <MDAvatar
              src="https://p.kindpng.com/picc/s/78-785827_user-profile-avatar-login-account-male-user-icon.png"
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
        </Grid>
        <Divider />
        <MDBox mb={2} mt={-2}>
          <MDBox p={2}>
            <MDBox>
              <MDBox key="name">
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  name: &nbsp;
                </MDTypography>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  // color="inherit"
                  style={{ marginLeft: "4rem" }}
                >
                  {profileInfo.userName}
                </MDTypography>
              </MDBox>
              <MDBox key="email">
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  e-mail: &nbsp;
                </MDTypography>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  // color="inherit"
                  style={{ marginLeft: "3.72rem" }}
                >
                  {profileInfo.email}
                </MDTypography>
              </MDBox>
              <MDBox key="role">
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  role: &nbsp;
                </MDTypography>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  // color="inherit"
                  style={{ marginLeft: "4.53rem" }}
                >
                  {profileInfo.roleName}
                </MDTypography>
              </MDBox>
              <MDBox key="clientId">
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {/* vendor-ID: &nbsp; */}
                </MDTypography>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  // color="inherit"
                  style={{ marginLeft: "2rem" }}
                >
                  {/* {profileInfo.vendorId} */}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>

      <p className=" font-size text-center text-md-left">
          Developed and maintained by :
          <a className="link" href="http://mobillor.com/">
            <strong style={{ color: "red" }}>
              {" "}
              Mobillor Technologies Pvt. Ltd.
            </strong>
          </a>
        </p>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapStateToDispatch = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapStateToDispatch)(Profile);
