/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  ALL_CLIENT_REQUESTS_REQUEST,
  ALL_CLIENT_REQUESTS_SUCCESS,
  ALL_CLIENT_REQUESTS_FAILURE,
  ACCEPT_AND_REJECT_CLIENT_REQUESTS_FAILURE,
  ACCEPT_AND_REJECT_CLIENT_REQUESTS_SUCCESS,
  ACCEPT_AND_REJECT_CLIENT_REQUESTS_REQUEST,
  CREATE_CLIENT_REQUESTS_REQUEST,
  CREATE_CLIENT_REQUESTS_SUCCESS,
  CREATE_CLIENT_REQUESTS_FAILURE,
  GET_ALL_UNASSIGNED_CLIENTS_FAILURE,
  GET_ALL_UNASSIGNED_CLIENTS_SUCCESS,
  GET_ALL_UNASSIGNED_CLIENTS_REQUEST,
  ASSIGN_CLIENT_FAILURE,
  ASSIGN_CLIENT_SUCCESS,
  ASSIGN_CLIENT_REQUEST,
  GET_CLIENTS_BY_CLIENTID_FAILURE,
  GET_CLIENTS_BY_CLIENTID_SUCCESS,
  GET_CLIENTS_BY_CLIENTID_REQUEST,
  GET_ALL_CLIENT_FAILURE,
  GET_ALL_CLIENT_SUCCESS,
  GET_ALL_CLIENT_REQUEST,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_REQUEST,
  BLOCK_CLIENT_FAILURE,
  BLOCK_CLIENT_SUCCESS,
  BLOCK_CLIENT_REQUEST,
  GET_CLIENT_BY_ID_FAILURE,
  GET_CLIENT_BY_ID_SUCCESS,
  GET_CLIENT_BY_ID_REQUEST,
} from "./clientRequestTypes";
import axios from "axios";
import Config from "../../config/index";
let auth3Url = Config.auth3Url;
let baseUrl = Config.baseUrl;
let clientId = Config.clientId;

const allClientRequestsRequest = () => {
  return {
    type: ALL_CLIENT_REQUESTS_REQUEST,
  };
};

const allClientRequestsSuccess = (payload) => {
  return {
    type: ALL_CLIENT_REQUESTS_SUCCESS,
    payload: payload,
  };
};

const allClientRequestsFaliure = (error) => {
  return {
    type: ALL_CLIENT_REQUESTS_FAILURE,
    payload: error,
  };
};

const allClientRequests = (jwtToken, status, date, pageNo, rowsPerPage) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(allClientRequestsRequest());
    let url = `${baseUrl}/requests/get_all_requests?status=${status}&date=${date}&page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(allClientRequestsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(allClientRequestsFaliure(err.message));
        return { status: false };
      });
  };
};

const getClientByIdRequest = () => {
  return {
    type: GET_CLIENT_BY_ID_REQUEST,
  };
};

const getClientByIdSuccess = (payload) => {
  return {
    type: GET_CLIENT_BY_ID_SUCCESS,
    payload: payload,
  };
};

const getClientByIdFaliure = (error) => {
  return {
    type: GET_CLIENT_BY_ID_FAILURE,
    payload: error,
  };
};

const getClientById = (jwtToken, clientId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getClientByIdRequest());
    let url = `${baseUrl}/clients/get_clients_by_client_id?client_id=${clientId}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getClientByIdSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(getClientByIdFaliure(err.message));
        return { status: false };
      });
  };
};

const deleteClientRequest = () => {
  return {
    type: DELETE_CLIENT_REQUEST,
  };
};

const deleteClientSuccess = (payload) => {
  return {
    type: DELETE_CLIENT_SUCCESS,
    payload: payload,
  };
};

const deleteClientFaliure = (error) => {
  return {
    type: DELETE_CLIENT_FAILURE,
    payload: error,
  };
};

const deleteClient = (jwtToken, clientId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteClientRequest());
    let url = `${baseUrl}/clients/delete?client_id=${clientId}`;
    return axios
      .put(url, "", headers)
      .then((response) => {
        dispatch(deleteClientSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(deleteClientFaliure(err.message));
        return { status: false };
      });
  };
};

const getAllClientRequest = () => {
  return {
    type: GET_ALL_CLIENT_REQUEST,
  };
};

const getAllClientSuccess = (payload) => {
  return {
    type: GET_ALL_CLIENT_SUCCESS,
    payload: payload,
  };
};

const getAllClientFaliure = (error) => {
  return {
    type: GET_ALL_CLIENT_FAILURE,
    payload: error,
  };
};

const getAllClient = (jwtToken, pageNo, rowsPerPage) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAllClientRequest());
    let url = `${baseUrl}/clients/get_clients?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAllClientSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getAllClientFaliure(err.message));
        return { status: false };
      });
  };
};

const getClientsByClientIdRequest = () => {
  return {
    type: GET_CLIENTS_BY_CLIENTID_REQUEST,
  };
};

const getClientsByClientIdSuccess = (payload) => {
  return {
    type: GET_CLIENTS_BY_CLIENTID_SUCCESS,
    payload: payload,
  };
};

const getClientsByClientIdFaliure = (error) => {
  return {
    type: GET_CLIENTS_BY_CLIENTID_FAILURE,
    payload: error,
  };
};

const getClientsByClientId = (jwtToken, clientId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getClientsByClientIdRequest());
    let url = `${baseUrl}/clients/get_clients_by_client_id?client_id=${clientId}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getClientsByClientIdSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(getClientsByClientIdFaliure(err.message));
        return { status: false };
      });
  };
};

const getAllUnassignedClientsRequest = () => {
  return {
    type: GET_ALL_UNASSIGNED_CLIENTS_REQUEST,
  };
};

const getAllUnassignedClientsSuccess = (payload) => {
  return {
    type: GET_ALL_UNASSIGNED_CLIENTS_SUCCESS,
    payload: payload,
  };
};

const getAllUnassignedClientsFaliure = (error) => {
  return {
    type: GET_ALL_UNASSIGNED_CLIENTS_FAILURE,
    payload: error,
  };
};

const getAllUnassignedClients = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAllUnassignedClientsRequest());
    let url = `${baseUrl}/clients/get_all_unassigned_clients`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAllUnassignedClientsSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(getAllUnassignedClientsFaliure(err.message));
        return { status: false };
      });
  };
};

const acceptAndRejectClientRequest = () => {
  return {
    type: ACCEPT_AND_REJECT_CLIENT_REQUESTS_REQUEST,
  };
};

const acceptAndRejectClientSuccess = (payload) => {
  return {
    type: ACCEPT_AND_REJECT_CLIENT_REQUESTS_SUCCESS,
    payload: payload,
  };
};

const acceptAndRejectClientFaliure = (error) => {
  return {
    type: ACCEPT_AND_REJECT_CLIENT_REQUESTS_FAILURE,
    payload: error,
  };
};

const acceptAndRejectClient = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(acceptAndRejectClientRequest());
    let url = `${baseUrl}/requests/update_request`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(acceptAndRejectClientSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(acceptAndRejectClientFaliure(err.message));
        return { status: false };
      });
  };
};

const createClientRequest = () => {
  return {
    type: CREATE_CLIENT_REQUESTS_REQUEST,
  };
};

const createClientSuccess = (payload) => {
  return {
    type: CREATE_CLIENT_REQUESTS_SUCCESS,
    payload: payload,
  };
};

const createClientFaliure = (error) => {
  return {
    type: CREATE_CLIENT_REQUESTS_FAILURE,
    payload: error,
  };
};

const createClientRequests = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createClientRequest());
    let url = `${baseUrl}/requests/create_request`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createClientSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createClientFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const assignClientRequest = () => {
  return {
    type: ASSIGN_CLIENT_REQUEST,
  };
};

const assignClientSuccess = (payload) => {
  return {
    type: ASSIGN_CLIENT_SUCCESS,
    payload: payload,
  };
};

const assignClientFaliure = (error) => {
  return {
    type: ASSIGN_CLIENT_FAILURE,
    payload: error,
  };
};

const assignClient = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(assignClientRequest());
    let url = `${baseUrl}/clients/assign_client`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(assignClientSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(assignClientFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const blockClientRequest = () => {
  return {
    type: BLOCK_CLIENT_REQUEST,
  };
};

const blockClientSuccess = (payload) => {
  return {
    type: BLOCK_CLIENT_SUCCESS,
    payload: payload,
  };
};

const blockClientFaliure = (error) => {
  return {
    type: BLOCK_CLIENT_FAILURE,
    payload: error,
  };
};

const blockClient = (jwtToken, clientId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(blockClientRequest());
    let url = `${baseUrl}/clients/update?client_id=${clientId}`;
    return axios
      .put(url, "", headers)
      .then((response) => {
        dispatch(blockClientSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(blockClientFaliure(err.message));
        return { status: false };
      });
  };
};

export {
  allClientRequests,
  acceptAndRejectClient,
  createClientRequests,
  getAllUnassignedClients,
  assignClient,
  getClientsByClientId,
  getAllClient,
  deleteClient,
  getClientById,
  blockClient,
};
