export const GET_OTP_REQUEST = "GET_OTP_REQUEST";
export const GET_OTP_FAILURE = "GET_OTP_FAILURE";
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";

export const PUT_OTP_VALIDATE_REQUEST = "PUT_OTP_VALIDATE_REQUEST";
export const PUT_OTP_VALIDATE_FAILURE = "PUT_OTP_VALIDATE_FAILURE";
export const PUT_OTP_VALIDATE_SUCCESS = "PUT_OTP_VALIDATE_SUCCESS";

export const CLIENT_REGISTER_REQUEST = "CLIENT_REGISTER_REQUEST";
export const CLIENT_REGISTER_FAILURE = "CLIENT_REGISTER_FAILURE";
export const CLIENT_REGISTER_SUCCESS = "CLIENT_REGISTER_SUCCESS";


export const GET_CONTENT_FOR_REGISTRATION_REQUEST = "GET_CONTENT_FOR_REGISTRATION_REQUEST";
export const GET_CONTENT_FOR_REGISTRATION_FAILURE = "GET_CONTENT_FOR_REGISTRATION_FAILURE";
export const GET_CONTENT_FOR_REGISTRATION_SUCCESS = "GET_CONTENT_FOR_REGISTRATION_SUCCESS";