export const GET_CLIENT_REQUESTS_FOR_MANAGER_REQUEST =
  "GET_CLIENT_REQUESTS_FOR_MANAGER_REQUEST";
export const GET_CLIENT_REQUESTS_FOR_MANAGER_SUCCESS =
  "GET_CLIENT_REQUESTS_FOR_MANAGER_SUCCESS";
export const GET_CLIENT_REQUESTS_FOR_MANAGER_FAILURE =
  "GET_CLIENT_REQUESTS_FOR_MANAGER_FAILURE";

export const GET_CLIENT_FOR_REASSIGN_REQUEST =
  "GET_CLIENT_FOR_REASSIGN_REQUEST";
export const GET_CLIENT_FOR_REASSIGN_SUCCESS =
  "GET_CLIENT_FOR_REASSIGN_SUCCESS";
export const GET_CLIENT_FOR_REASSIGN_FAILURE =
  "GET_CLIENT_FOR_REASSIGN_FAILURE";

export const TRANSFER_CLIENT_FOR_REASSIGN_REQUEST =
  "TRANSFER_CLIENT_FOR_REASSIGN_REQUEST";
export const TRANSFER_CLIENT_FOR_REASSIGN_SUCCESS =
  "TRANSFER_CLIENT_FOR_REASSIGN_SUCCESS";
export const TRANSFER_CLIENT_FOR_REASSIGN_FAILURE =
  "TRANSFER_CLIENT_FOR_REASSIGN_FAILURE";

export const REMOVE_CLIENT_FOR_REASSIGN_REQUEST =
  "REMOVE_CLIENT_FOR_REASSIGN_REQUEST";
export const REMOVE_CLIENT_FOR_REASSIGN_SUCCESS =
  "REMOVE_CLIENT_FOR_REASSIGN_SUCCESS";
export const REMOVE_CLIENT_FOR_REASSIGN_FAILURE =
  "REMOVE_CLIENT_FOR_REASSIGN_FAILURE";
