/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_CLIENT_REQUESTS_FOR_MANAGER_REQUEST,
  GET_CLIENT_REQUESTS_FOR_MANAGER_SUCCESS,
  GET_CLIENT_REQUESTS_FOR_MANAGER_FAILURE,
  GET_CLIENT_FOR_REASSIGN_FAILURE,
  GET_CLIENT_FOR_REASSIGN_SUCCESS,
  GET_CLIENT_FOR_REASSIGN_REQUEST,
  TRANSFER_CLIENT_FOR_REASSIGN_FAILURE,
  TRANSFER_CLIENT_FOR_REASSIGN_SUCCESS,
  TRANSFER_CLIENT_FOR_REASSIGN_REQUEST,
  REMOVE_CLIENT_FOR_REASSIGN_FAILURE,
  REMOVE_CLIENT_FOR_REASSIGN_SUCCESS,
  REMOVE_CLIENT_FOR_REASSIGN_REQUEST,
} from "./clientRequestForManagerTypes";
import axios from "axios";
import Config from "../../config/index";

let baseUrl = Config.baseUrl;

const getClientRequestForManagerRequest = () => {
  return {
    type: GET_CLIENT_REQUESTS_FOR_MANAGER_REQUEST,
  };
};

const getClientRequestForManagerSuccess = (payload) => {
  return {
    type: GET_CLIENT_REQUESTS_FOR_MANAGER_SUCCESS,
    payload: payload,
  };
};

const getClientRequestForManagerFaliure = (error) => {
  return {
    type: GET_CLIENT_REQUESTS_FOR_MANAGER_FAILURE,
    payload: error,
  };
};

const getClientRequestForManager = (
  accountManagerId,
  jwtToken,
  status,
  date,
  pageNo,
  rowsPerPage
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };

  return (dispatch) => {
    dispatch(getClientRequestForManagerRequest());
    let url = `${baseUrl}/requests/get_requests_by_account_manager_id?account_manager_id=${accountManagerId}&status=${status}&date=${date}&page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getClientRequestForManagerSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getClientRequestForManagerFaliure(err.message));
        return { status: false };
      });
  };
};

const getClientForReAssignRequest = () => {
  return {
    type: GET_CLIENT_FOR_REASSIGN_REQUEST,
  };
};

const getClientForReAssignSuccess = (payload) => {
  return {
    type: GET_CLIENT_FOR_REASSIGN_SUCCESS,
    payload: payload,
  };
};

const getClientForReAssignFaliure = (error) => {
  return {
    type: GET_CLIENT_FOR_REASSIGN_FAILURE,
    payload: error,
  };
};

const getClientForReAssign = (jwtToken, email) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getClientForReAssignRequest());
    let url = `${baseUrl}/clients/get_clients_by_account_manager_id?email=${email}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getClientForReAssignSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(getClientForReAssignFaliure(err.message));
        return { status: false };
      });
  };
};

const transferClientForReAssignRequest = () => {
  return {
    type: TRANSFER_CLIENT_FOR_REASSIGN_REQUEST,
  };
};

const transferClientForReAssignSuccess = (payload) => {
  return {
    type: TRANSFER_CLIENT_FOR_REASSIGN_SUCCESS,
    payload: payload,
  };
};

const transferClientForReAssignFaliure = (error) => {
  return {
    type: TRANSFER_CLIENT_FOR_REASSIGN_FAILURE,
    payload: error,
  };
};

const transferClientForReAssign = (jwtToken, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(transferClientForReAssignRequest());
    let url = `${baseUrl}/`;
    return axios
      .put(url, headers, payload)
      .then((response) => {
        dispatch(transferClientForReAssignSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(transferClientForReAssignFaliure(err.message));
        return { status: false };
      });
  };
};

const removeClientForReAssignRequest = () => {
  return {
    type: REMOVE_CLIENT_FOR_REASSIGN_REQUEST,
  };
};
const removeClientForReAssignSuccess = (payload) => {
  return {
    type: REMOVE_CLIENT_FOR_REASSIGN_SUCCESS,
    payload: payload,
  };
};

const removeClientForReAssignFaliure = (error) => {
  return {
    type: REMOVE_CLIENT_FOR_REASSIGN_FAILURE,
    payload: error,
  };
};

const removeClientForReAssign = (jwtToken, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(removeClientForReAssignRequest());
    let url = `${baseUrl}/clients/unassign_clients`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(removeClientForReAssignSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(removeClientForReAssignFaliure(err.message));
        return { status: false };
      });
  };
};

export {
  getClientRequestForManager,
  getClientForReAssign,
  transferClientForReAssign,
  removeClientForReAssign,
};
