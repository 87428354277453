/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useState, useEffect } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";

// Dashboard base styles
import breakpoints from "assets/theme/base/breakpoints";

// Dashboard context
import { useMaterialUIController } from "context";

import logo from "../../../assets/mobillor.png";
import whitelogo from "../../../assets/whitelogo.png";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();
  
function DefaultNavbar({ transparent, light, bgColor, action ,topbutton,topbutton5, pagelink}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const openMobileNavbar = ({ currentTarget }) =>
    setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleRegisterPage = () => {
    cookies.remove("mobillor-talent-accelerator-user-data");
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      navigate(pagelink)
    }, [500])
  }; 

  return (
    <Container>
      <MDBox
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 4 }}
        my={1}
        mx={3}
        width="calc(100% - 48px)"
        borderRadius="lg"
        // shadow={transparent ? "none" : "md"}
        // color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="fixed"
        left={0}
        zIndex={9}
        // style={{ backgroundColor:"#212b31"}} 
        style={{ backgroundColor:"#191919", opacity:"0.9"}}
     
      > 

        <MDBox
          py={transparent ? 1.5 : 0.75}
          lineHeight={1}
          pl={{ xs: 0, lg: 1 }}

        >
          <MDBox component="img" src={logo} alt="Brand" width={{xs: "8rem", sm: "8rem", md: "8rem", lg: "210px", xl: "210px"}} marginLeft={{xs: "-15px"}} />
        </MDBox>
        <MDBox display="flex">
        <MDBox mr={{xs: 0.5, sm: 0.5, md: 1, lg: 1, xl: 1}} mt={1}>
        <MDTypography 
        // fontStyle="italic"
          color="white" 
          fontSize={{xs: "8px", sm: "8px", md: "12px", lg: "12px", xl: "12px"}}  
          textTransform= "uppercase"
          style={{fontWeight:"600"}}
          >
           {topbutton5}
           </MDTypography>  
           </MDBox> 
          <MDBox
            varient="contained"
            color="error"
            sx={{ '&:hover': {
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
            },}}
            padding={{xs: "6px", sm: "6px", md: "8px", lg: "10px", xl: "10px"}}
            paddingLeft={{xs: "6px", sm: "6px", md: "8px", lg: "15px", xl: "15px"}}
            paddingRight={{xs: "6px", sm: "6px", md: "8px", lg: "15px", xl: "15px"}}
            marginRight={{xs: "-15px"}}
            style={{ backgroundColor: "red", borderRadius:"5px", cursor:"pointer" }}
            
            onClick={handleRegisterPage}
          >
           
          <MDTypography 
          color="white" 
          fontSize={{xs: "10px", sm: "10px", md: "12px", lg: "12px", xl: "12px"}}  
          textTransform= "uppercase"
          style={{fontWeight:"600"}}
          >
           {topbutton}
           </MDTypography>  
          </MDBox> 
        </MDBox>

      </MDBox>
      {mobileView && (
        <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />
      )}

{loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
    
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
