/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_UPLOAD_PROFILE_REQUEST,
  GET_UPLOAD_PROFILE_SUCCESS,
  GET_UPLOAD_PROFILE_FAILURE,
  GET_ALL_PROFILE_REQUEST,
  GET_ALL_PROFILE_SUCCESS,
  GET_ALL_PROFILE_FAILURE,
  DELETE_UPLOAD_PROFILE_FAILURE,
  DELETE_UPLOAD_PROFILE_SUCCESS,
  DELETE_UPLOAD_PROFILE_REQUEST,
  EDIT_UPLOAD_PROFILE_FAILURE,
  EDIT_UPLOAD_PROFILE_SUCCESS,
  EDIT_UPLOAD_PROFILE_REQUEST,
  GET_PROFILE_BY_CLIENTID_REQUEST,
  GET_PROFILE_BY_CLIENTID_SUCCESS,
  GET_PROFILE_BY_CLIENTID_FAILURE,
  ACCEPT_OR_REJECT_PROFILE_FAILURE,
  ACCEPT_OR_REJECT_PROFILE_SUCCESS,
  ACCEPT_OR_REJECT_PROFILE_REQUEST,
  SORT_LIST_PROFILE_REQUEST,
  SORT_LIST_PROFILE_SUCCESS,
  SORT_LIST_PROFILE_FAILURE,
  PROFILES_NOTIFICATION_FAILURE,
  PROFILES_NOTIFICATION_SUCCESS,
  PROFILES_NOTIFICATION_REQUEST,
} from "./uploadProfileTypes";
import axios from "axios";
import Config from "../../config/index";
let auth3Url = Config.auth3Url;
let baseUrl = Config.baseUrl;
let clientId = Config.clientId;

const getUploadProfileRequest = () => {
  return {
    type: GET_UPLOAD_PROFILE_REQUEST,
  };
};

const getUploadProfileSuccess = (payload) => {
  return {
    type: GET_UPLOAD_PROFILE_SUCCESS,
    payload: payload,
  };
};

const getUploadProfileFaliure = (error) => {
  return {
    type: GET_UPLOAD_PROFILE_FAILURE,
    payload: error,
  };
};

const getUploadProfile = (jwtToken, pageNo, rowsPerPage) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getUploadProfileRequest());
    let url = `${baseUrl}/profiles/get_profiles?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}`;

    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getUploadProfileSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getUploadProfileFaliure(err.message));
        return { status: false };
      });
  };
};

const getAllProfileRequest = () => {
  return {
    type: GET_ALL_PROFILE_REQUEST,
  };
};

const getAllProfileSuccess = (payload) => {
  return {
    type: GET_ALL_PROFILE_SUCCESS,
    payload: payload,
  };
};

const getAllProfileFaliure = (error) => {
  return {
    type: GET_ALL_PROFILE_FAILURE,
    payload: error,
  };
};

const getAllProfile = (jwtToken, pageNo, rowsPerPage) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAllProfileRequest());
    let url = `${baseUrl}/profiles/get_profiles?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}`;

    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAllProfileSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getAllProfileFaliure(err.message));
        return { status: false };
      });
  };
};

const profilesNotificationRequest = () => {
  return {
    type: PROFILES_NOTIFICATION_REQUEST,
  };
};

const profilesNotificationSuccess = (payload) => {
  return {
    type: PROFILES_NOTIFICATION_SUCCESS,
    payload: payload,
  };
};

const profilesNotificationFaliure = (error) => {
  return {
    type: PROFILES_NOTIFICATION_FAILURE,
    payload: error,
  };
};

const profilesNotification = (jwtToken, clientId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(profilesNotificationRequest());
    let url = `${baseUrl}/profiles/notification?client_id=${clientId}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(profilesNotificationSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(profilesNotificationFaliure(err.message));
        return { status: false };
      });
  };
};

const getProfileByClientIdRequest = () => {
  return {
    type: GET_PROFILE_BY_CLIENTID_REQUEST,
  };
};

const getProfileByClientIdSuccess = (payload) => {
  return {
    type: GET_PROFILE_BY_CLIENTID_SUCCESS,
    payload: payload,
  };
};

const getProfileByClientIdFaliure = (error) => {
  return {
    type: GET_PROFILE_BY_CLIENTID_FAILURE,
    payload: error,
  };
};

const getProfileByClientId = (jwtToken, clientId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getProfileByClientIdRequest());
    let url = `${baseUrl}/profiles/get_profile_by_client_id?client_id=${clientId}`;

    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getProfileByClientIdSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getProfileByClientIdFaliure(err.message));
        return { status: false };
      });
  };
};

const deleteUploadProfileRequest = () => {
  return {
    type: DELETE_UPLOAD_PROFILE_REQUEST,
  };
};

const deleteUploadProfileSuccess = (payload) => {
  return {
    type: DELETE_UPLOAD_PROFILE_SUCCESS,
    payload: payload,
  };
};

const deleteUploadProfileFaliure = (error) => {
  return {
    type: DELETE_UPLOAD_PROFILE_FAILURE,
    payload: error,
  };
};

const deleteUploadProfile = (jwtToken, profileId) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteUploadProfileRequest());
    let url = `${baseUrl}/profiles/delete?profile_id=${profileId}`;
    return axios
      .put(url, "", headers)
      .then((response) => {
        dispatch(deleteUploadProfileSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(deleteUploadProfileFaliure(err.message));
        return { status: false };
      });
  };
};

const editUploadProfileRequest = () => {
  return {
    type: EDIT_UPLOAD_PROFILE_REQUEST,
  };
};

const editUploadProfileSuccess = (payload) => {
  return {
    type: EDIT_UPLOAD_PROFILE_SUCCESS,
    payload: payload,
  };
};

const editUploadProfileFaliure = (error) => {
  return {
    type: EDIT_UPLOAD_PROFILE_FAILURE,
    payload: error,
  };
};

const editUploadProfile = (jwtToken, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editUploadProfileRequest());
    let url = `${baseUrl}/profiles/update`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editUploadProfileSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(editUploadProfileFaliure(err.message));
        return { status: false };
      });
  };
};

const acceptOrRejectProfileRequest = () => {
  return {
    type: ACCEPT_OR_REJECT_PROFILE_REQUEST,
  };
};

const acceptOrRejectProfileSuccess = (payload) => {
  return {
    type: ACCEPT_OR_REJECT_PROFILE_SUCCESS,
    payload: payload,
  };
};

const acceptOrRejectProfileFaliure = (error) => {
  return {
    type: ACCEPT_OR_REJECT_PROFILE_FAILURE,
    payload: error,
  };
};

const acceptOrRejectProfile = (
  jwtToken,
  profileId,
  clientId,
  requestId,
  status
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(acceptOrRejectProfileRequest());
    let url = `${baseUrl}/client_profile_mapping/update?profile_id=${profileId}&status=${status}&sort_listed=false&client_id=${clientId}&request_id=${requestId}`;
    return axios
      .put(url, " ", headers)
      .then((response) => {
        dispatch(acceptOrRejectProfileSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(acceptOrRejectProfileFaliure(err.message));
        return { status: false };
      });
  };
};

const sortListProfileRequest = () => {
  return {
    type: SORT_LIST_PROFILE_REQUEST,
  };
};

const sortListProfileSuccess = (payload) => {
  return {
    type: SORT_LIST_PROFILE_SUCCESS,
    payload: payload,
  };
};

const sortListProfileFaliure = (error) => {
  return {
    type: SORT_LIST_PROFILE_FAILURE,
    payload: error,
  };
};

const sortListProfile = (jwtToken, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(sortListProfileRequest());
    let url = `${baseUrl}/client_profile_mapping/sort_list_profiles`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(sortListProfileSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(sortListProfileFaliure(err.message));
        return { status: false };
      });
  };
};

export {
  getAllProfile,
  getUploadProfile,
  deleteUploadProfile,
  editUploadProfile,
  getProfileByClientId,
  acceptOrRejectProfile,
  sortListProfile,
  profilesNotification,
};
