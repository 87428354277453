/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_ALL_ACCOUNTMANAGER_REQUEST,
  GET_ALL_ACCOUNTMANAGER_SUCCESS,
  GET_ALL_ACCOUNTMANAGER_FAILURE,
  GET_ACCOUNTMANAGER_REQUEST,
  GET_ACCOUNTMANAGER_SUCCESS,
  GET_ACCOUNTMANAGER_FAILURE,
  CREATE_ACCOUNT_MANAGER_FAILURE,
  CREATE_ACCOUNT_MANAGER_SUCCESS,
  CREATE_ACCOUNT_MANAGER_REQUEST,
  DELETE_ACCOUNTMANAGER_REQUEST,
  DELETE_ACCOUNTMANAGER_SUCCESS,
  DELETE_ACCOUNTMANAGER_FAILURE,
  EDIT_ACCOUNTMANAGER_REQUEST,
  EDIT_ACCOUNTMANAGER_SUCCESS,
  EDIT_ACCOUNTMANAGER_FAILURE,
} from "./accountManagerTypes";

import axios from "axios";
import Config from "../../config/index";
let auth3Url = Config.auth3Url;
let baseUrl = Config.baseUrl;

const getAllAccountManagerRequest = () => {
  return {
    type: GET_ALL_ACCOUNTMANAGER_REQUEST,
  };
};

const getAllAccountManagerSuccess = (payload) => {
  return {
    type: GET_ALL_ACCOUNTMANAGER_SUCCESS,
    payload: payload,
  };
};

const getAllAccountManagerFaliure = (error) => {
  return {
    type: GET_ALL_ACCOUNTMANAGER_FAILURE,
    payload: error,
  };
};

const getAllAccountManager = (jwtToken, pageNo, rowsPerPage) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAllAccountManagerRequest());
    let url = `${baseUrl}/account_managers/get_account_managers?page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAllAccountManagerSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getAllAccountManagerFaliure(err.message));
        return { status: false };
      });
  };
};

const getAccountManagerRequest = () => {
  return {
    type: GET_ACCOUNTMANAGER_REQUEST,
  };
};

const getAccountManagerSuccess = (payload) => {
  return {
    type: GET_ACCOUNTMANAGER_SUCCESS,
    payload: payload,
  };
};

const getAccountManagerFaliure = (error) => {
  return {
    type: GET_ACCOUNTMANAGER_FAILURE,
    payload: error,
  };
};

const getAccountManager = (jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getAccountManagerRequest());
    let url = `${baseUrl}/account_managers/get_account_managers_without_pagination`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getAccountManagerSuccess(response.data.data));
        return { status: true, data: response.data.data };
      })
      .catch((err) => {
        dispatch(getAccountManagerFaliure(err.message));
        return { status: false };
      });
  };
};

const createAccountManagerRequest = () => {
  return {
    type: CREATE_ACCOUNT_MANAGER_REQUEST,
  };
};

const createAccountManagerSuccess = (payload) => {
  return {
    type: CREATE_ACCOUNT_MANAGER_SUCCESS,
    payload: payload,
  };
};

const createAccountManagerFaliure = (error) => {
  return {
    type: CREATE_ACCOUNT_MANAGER_FAILURE,
    payload: error,
  };
};

const createAccountManagers = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createAccountManagerRequest());
    let url = `${baseUrl}/account_managers/create`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createAccountManagerSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createAccountManagerFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const deleteAccountManagerRequest = () => {
  return {
    type: DELETE_ACCOUNTMANAGER_REQUEST,
  };
};

const deleteAccountManagerSuccess = (payload) => {
  return {
    type: DELETE_ACCOUNTMANAGER_SUCCESS,
    payload: payload,
  };
};

const deleteAccountManagerFaliure = (error) => {
  return {
    type: DELETE_ACCOUNTMANAGER_FAILURE,
    payload: error,
  };
};

const deleteAccountManager = (accountManagerId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(deleteAccountManagerRequest());
    let url = `${baseUrl}/account_managers/delete?account_manager_id=${accountManagerId}`;
    return axios
      .put(url, " ", headers)
      .then((response) => {
        dispatch(deleteAccountManagerSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(deleteAccountManagerFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

const editAccountManagerRequest = () => {
  return {
    type: EDIT_ACCOUNTMANAGER_REQUEST,
  };
};

const editAccountManagerSuccess = (payload) => {
  return {
    type: EDIT_ACCOUNTMANAGER_SUCCESS,
    payload: payload,
  };
};
const editAccountManagerFaliure = (error) => {
  return {
    type: EDIT_ACCOUNTMANAGER_FAILURE,
    payload: error,
  };
};

const editAccountManager = (jwtToken, payload) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(editAccountManagerRequest());
    let url = `${baseUrl}/clients/transfer_clients`;
    return axios
      .put(url, payload, headers)
      .then((response) => {
        dispatch(editAccountManagerSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(editAccountManagerFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export {
  getAllAccountManager,
  createAccountManagers,
  deleteAccountManager,
  editAccountManager,
  getAccountManager,
};
