/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useState, useEffect } from "react";

// react-router components
import { Navigate, useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@mui/material/Tooltip";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { green, pink } from "@mui/material/colors";

//  some date functions
import { format, addMinutes } from "date-fns";

// Dashboard components
import MDBox from "components/MDBox";

// Dashboard example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import { connect } from "react-redux";

import { profilesNotification ,   getNotificationForRegisteredClient
} from "../../../store";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Dashboard context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import MDButton from "components/MDButton";
import Cookies from "universal-cookie";
import MDTypography from "components/MDTypography";
import MDCard from "components/MDCard";

const cookies = new Cookies();

function DashboardNavbar({
  absolute,
  light,
  isMini,
  profilesNotification,
  profilesNotificationReducer,
  getNotificationForRegisteredClient ,
  notificationForRegisteredClientReducer
}) {
  let data = cookies.get("mobillor-talent-accelerator-user-data");
  let jwtToken = data?.data?.jwtToken;
  let clientId = data.data.data.clientId;

  useEffect(() => {
    profilesNotification(jwtToken, clientId);
  }, [profilesNotification, clientId]);

  useEffect(() => {
    getNotificationForRegisteredClient(jwtToken);
  }, [getNotificationForRegisteredClient]);

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  // let user = cookies.get("vendor-portal-user-data");
  // let vendorId = user.data.data.vendorId;
  // let role = user.data.data.role;

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleOpenNotifications = (event) =>
    setOpenNotifications(event.currentTarget);
  const handleCloseNotifications = () => setOpenNotifications(false);

  const navigate = useNavigate();

  const viewHome = () => {
    navigate("/");
  };
  function randomColor() {
    let r = Math.floor(Math.random() * 256);
    let g = Math.floor(Math.random() * 256);
    let b = Math.floor(Math.random() * 256);
    return "rgb(" + r + "," + g + "," + b + ")";
  }
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<Icon>person</Icon>}
        title="Profile"
        route="/profile"
      />
      <NotificationItem
        icon={<Icon>key</Icon>}
        title="Reset Password"
        route="/authentication/reset-password"
      />
      <NotificationItem
        icon={<Icon>lock</Icon>}
        title="Logout"
        route="/authentication/logout"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  let requestsData = profilesNotificationReducer?.profileData?.data
    ? profilesNotificationReducer?.profileData?.data
    : [];
    let notificationData  = notificationForRegisteredClientReducer?.notification?.data ? 
    notificationForRegisteredClientReducer?.notification?.data  : [];

  let notification = requestsData?.length;
  let notificationForAdmin = notificationData?.length;
  // let notificationData = requestsData[0].name;
  let n = (Math.random() * 0xfffff * 1000000).toString(16);
  // (Math.random() * 0xfffff * 1000000).toString(16);

  

  const renderNotifications = () => (
    <Menu
      anchorEl={openNotifications}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openNotifications)}
      onClose={handleCloseNotifications}
      sx={{ mt: 2, maxHeight: "500px", overflow: "auto" }} 
    > 
      {/* <MDTypography variant="h5"> Notifications</MDTypography> */}
      {notificationData.map((item) => (
        <>
          <MDCard
            style={{
              width:"300px",
              height: "90px",
              border: "1px solid #D8D8D8",
              borderRadius: "5px",
              padding: "10px",  
              paddingTop: "10px",  
              paddingBottom: "10px" ,   
              marginTop: "5px",   
              marginBottom: "10px" ,            
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 2px 4px",
            }}   
          > 
          
            
          <MDTypography variant="text" style={{ fontSize: "15px"}}>
           <span>  Name - </span> 
           <strong>{item.userName } </strong>{" "}
           </MDTypography>    
           
           <MDTypography variant="text" style={{ fontSize: "15px"}}>
           <span>  Company Name  - </span> 
           <strong>{item.companyName} </strong>{" "}
           </MDTypography>   
           {/* <MDTypography variant="text" style={{ fontSize: "15px"}}> {item.email}</MDTypography> */}
            <MDTypography fontSize="12px">
             
              {item.duration} 
            </MDTypography>
           
          
          </MDCard>
        </>
      ))}
    </Menu>
  );



  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
        </MDBox>
        {/* {isMini ? null : ( */}
        <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
          <MDBox color={light ? "white" : "inherit"}>
            {/* <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link> */}

            <Tooltip title="Toggle Sidebar">
              <IconButton
                size="small"
                // disableRipple
                color="inherit"
                // sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
            </Tooltip>
            {data.data.data.roleName === "Admin" && (
              <>
                

                <Tooltip title="Registered Client">
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarIconButton}
                    aria-controls="notification-menu"
                    aria-haspopup="true"
                    variant="contained"
                    onClick={handleOpenNotifications}
                  >
                    <Badge
                      badgeContent={notificationForAdmin}
                      color="error"
                      variant="standard"
                    > 
                      <Icon sx={iconsStyle}> notifications </Icon>
                    </Badge>
                  </IconButton>
                </Tooltip>
              </>
            )}

            <Tooltip title="Home">
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={viewHome}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  home
                </Icon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Profile">
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Settings">
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
            </Tooltip>
            {renderMenu()}
            {renderNotifications()}
           
          </MDBox>
        </MDBox>
        {/* )} */}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    profilesNotificationReducer: state.profilesNotificationReducer,
    notificationForRegisteredClientReducer: state.notificationForRegisteredClientReducer ,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    profilesNotification: (jwtToken, clientId) =>
      dispatch(profilesNotification(jwtToken, clientId)),
      getNotificationForRegisteredClient:(jwtToken) => dispatch(getNotificationForRegisteredClient(jwtToken))
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(DashboardNavbar);
