import {
  PUT_CLIENTDETAILS_REQUEST,
  PUT_CLIENTDETAILS_SUCCESS,
  PUT_CLIENTDETAILS_FAILURE,
  GET_PREVIEW_SUCCESS,
  GET_CLIENT_REQUESTS_FOR_CLIENT_REQUEST,
  GET_CLIENT_REQUESTS_FOR_CLIENT_SUCCESS,
  GET_CLIENT_REQUESTS_FOR_CLIENT_FAILURE,
  GET_LIST_OF_PROFILES_REQUEST,
  GET_LIST_OF_PROFILES_SUCCESS,
  GET_LIST_OF_PROFILES_FAILURE,
  GET_LIST_OF_SHORTLISTED_PROFILES_REQUEST,
  GET_LIST_OF_SHORTLISTED_PROFILES_SUCCESS,
  GET_LIST_OF_SHORTLISTED_PROFILES_FAILURE,
} from "./clientDetailsTypes";

import axios from "axios";
import Config from "../../config/index";
let auth3Url = Config.auth3Url;
let baseUrl = Config.baseUrl;

const createClientDetailsRequest = () => {
  return {
    type: PUT_CLIENTDETAILS_REQUEST,
  };
};

const createClientDetailsSuccess = (payload) => {
  return {
    type: PUT_CLIENTDETAILS_SUCCESS,
    payload: payload,
  };
};

const createClientDetailsFaliure = (error) => {
  return {
    type: PUT_CLIENTDETAILS_FAILURE,
    payload: error,
  };
};

const createClientDetails = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(createClientDetailsRequest());
    let url = `${baseUrl}/clients/client_details_form`;
    return axios
      .post(url, payload, headers)
      .then((response) => {
        dispatch(createClientDetailsSuccess(response));
        return { status: true, data: response };
      })
      .catch((err) => {
        dispatch(createClientDetailsFaliure(err.msg));
        return { status: false, data: err.msg };
      });
  };
};

const getPreviewSuccess = (payload) => {
  return {
    type: GET_PREVIEW_SUCCESS,
    payload: payload,
  };
};

const getPreview = (payload) => {
  return (dispatch) => {
    dispatch(getPreviewSuccess(payload));
  };
};

const getClientRequestForClientRequest = () => {
  return {
    type: GET_CLIENT_REQUESTS_FOR_CLIENT_REQUEST,
  };
};

const getClientRequestForClientSuccess = (payload) => {
  return {
    type: GET_CLIENT_REQUESTS_FOR_CLIENT_SUCCESS,
    payload: payload,
  };
};

const getClientRequestForClientFaliure = (error) => {
  return {
    type: GET_CLIENT_REQUESTS_FOR_CLIENT_FAILURE,
    payload: error,
  };
};

const getClientRequestForClient = (
  clientId,
  jwtToken,
  status,
  date,
  pageNo,
  rowsPerPage
) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getClientRequestForClientRequest());
    let url = `${baseUrl}/requests/get_requests_by_client_id?client_id=${clientId}&status=${status}&date=${date}&page_number=${
      pageNo + 1
    }&rows_per_page=${rowsPerPage}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getClientRequestForClientSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getClientRequestForClientFaliure(err.message));
        return { status: false };
      });
  };
};

const getListOfProfilesRequest = () => {
  return {
    type: GET_LIST_OF_PROFILES_REQUEST,
  };
};

const getListOfProfilesSuccess = (payload) => {
  return {
    type: GET_LIST_OF_PROFILES_SUCCESS,
    payload: payload,
  };
};

const getListOfProfilesFaliure = (error) => {
  return {
    type: GET_LIST_OF_PROFILES_FAILURE,
    payload: error,
  };
};

const getListOfProfiles = (clientId, requestId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getListOfProfilesRequest());
    let url = `${baseUrl}/profiles/get_profile_by_request_id?client_id=${clientId}&request_id=${requestId}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getListOfProfilesSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getListOfProfilesFaliure(err.message));
        return { status: false };
      });
  };
};

const getListOfShortlistedProfilesRequest = () => {
  return {
    type: GET_LIST_OF_SHORTLISTED_PROFILES_REQUEST,
  };
};

const getListOfShortlistedProfilesSuccess = (payload) => {
  return {
    type: GET_LIST_OF_SHORTLISTED_PROFILES_SUCCESS,
    payload: payload,
  };
};

const getListOfShortlistedProfilesFaliure = (error) => {
  return {
    type: GET_LIST_OF_SHORTLISTED_PROFILES_FAILURE,
    payload: error,
  };
};

const getListOfShortlistedProfiles = (clientId, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return (dispatch) => {
    dispatch(getListOfShortlistedProfilesRequest());
    let url = `${baseUrl}/profiles/get_sort_listed_profiles?client_id=${clientId}`;
    return axios
      .get(url, headers)
      .then((response) => {
        dispatch(getListOfShortlistedProfilesSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getListOfShortlistedProfilesFaliure(err.message));
        return { status: false };
      });
  };
};

export {
  createClientDetails,
  getPreview,
  getClientRequestForClient,
  getListOfProfiles,
  getListOfShortlistedProfiles,
};
