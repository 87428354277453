import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Formik & Yup
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "yup-phone";

// Country & State components
import { Country, State } from "country-state-city";

import NumberFormat from "react-number-format";
// React-Select component and styling
import Select from "react-select";
// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// React-DatePicker component and styling
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Selects from "@mui/material/Select";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import BasicLayout from "layouts/authentication/components/BasicLayoutForForm";

// Utils component
import TextError from "utils/TextError";
import "../../utils/input.css";

// Router components

import { useNavigate } from "react-router-dom";

// Store
import { getPreview } from "../../store";

// Cookies
import Cookies from "universal-cookie";
import { isValid } from "date-fns";
import FormPreviewForClient from "./FormPreviewForClient";
const _ = require("lodash");

const red = {
  // 100: "#2196f3",
  100: "#c51162",
  200: "#c51162",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  200: "#ff0000",
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: #fff;
    color: #2196f3;
  }

  &:focus {
    color: #2196f3;
    outline: 3px solid #2196f3;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: #2196f3;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  min-width: 400px;
  background-color: #2196f3;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  // box-shadow: 0px 4px 8px ${
    theme.palette.mode === "dark" ? "#d2042d" : "#d2042d"
  };
  `
);
// let loading = allAccountManagerReducer.loading;
const VendorForm = ({ getPreview, getPreviewReducer }) => {
  const [industryType, setIndustryType] = React.useState([]);

  const handleChangeForIndustrial = (event) => {
    const {
      target: { value },
    } = event;
    setIndustryType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const industryTypeNames = [
    "Staffing Services",
    "Knowledge Management",
    "IT Services and Consulting",
    "Software Application Development",
    "Fintech",
    "Financial Services",
    "EdTech",
    "Agritech",
    "e-Commerce",
    "InsurTech",
    "HealthTech",
    "AI/ML Start Up",
    "Bio Tech",
    "Retail Technology",
    "Data Analytics",
    "Green Energy",
    "Block Chain",
    "Venture Capital",
    "Cloud Services",
    "GovTech",
    "IT Infrastructure Management",
    "Industry 4.0",
    "Manufacturing and Distrubution",
    "Gaming",
    "Others",
  ];

  const navigate = useNavigate();

  const cookies = new Cookies();
  let data = cookies.get("mobillor-talent-accelerator-user-data");
  let email = data?.data?.data?.email;
  let clientId = data?.data?.data?.clientId;
  let jwtToken = data?.data?.jwtToken;
  let isFormFilled = data?.data?.data?.isFormFilled;
  let formFilled = cookies.get("isFormFilled");
  let createRequest = cookies.get("create-request");

  const [previewData, setPreviewData] = useState({});

  useEffect(() => {
    setPreviewData(getPreviewReducer?.preview);
  }, [getPreviewReducer]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const valuesAccountType = [
    { label: "Current Account", value: "Current Account" },
    { label: "Savings Account", value: "Savings Account" },
  ];
  const [selectedaccountType, setSelectedaccountType] = useState("");

  const handleaccountType = (selectedaccountType) => {
    setSelectedaccountType(selectedaccountType);
  };

  const [selectedindustrialType, setSelectedindustrialType] = useState("");

  const handleindustrialType = (selectedindustrialType) => {
    setSelectedindustrialType(selectedindustrialType);
  };

  const valuesLineOfBusiness = [
    { label: "Product development", value: "Product development" },
    { label: "Startup", value: "Startup" },
    {
      label: "IT consulting and services",
      value: "IT consulting and services",
    },
    { label: "Others", value: "Others" },
  ];

  const [selectedlineOfBusiness, setSelectedlineOfBusiness] = useState({});

  const handlelineOfBusiness = (selectedlineOfBusiness) => {
    setSelectedlineOfBusiness(selectedlineOfBusiness);
  };

  const valuesPaymentTerms = [
    { label: "7 Days", value: "7 Days" },
    { label: "15 Days", value: "15 Days" },
    { label: "30 Days", value: "30 Days" },
  ];

  const [selectedPaymentTerms, setSelectedPaymentTerms] = useState({});

  const handlePaymentTerms = (selectedPaymentTerms) => {
    setSelectedPaymentTerms(selectedPaymentTerms);
  };

  //   Whether SEZ/Non-SEZ
  const valuesSezNonsez = [
    { label: "SEZ", value: "SEZ" },
    { label: "Non-SEZ", value: "Non-SEZ" },
  ];
  const [selectedvaluesSezNonsez, setSelectedvaluesSezNonsez] = useState("");

  const handlevaluesSezNonsez = (selectedvaluesSezNonsez) => {
    setSelectedvaluesSezNonsez(selectedvaluesSezNonsez);
  };

  const listOfCountries = Country.getAllCountries();
  const countryDetails = [];

  listOfCountries.map((country) =>
    countryDetails.push({
      name: country.name,
      currency: country.currency,
      phoneCode: country.phonecode,
      countryCode: country.isoCode,
      label: country.name,
      value: country.name,
    })
  );

  const countryDetails2 = [];

  listOfCountries.map((country) =>
    countryDetails2.push({
      name: country.name,
      currency: country.currency,
      phoneCode: country.phonecode,
      countryCode: country.isoCode,
      label: country.currency,
      value: country.currency,
    })
  );

  const listOfCountriesName = Country.getAllCountries();
  const countryDetailsName = [];

  listOfCountriesName.map((country) =>
    countryDetailsName.push({
      name: country.name,
      currency: country.currency,
      phoneCode: country.phonecode,
      countryCode: country.isoCode,
      label: country.name,
      value: country.name,
    })
  );

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountry = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const [selectedValidity, setSelectedValidity] = useState("");

  const handleValidityChange = (selectedValidity) => {
    setSelectedValidity(selectedValidity);
  };

  const [selectedState, setSelectedState] = useState("");

  const handleStateChange = (selectedState) => {
    setSelectedState(selectedState);
  };

  const [selectedCountryName, setSelectedCountryName] = useState("");

  const handleCountryName = (selectedCountryName) => {
    setSelectedCountryName(selectedCountryName);
  };

  const updatedStates = (countryId) =>
    State.getStatesOfCountry(countryId).map((state) => ({
      label: state.name,
      value: state.name,
      ...state,
    }));

  const [startDate, setStartDate] = useState("");

  const initialValues = {
    lineOfBusiness: previewData.lineOfBusiness
      ? previewData.lineOfBusiness
      : "",
    currency: previewData.currency ? previewData.currency : "INR",
    companyName: previewData.companyName ? previewData.companyName : "",
    buildingNoName: previewData.buildingNoName
      ? previewData.buildingNoName
      : "",
    floor: previewData.floor ? previewData.floor : "",
    street: previewData.street ? previewData.street : "",
    country: previewData.country ? previewData.country : "",
    state: previewData.state ? previewData.state : "",
    city: previewData.city ? previewData.city : "",
    pinCode: previewData.pinCode ? previewData.pinCode : "",
    contactPerson: previewData.contactPerson ? previewData.contactPerson : "",
    countryCode: previewData.countryCode ? previewData.countryCode : "",
    contactNo: previewData.contactNo ? previewData.contactNo : "",
    industrialType: previewData.industrialType
      ? previewData.industrialType
      : [""],
    email: previewData.email ? previewData.email : email,
    pan: previewData.pan ? previewData.pan : "",
    gstNo: previewData.gstNo ? previewData.gstNo : "",
    paymentTerms: previewData.paymentTerms ? previewData.paymentTerms : "",
    cinNumber: previewData.cinNumber ? previewData.cinNumber : "",
  };

  const pancardRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
  const gstnoRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const cinnumberRegex =
    /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
  const pfaccountnumberRegex =
    /[A-Z]{2}\/[A-Z]{3}\/[0-9]{7}\/[0-9A-Z]{2}[0-9]{1}\/[0-9]{7}/;
  const ifsccodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    lineOfBusiness: Yup.string().required("Choose a Line Of Business"),
    currency: Yup.string().required("Choose a Currency"),
    companyName: Yup.string().required("Enter a Company Name"),
    buildingNoName: Yup.string().required("Enter a Building Number/Name"),
    floor: Yup.string().required("Enter a Floor"),
    street: Yup.string().required("Enter a Street"),
    country: Yup.string().required("Choose a Country"),
    state: Yup.string().required("Choose a State"),
    city: Yup.string().required("Enter a City"),
    pinCode: Yup.string()
      .required("Enter a Pincode")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),
    contactPerson: Yup.string().required("Enter a Contact Person"),
    contactNo: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Enter a Contact Number"),
    email: Yup.string().email("E-mail is Invalid").required("Enter a E-mail"),
    pan: Yup.string().required("Enter PAN/TAX number"),
    gstNo: Yup.string().matches(gstnoRegex, "Invalid Format"),
    // cinNumber: Yup.string().matches(cinnumberRegex, "Invalid Format"),
  });

  const [countryIsoCode, setCountryIsoCode] = useState("");

  const countryCodeHandler = (code) => {
    setCountryIsoCode(code);
  };

  const onSubmit = (values) => {
    values.countryCode = countryIsoCode;
    values.lineOfBusiness = selectedlineOfBusiness.value;
    values.state = selectedState.name;
    values.industrialType = industryType;
    values.lutDate = startDate;
    getPreview(values);
    navigate("/preview");
  };

  const [expanded, setExpanded] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
  });

  const Currency = countryDetails2.filter((country) => country.currency !== "");
  const key = "currency";

  const valuesCurrency = [
    ...new Map(Currency.map((item) => [item[key], item])).values(),
  ];

  const [selectedCurrency, setSelectedCurrency] = useState("");

  const handleCurrency = (selectedlineOfBusiness) => {
    setSelectedCurrency(selectedlineOfBusiness);
  };

  useEffect(() => {
    if (previewData) {
      setSelectedlineOfBusiness({
        label: previewData.lineOfBusiness,
        value: previewData.lineOfBusiness,
      });

      setSelectedCurrency({
        label: previewData.currency ? previewData.currency : "INR",
        value: previewData.currency ? previewData.currency : "INR",
      });

      setSelectedCountry({
        label: previewData.country,
        value: previewData.country,
      });

      setSelectedState({
        label: previewData.state,
        name: previewData.state,
      });

      setSelectedvaluesSezNonsez({
        label: previewData.sezNonsez,
        value: previewData.sezNonsez,
      });

      setSelectedaccountType({
        label: previewData.accountType,
        value: previewData.accountType,
      });
    }
  }, [previewData]);

  useEffect(() => {
    if (previewData) {
      setStartDate(previewData.lutDate);
    }

    if (previewData.industrialType) {
      setIndustryType(previewData.industrialType);
    }
  }, [previewData]);

  const [previewButtonColor, setPreviewButtonColor] = useState("#2196f3");
  const [cursorCss, setCursorCss] = useState("auto");

  setTimeout(() => {
    setPreviewButtonColor("#2196f3");
    setCursorCss("pointer");
  }, [20000]);
  const notifyForClientRequest = () => {
    toast.info("First you have to fill the client details form", {
      position: "top-center",
      className: "toast-message",
    });
  };

  if (createRequest == "true") {
    setTimeout(() => {
      notifyForClientRequest();
    }, 1000);
    cookies.remove("create-request");
  }

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />

        {isFormFilled == true || formFilled == "true" ? (
          <>
            <FormPreviewForClient />
          </>
        ) : (
          <>
            <div style={{ position: "absolute" }}>
              <MDCard
                style={{
                  width: "98%",
                  marginRight: "-15px",
                }}
              >
                <CardContent align="center">
                  <MDTypography
                    mt={1}
                    mb={3} 
                    variant="h2"
                    fontWeight="medium"
                    style={{ display: "grid", justifyContent: "center" }}
                  > 
                    Client Form
                  </MDTypography>
                  <MDBox>
                    <TabsUnstyled defaultValue={0}>
                      <TabsList style={{ width: "95%" }}>
                        <Tab style={{ padding: "15px" }}>
                          {" "}
                          Business Partner Details{" "}
                        </Tab>
                        <Tab style={{ padding: "15px" }}>
                          {" "}
                          Statutory Information{" "}
                        </Tab>
                      </TabsList>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize={true}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          formik,
                          handleChange,
                          handleBlur,
                          setFieldValue,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                        }) => (
                          <Form>
                            <TabPanel value={0}>
                              <Grid container mt={3}>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      Line of Business{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>

                                    <Select
                                      className="select-css-for-client-form"
                                      onBlur={() => {
                                        handleBlur({
                                          target: { name: "lineOfBusiness" },
                                        });
                                      }}
                                      // placeholder={p}
                                      name="lineOfBusiness"
                                      value={selectedlineOfBusiness}
                                      options={valuesLineOfBusiness}
                                      onChange={(selectedOption) => {
                                        handlelineOfBusiness(selectedOption);
                                        handleChange("lineOfBusiness")(
                                          selectedOption.value
                                        );
                                      }}
                                    />

                                    {errors.lineOfBusiness &&
                                      touched.lineOfBusiness && (
                                        <TextError
                                          msg={errors.lineOfBusiness}
                                        />
                                      )}
                                  </MDBox>
                                </Grid>

                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      Currency
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>

                                    <Select
                                      className="select-css-for-client-form"
                                      // placeholder="Select..."
                                      defaultValue={{
                                        label: "INR",
                                        value: "INR",
                                      }}
                                      name="currency"
                                      onBlur={() => {
                                        handleBlur({
                                          target: { name: "currency" },
                                        });
                                      }}
                                      value={selectedCurrency}
                                      options={valuesCurrency}
                                      onChange={(selectedOption) => {
                                        handleCurrency(selectedOption);
                                        handleChange("currency")(
                                          selectedOption.value
                                        );
                                      }}
                                    />

                                    {errors.currency && touched.currencye && (
                                      <TextError msg={errors.currency} />
                                    )}
                                  </MDBox>
                                </Grid>

                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      Company Name{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>
                                    <MDInput
                                      type="text"
                                      inputProps={{ maxLength: 30 }}
                                      name="companyName"
                                      fullWidth
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.companyName}
                                      style={{ width: "400px" }}
                                    />

                                    {errors.companyName &&
                                      touched.companyName && (
                                        <TextError msg={errors.companyName} />
                                      )}
                                  </MDBox>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      Building Number/Name{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>
                                    <MDInput
                                      // label="Building No/Name"
                                      inputProps={{ maxLength: 30 }}
                                      type="text"
                                      name="buildingNoName"
                                      fullWidth
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.buildingNoName}
                                      style={{ width: "400px" }}
                                    />

                                    {errors.buildingNoName &&
                                      touched.buildingNoName && (
                                        <TextError
                                          msg={errors.buildingNoName}
                                        />
                                      )}
                                  </MDBox>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      Floor{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>
                                    <MDInput
                                      // label="Floor"
                                      type="text"
                                      inputProps={{ maxLength: 30 }}
                                      name="floor"
                                      fullWidth
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.floor}
                                      style={{ width: "400px" }}
                                    />

                                    {errors.floor && touched.floor && (
                                      <TextError msg={errors.floor} />
                                    )}
                                  </MDBox>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      Street{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>
                                    <MDInput
                                      // label="Street"
                                      type="text"
                                      name="street"
                                      fullWidth
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.street}
                                      style={{ width: "400px" }}
                                    />

                                    {errors.street && touched.street && (
                                      <TextError msg={errors.street} />
                                    )}
                                  </MDBox>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      Country{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>

                                    <Select
                                      className="select-css-for-client-form"
                                      // placeholder="Select..."
                                      onBlur={() => {
                                        handleBlur({
                                          target: { name: "country" },
                                        });
                                      }}
                                      name="country"
                                      value={selectedCountry}
                                      options={countryDetails}
                                      onChange={(selectedOption) => {
                                        handleCountry(selectedOption);
                                        handleChange("country")(
                                          selectedOption.name
                                        );
                                      }}
                                    />

                                    {/* {errors.country && (
                                      <TextError msg={errors.country} />
                                    )} */}
                                    {errors.country && touched.country && (
                                      <TextError msg={errors.country} />
                                    )}
                                  </MDBox>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      State{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>

                                    <Select
                                      className="select-css-for-client-form"
                                      // placeholder="Select..."
                                      onBlur={() => {
                                        handleBlur({
                                          target: { name: "state" },
                                        });
                                      }}
                                      name="state"
                                      value={selectedState ? selectedState : ""}
                                      options={updatedStates(
                                        selectedCountry
                                          ? selectedCountry.countryCode
                                          : null
                                      )}
                                      onChange={(selectedOption) => {
                                        handleStateChange(selectedOption);
                                        handleChange("state")(
                                          selectedOption.value
                                        );
                                      }}
                                    />
                                    {errors.state && touched.state && (
                                      <TextError msg={errors.state} />
                                    )}
                                  </MDBox>
                                </Grid>

                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      City{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>
                                    <MDInput
                                      // label="City"
                                      type="text"
                                      inputProps={{ maxLength: 30 }}
                                      name="city"
                                      fullWidth
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.city}
                                      style={{ width: "400px" }}
                                    />

                                    {errors.city && touched.city && (
                                      <TextError msg={errors.city} />
                                    )}
                                  </MDBox>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      Pincode{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>
                                    <NumberFormat
                                      style={{
                                        width: "400px",
                                        height: "40px",
                                        borderRadius: "5px",
                                        border:
                                          "0.2px solid rgba(0, 0, 0, 0.125)",
                                      }}
                                      type="text"
                                      name="pinCode"
                                      inputProps={{ maxLength: 6 }}
                                      fullWidth
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.pinCode}
                                      format="######"
                                      mask={"X"}
                                      placeholder=" EX:- 455336"
                                    />

                                    {errors.pinCode && touched.pinCode && (
                                      <TextError msg={errors.pinCode} />
                                    )}
                                  </MDBox>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      Contact Person{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>
                                    <MDInput
                                      // label="Contact Person"
                                      inputProps={{ maxLength: 35 }}
                                      type="text"
                                      name="contactPerson"
                                      fullWidth
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.contactPerson}
                                      style={{ width: "400px" }}
                                    />

                                    {errors.contactPerson &&
                                      touched.contactPerson && (
                                        <TextError msg={errors.contactPerson} />
                                      )}
                                  </MDBox>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      Contact Number{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>{" "}
                                    <Field
                                      readOnly
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "5px",
                                        border:
                                          "0.2px solid rgba(0, 0, 0, 0.125)",
                                      }}
                                      className="phonecode"
                                      onChange={countryCodeHandler(
                                        values.country
                                          ? "+" +
                                              countryDetails.filter(
                                                (country) =>
                                                  country.name ===
                                                  values.country
                                              )[0].phoneCode
                                          : ""
                                      )}
                                      value={
                                        values.country
                                          ? "+" +
                                            countryDetails.filter(
                                              (country) =>
                                                country.name === values.country
                                            )[0].phoneCode
                                          : ""
                                      }
                                    />
                                    <span style={{ paddingLeft: "0.4rem" }} />
                                    <MDInput
                                      type="number"
                                      name="contactNo"
                                      disabled={values.country ? false : true}
                                      fullWidth
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.contactNo}
                                      style={{ width: "360px" }}
                                      inputProps={{ maxLength: 14 }}
                                    />
                                    {errors.contactNo && touched.contactNo && (
                                      <TextError msg={errors.contactNo} />
                                    )}
                                  </MDBox>
                                </Grid>

                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      Email{" "}
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>
                                    <MDInput
                                      // label="Email"
                                      inputProps={{ readOnly: true }}
                                      type="text"
                                      name="email"
                                      fullWidth
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.email}
                                      style={{ width: "400px" }}
                                    />

                                    {errors.email && touched.email && (
                                      <TextError msg={errors.email} />
                                    )}
                                  </MDBox>
                                </Grid>

                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <div>
                                    <MDTypography variant="h6">
                                      Select Industrial Type
                                    </MDTypography>
                                    <FormControl
                                      sx={{ m: 0, width: 400, height: 45 }}
                                    >
                                      <InputLabel id="demo-multiple-checkbox-label">
                                        Select Industrial Type
                                      </InputLabel>
                                      <Selects
                                        style={{ height: "100px" }}
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={industryType}
                                        onChange={handleChangeForIndustrial}
                                        input={
                                          <OutlinedInput label="Select Industrial Type" />
                                        }
                                        renderValue={(selected) =>
                                          selected.join(", ")
                                        }
                                        MenuProps={MenuProps}
                                      >
                                        {industryTypeNames.map((name) => (
                                          <MenuItem key={name} value={name}>
                                            <Checkbox
                                              checked={
                                                industryType.indexOf(name) > -1
                                              }
                                            />
                                            <ListItemText primary={name} />
                                          </MenuItem>
                                        ))}
                                      </Selects>
                                    </FormControl>
                                  </div>
                                </Grid>
                              </Grid>
                            </TabPanel>
                            <TabPanel value={1}>
                              <Grid
                                container
                                spacing={1}
                                mt={3}
                                alignItems="center"
                              >
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      PAN/TAX Number
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: 5,
                                          marginBottom: 15,
                                        }}
                                      >
                                        *
                                      </span>
                                    </MDTypography>
                                    <MDInput
                                      placeholder="Enter PAN/TAX Number"
                                      type="text"
                                      name="pan"
                                      inputProps={{ maxLength: 10 }}
                                      fullWidth
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.pan}
                                      style={{ width: "400px" }}
                                    />

                                    {errors.pan && touched.pan && (
                                      <TextError msg={errors.pan} />
                                    )}
                                  </MDBox>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      GST Number{" "}
                                    </MDTypography>
                                    <MDInput
                                      placeholder="Enter Gst Number"
                                      inputProps={{ maxLength: 20 }}
                                      // label="Gst No"
                                      type="text"
                                      name="gstNo"
                                      fullWidth
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.gstNo}
                                      style={{ width: "400px" }}
                                    />

                                    {errors.gstNo && touched.gstNo && (
                                      <TextError msg={errors.gstNo} />
                                    )}
                                  </MDBox>
                                </Grid>

                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2} >
                                    <MDTypography variant="h6">
                                      Payment Terms
                                    </MDTypography>

                                    <Select
                                      className="select-css-for-client-form"
                                      onBlur={() => {
                                        handleBlur({
                                          target: { name: "paymentTerms" },
                                        });
                                      }}
                                      // placeholder={p}
                                      name="paymentTerms"
                                      value={selectedPaymentTerms}
                                      options={valuesPaymentTerms}
                                      onChange={(selectedOption) => {
                                        handlePaymentTerms(selectedOption);
                                        handleChange("paymentTerms")(
                                          selectedOption.value
                                        );
                                      }}
                                    />

                                    {/* <MDInput
                                      // label="Payment Terms"
                                      type="text"
                                      name="paymentTerms"
                                      fullWidth
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.paymentTerms}
                                      style={{ width: "400px" }}
                                    /> */}

                                    {errors.paymentTerms &&
                                      touched.paymentTerms && (
                                        <TextError msg={errors.paymentTerms} />
                                      )}
                                  </MDBox>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h6">
                                      Company Registration Number
                                    </MDTypography>
                                    <MDInput
                                      placeholder="Enter Company Registration Number"
                                      // label="CIN Number"
                                      type="text"
                                      name="cinNumber"
                                      fullWidth
                                      autoComplete="off"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.cinNumber}
                                      style={{ width: "400px" }}
                                    />

                                    {errors.cinNumber && touched.cinNumber && (
                                      <TextError msg={errors.cinNumber} />
                                    )}
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </TabPanel>

                            {!isValid ? (
                              <MDButton
                                color="info"
                                // style={{ backgroundColor: "red", marginTop: "20px" }}
                                type="submit"
                                disabled={!isValid}
                              >
                                Preview
                              </MDButton>
                            ) : (
                              <MDButton
                                color="info"
                                style={{
                                  backgroundColor: previewButtonColor,
                                  cursor: cursorCss,
                                  marginTop: "20px",
                                }}
                                type="submit"
                              >
                                Preview
                              </MDButton>
                            )}
                          </Form>
                        )}
                      </Formik>
                    </TabsUnstyled>
                  </MDBox>
                </CardContent>
              </MDCard>
            </div>
          </>
        )}

        <ToastContainer />
      </DashboardLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getPreviewReducer: state.getPreviewReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPreview: (payload) => dispatch(getPreview(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorForm);
