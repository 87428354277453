/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE,
  SET_PASSWORD_FOR_NEW_USER_REQUEST,
  SET_PASSWORD_FOR_NEW_USER_SUCCESS,
  SET_PASSWORD_FOR_NEW_USER_FAILURE,
  SET_NEWPASSWORD_REQUEST,
  SET_NEWPASSWORD_SUCCESS,
  SET_NEWPASSWORD_FAILURE,
} from "./setPasswordTypes";

const initialStateForSetPassword = {
  loading: false,
  succeeded: false,
  setPassword: {},
  error: "",
};

const setPasswordReducer = (state = initialStateForSetPassword, action) => {
  switch (action.type) {
    case SET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        succeeded: false,
      };
    case SET_PASSWORD_SUCCESS:
      return {
        loading: true,
        succeeded: true,
        setPassword: action.payload,
        error: "",
      };
    case SET_PASSWORD_FAILURE:
      return {
        loading: false,
        succeeded: false,
        setPassword: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  succeeded: false,
  setPasswordForNewUser: {},
  error: "",
};

const setPasswordForNewUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PASSWORD_FOR_NEW_USER_REQUEST:
      return {
        ...state,
        loading: true,
        succeeded: false,
      };
    case SET_PASSWORD_FOR_NEW_USER_SUCCESS:
      return {
        loading: true,
        succeeded: true,
        setPasswordForNewUser: action.payload,
        error: "",
      };
    case SET_PASSWORD_FOR_NEW_USER_FAILURE:
      return {
        loading: false,
        succeeded: false,
        setPasswordForNewUser: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForSetNewPassword = {
  loading: false,
  succeeded: false,
  setNewPassword: {},
  error: "",
};

const setNewPasswordReducer = (state = initialStateForSetNewPassword, action) => {
  switch (action.type) {
    case SET_NEWPASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        succeeded: false,
      };
    case SET_NEWPASSWORD_SUCCESS:
      return {
        loading: true,
        succeeded: true,
        setNewPassword: action.payload,
        error: "",
      };
    case SET_NEWPASSWORD_FAILURE:
      return {
        loading: false,
        succeeded: false,
        setNewPassword: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export { setPasswordReducer, setPasswordForNewUserReducer, setNewPasswordReducer };
