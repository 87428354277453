/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
} from "react-table";

// @mui material components
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Dashboard example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

function DataTable({
  entriesPerPage,
  showTotalEntries,
  isSorted,
  canSearch,
  noEndBorder,
  table,
  pagination,
  selection,
  selectionButtonComponent,
  selectionButtonClick,
  nestedTable,
  totalRecords,
  uncheckProp,
  tableSearch,
  serverSidePagination,
  paginationObj,
  pageNoProp,
  rowsPerPageProp,
  searchProp,
  pageNoValue,
  rowsPerPageValue,
}) {
  const ColumnSearching = ({ column }) => {
    const { filterValue, setFilter } = column;

    return (
      <MDBox width="8rem" mt={1}>
        <MDInput
          variant="standard"
          placeholder="Search..."
          value={filterValue || ""}
          size="small"
          onChange={(e) => setFilter(e.target.value)}
        />
      </MDBox>
    );
  };

  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : totalRecords;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const defaultColumn = useMemo(() => {
    return { Filter: ColumnSearching };
  }, [table]);

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualPagination: serverSidePagination ? true : false,
      initialState: {
        pageIndex: pageNoValue ? pageNoValue : 0,
        pageSize: rowsPerPageValue ? rowsPerPageValue : 5,
      },
      stateReducer: (newState, action, prevState) => {
        
        if (serverSidePagination) {
          switch (action.type) {
            case "setPageSize":
              return { ...prevState, pageSize: action.pageSize };
            case "gotoPage":
              return { ...prevState, pageIndex: action.pageIndex };
            default:
              return newState;
          }
        } else {
          return;
        }
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    dispatch,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}   
    </MDPagination>
  ));

  const renderServerPagination = paginationObj?.totalPages?.map((option) => (
    <MDPagination 
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  const exportPageNo = () => {
    pageNoProp(pageIndex);
  };

  useEffect(() => {
    if (serverSidePagination) {
      exportPageNo();
    }
  }, [pageIndex]);

  const exportRowsPerPage = () => {
    rowsPerPageProp(pageSize);
  };
  useEffect(() => {
    if (serverSidePagination) {
      exportRowsPerPage();
    }
  }, [pageSize]);

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));

  const handleInputServerPagination = ({ target: { value } }) => {
    dispatch({ type: "gotoPage", pageIndex: value });
    // gotoPage(Number(value));
    value > paginationObj?.totalPages?.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));
  };

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);
  const customizedServerPageOptions = paginationObj?.totalPages?.map(
    (option) => option + 1
  );

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    if (serverSidePagination) {
      gotoPage(0);
    }
    setGlobalFilter(value || undefined);
    searchProp(value || "");
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart =
    pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected([]);
  }, [uncheckProp]);

  const exportSelectedData = () => {
    selectionButtonClick(selected);
  };
  useEffect(() => {
    if (selection) {
      exportSelectedData();
    }
  }, [selected]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n);

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, singleObject) => {
    const selectedIndex = selected.indexOf(singleObject);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, singleObject);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (privateKey) => {
    return selected.findIndex((obj) => obj.privateKey === privateKey) !== -1;
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {entriesPerPage || canSearch ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {selected.length > 0 && (
            <MDBox
              borderRadius="lg"
              // display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={0.5}
              sx={{
                border: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              }}
              style={{
                width: "10rem",
                textAlign: "center",
                marginLeft: "8rem",
              }}
            >
              <MDTypography variant="h6" fontWeight="medium">
                {selected.length} selected
              </MDTypography>
            </MDBox>
          )}
          {selected.length > 0 && (
            <MDBox style={{ marginLeft: "1rem" }}>
              {selectionButtonComponent}
            </MDBox>
          )} 
          {canSearch && (
            <MDBox width="15rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null} 
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {nestedTable && ( 
                <TableCell padding="checkbox">
                  <React.Fragment />
                </TableCell>
              )}
              {selection && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 && selected.length < data.length
                    }
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                  {tableSearch && column.canFilter
                    ? column.render("Filter")
                    : null}
                  {tableSearch && !column.canFilter && (
                    <MDBox width="8rem" mt={4}></MDBox>
                  )}
                  {/* {tableSearch && column.search && (
                    <MDBox width="8rem" mt={1}>
                      <MDInput
                        variant="standard"
                        placeholder="Search..."
                        value={search}
                        size="small"
                        onChange={({ currentTarget }) => {
                          setSearch(search);
                          onSearchChange(currentTarget.value);
                        }}
                      />
                    </MDBox>
                  )} */}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);

            const isItemSelected = isSelected(row.original.privateKey);
            const labelId = `enhanced-table-checkbox-${key}`;

            
            return (
              <React.Fragment key={key}>
                <TableRow {...row.getRowProps()}>
                  {nestedTable && (
                    <TableCell padding="checkbox">
                      {row.original.expand}
                    </TableCell>
                  )}
                  {selection && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(e) => {
                          handleClick(e, row.original);
                        }}
                      />
                    </TableCell>
                  )}
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                   {row.original.items}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              {/* Showing {entriesStart} to {entriesEnd} of {rows.length} entries */}
              Showing {paginationObj ? paginationObj.firstRow : entriesStart} to{" "}
              {paginationObj ? paginationObj.lastRow : entriesEnd} of{" "}
              {paginationObj ? paginationObj.totalRows : rows.length} entries
              {/* Showing {paginationObj ? paginationObj.row : entriesStart} to{" "}
              {paginationObj ? paginationObj.totalRows : entriesEnd} of{" "}
              {paginationObj
                ? paginationObj.rowsPerPage * paginationObj.currentPage
                : rows.length}{" "}
              entries */}
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{
                    type: "number",
                    min: 1,
                    max: customizedPageOptions.length,
                  }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
        {/* <div>
          <button onClick={() => previousPage()}>Previous</button>
          <button onClick={() => nextPage()}>Next</button>
        </div> */}
        {paginationObj?.totalPages?.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination
                item
                onClick={() => {
                  dispatch({ type: "gotoPage", pageIndex: pageIndex - 1 });
                  gotoPage(pageIndex - 1);
                  // previousPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderServerPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{
                    type: "number",
                    min: 1,
                    max: customizedServerPageOptions.length,
                  }}
                  value={customizedServerPageOptions[pageIndex]}
                  onChange={
                    (handleInputServerPagination, handleInputPaginationValue)
                  }
                />
              </MDBox>
            ) : (
              renderServerPagination
            )}
            {pageIndex + 1 < paginationObj.lastPage && (
              <MDPagination
                item
                onClick={() => {
                  dispatch({ type: "gotoPage", pageIndex: pageIndex + 1 });
                  gotoPage(pageIndex + 1);
                  // nextPage();
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  selection: false,
  selectionButtonClick: () => {},
  selectionButtonComponent: <span />,
  nestedTable: false,
  tableSearch: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  selection: PropTypes.bool,
  selectionButtonClick: PropTypes.func,
  selectionButtonComponent: PropTypes.object,
  nestedTable: PropTypes.bool,
  tableSearch: PropTypes.bool,
};

export default DataTable;
