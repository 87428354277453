// /*********************
//  * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
//  * __________________
//  *
//  *  Mobillor Technologies Pvt. Ltd.
//  *  All Rights Reserved.
//  *
//  * NOTICE:  All information contained herein is, and remains
//  * the property of Mobillor Technologies Pvt. Ltd.
//  * The intellectual and technical concepts contained
//  * herein are proprietary to Mobillor Technologies Pvt. Ltd.
//  * may be covered by Rebublic Of India and Foreign Patents,
//  * patents in process, and are protected by trade secret or copyright law.
//  * Dissemination of this information or reproduction of this material
//  * is strictly forbidden unless prior written permission is obtained
//  * from Mobillor Technologies Pvt. Ltd.
//  */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import TextError from "utils/TextError";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

//  some date functions
import { format, addMinutes } from "date-fns";

// DatePicker components and  styling
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//  Formik & Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// moment
import moment from "moment";
// @mui material icons
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";

// Functions from store

import {
  allClientRequests,
  acceptAndRejectClient,
  getAllAccountManager,
} from "../../store";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

const ClientRequest = ({
  allClientRequestsReducer,
  allClientRequests,
  acceptAndRejectClient,
  getAllAccountManager,
}) => {
  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: "#4caf50",
          },
          colorPrimary: {
            "&.Mui-checked": {
              // Controls checked color for the thumb
              color: "#4caf50",
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.5,
            backgroundColor: "#4caf50",
            ".Mui-checked.Mui-checked + &": {
              // Controls checked color for the track
              opacity: 0.6,
              backgroundColor: "#4caf50",
            },
          },
        },
      },
    },
  });

  const themeForRejected = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: "#d32f2f",
          },
          colorPrimary: {
            "&.Mui-checked": {
              // Controls checked color for the thumb
              color: "#d32f2f",
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.5,
            backgroundColor: "#d32f2f",
            ".Mui-checked.Mui-checked + &": {
              // Controls checked color for the track
              opacity: 0.6,
              backgroundColor: "#d32f2f",
            },
          },
        },
      },
    },
  });
  const themeForPending = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: "white",
          },
          colorPrimary: {
            "&.Mui-checked": {
              // Controls checked color for the thumb
              color: "white",
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.5,
            backgroundColor: "gray",
            ".Mui-checked.Mui-checked + &": {
              // Controls checked color for the track
              opacity: 0.6,
              backgroundColor: "gray",
            },
          },
        },
      },
    },
  });

  let data = cookies.get("mobillor-talent-accelerator-user-data");
  let email = data?.data?.data?.email;
  let jwtToken = data?.data?.jwtToken;
  const [statusSelected, setStatusSelected] = useState("");
  const [startDate, setStartDate] = useState("");

  const isoStartDate = new Date(
    (startDate ? startDate : new Date()).getTime() -
      (startDate ? startDate : new Date()).getTimezoneOffset() * 60000
  ).toISOString();

  let status = statusSelected ? statusSelected?.value : "";
  let date = startDate ? isoStartDate.slice(0, 10) : "";
  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearch(globalFilter);
  };

  useEffect(() => {
    allClientRequests(jwtToken, status, date, pageNo, rowsPerPage);
  }, [allClientRequests, status, date, pageNo, rowsPerPage]);

  useEffect(() => {
    getAllAccountManager(jwtToken);
  }, [getAllAccountManager]);

  const options = [
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },
    { value: "Pending", label: "Pending" },
  ];

  const styleForAcceptModel = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    boxShadow: 24,
    p: 1,
    pt: 3,
  };

  const styleForView = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 600, lg: 600, xl: 600 },
    boxShadow: 24,
    p: 2,
    pt: 3,
  };
  const styleForDocument = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 600, lg: 900, xl: 900 },
    overflow: "auto",
    height: "90%",
    boxShadow: 24,
    p: 1,
    pt: 3,
  };

  const navigate = useNavigate();

  const [state, setState] = useState({
    columns: [
      { Header: "Company Name", accessor: "companyName" },
      { Header: "Request Time", accessor: "requestTime" },
      { Header: "Job Title", accessor: "jobTitle" },
      { Header: "Skill", accessor: "skill" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Accept Request ", accessor: "acceptRequest", align: "center" },
      { Header: "Action", accessor: "jobDescription", align: "center" },
    ],
    rows: [],
  });

  // const [loading , setLoading] = useState("");
  const { columns, rows } = state;
  const [paginationObj, setPaginationObj] = useState({});

  const [document, setDocument] = useState("");
  let loading = allClientRequestsReducer.loading;
  useEffect(() => {
    let tempRequests = [];
    let data = allClientRequestsReducer?.clientsData?.data
      ? allClientRequestsReducer?.clientsData?.data
      : [];

    if (allClientRequestsReducer?.clientsData?.data?.length) {
      setPaginationObj(allClientRequestsReducer?.clientsData?.pagination);
      data?.map((data) => {
        const formatDate = new Date(data.cd);
        const singledata = {
          companyName: data.companyName,

          requestTime: format(
            addMinutes(formatDate, formatDate.getTimezoneOffset()),
            "do MMMM yyyy"
          ),
          status: data.status,
          skill: data.technology,
          jobTitle: data.jobTitle,
          jobDescription: (
            <span style={{ display: "flex" }}>
              <Tooltip title="View">
                <MDButton
                  style={{ marginLeft: "10px" }}
                  variant="gradient"
                  color="info"
                  iconOnly
                  type="button"
                  onClick={() => {
                    handleOpenViewJobDescriptionModal(data);
                  }}
                >
                  <Icon> visibility_icon </Icon>
                </MDButton>
              </Tooltip>
              <Tooltip title="View Document">
                <MDButton
                  style={{ marginLeft: "10px" }}
                  variant="gradient"
                  color="primary"
                  iconOnly
                  type="button"
                  onClick={() => {
                    handleOpenViewDocumentModal(data);
                    setDocument(data.url);
                  }}
                >
                  {" "}
                  <FileOpenIcon />
                </MDButton>
              </Tooltip>

              <>
                <Tooltip title="Reject Request">
                  <MDButton
                    style={{ marginLeft: "10px" }}
                    variant="gradient"
                    color="error"
                    iconOnly
                    disabled={data.status === "Rejected"}
                    type="button"
                    onClick={() => {
                      handleOpenRejectModal(data);
                    }}
                  >
                    <Icon> cancel_icon </Icon>
                  </MDButton>
                </Tooltip>
              </>
            </span>
          ),
          status: data.status,
          acceptRequest: (
            <>
              {data.status === "Accepted" && (
                <>
                  <ThemeProvider theme={theme}>
                    <Switch
                      inputProps={{ readOnly: true }}
                      // disabled
                      checked={data.status === "Accepted" ? true : false}
                      onChange={() => {
                        // handleOpenAcceptModal(data);
                      }}
                    />
                  </ThemeProvider>
                </>
              )}
              {data.status === "Pending" && (
                <>
                  <ThemeProvider theme={""}>
                    <Switch
                      checked={data.status === "Accepted" ? true : false}
                      onChange={() => {
                        handleOpenAcceptModal(data);
                      }}
                    />
                  </ThemeProvider>
                </>
              )}

              {data.status === "Rejected" && (
                <>
                  <ThemeProvider theme={themeForRejected}>
                    <Switch
                      checked={data.status === "Accepted" ? true : false}
                      onChange={() => {
                        handleOpenAcceptModal(data);
                      }}
                    />
                  </ThemeProvider>
                </>
              )}
            </>
          ),
        };
        tempRequests.push(singledata);
      });
    }
    setState({ ...state, rows: tempRequests });
  }, [allClientRequestsReducer]);

  const changeStatus = (selectedData) => {
    setStatusSelected(selectedData);
  };

  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [dataForAcceptClient, setDataForAcceptClient] = useState({});

  const handleOpenAcceptModal = (data = {}) => {
    setDataForAcceptClient(data);
    setOpenAcceptModal(true);
  };

  const handleCloseAcceptModal = () => {
    setOpenAcceptModal(false);
  };

  const [isSuccessAcceptClient, setIsSuccessAcceptClient] = useState(false);
  const [isErrorAcceptClient, setIsErrorAcceptClient] = useState(false);
  useEffect(() => {
    if (isSuccessAcceptClient) {
      setTimeout(() => {
        setIsSuccessAcceptClient(false);
      }, 3000);
    }
  }, [isSuccessAcceptClient]);

  useEffect(() => {
    if (isErrorAcceptClient) {
      setTimeout(() => {
        setIsErrorAcceptClient(false);
      }, 3000);
    }
  }, [isErrorAcceptClient]);

  const initialValues = {
    requestId: "",
    comment: "",
    status: "",
  };

  const validationSchema = Yup.object({
    comment: Yup.string().required("Enter a comment"),
  });

  const onSubmitForAccept = async (values, { resetForm }) => {
    values.requestId = dataForAcceptClient.requestId;
    values.status = "Accepted";
    let response = await acceptAndRejectClient(values, jwtToken);
    if (response.status === true) {
      setIsSuccessAcceptClient(true);
      resetForm();
      allClientRequests(jwtToken, status, date, pageNo, rowsPerPage);
    }
    if (response.status == false) {
      setIsErrorAcceptClient(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmitForAccept,
    validationSchema: validationSchema,
  });
  //    reject form
  const [openRejectModal, setOpenRejectModal] = useState(false);

  const handleOpenRejectModal = (data = {}) => {
    setDataForAcceptClient(data);
    setOpenRejectModal(true);
  };

  const handleCloseRejectModal = () => {
    setOpenRejectModal(false);
  };
  const [isSuccessRejectedClient, setIsSuccessRejectedClient] = useState(false);
  const [isErrorRejectedClient, setIsErrorRejectedClient] = useState(false);
  useEffect(() => {
    if (isSuccessRejectedClient) {
      setTimeout(() => {
        setIsSuccessRejectedClient(false);
      }, 3000);
    }
  }, [isSuccessRejectedClient]);

  useEffect(() => {
    if (isErrorRejectedClient) {
      setTimeout(() => {
        setIsErrorAcceptClient(false);
      }, 3000);
    }
  }, [isErrorRejectedClient]);

  const [openViewJobDescriptionModal, setOpenViewJobDescriptionModal] =
    useState(false);

  const handleOpenViewJobDescriptionModal = (data = {}) => {
    setDataForAcceptClient(data);
    setOpenViewJobDescriptionModal(true);
  };

  const handleCloseViewJobDescriptionModal = () => {
    setOpenViewJobDescriptionModal(false);
  };

  const [openViewDocumentModal, setOpenViewDocumentModal] = useState(false);

  const handleOpenViewDocumentModal = (Data = {}) => {
    setOpenViewDocumentModal(true);
  };

  const handleCloseViewDocumentModal = () => {
    setOpenViewDocumentModal(false);
  };

  const initialValuesForReject = {
    requestId: "",
    comment: "",
    status: "",
  };

  const validationSchemaForReject = Yup.object({
    comment: Yup.string().required("Enter a comment"),
  });

  const onSubmitForReject = async (values, { resetForm }) => {
    values.requestId = dataForAcceptClient.requestId;
    values.status = "Rejected";
    let response = await acceptAndRejectClient(values, jwtToken);
    if (response.status === true) {
      setIsSuccessRejectedClient(true);
      resetForm();
      allClientRequests(jwtToken, status, date, pageNo, rowsPerPage);
    }
    if (response.status == false) {
      setIsErrorRejectedClient(true);
    }
  };

  const formikForReject = useFormik({
    initialValues: initialValuesForReject,
    onSubmit: onSubmitForReject,
    validationSchema: validationSchemaForReject,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading ? (
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Resource Requests
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDBox
                    style={{ display: "flex" }}
                    flexDirection={{
                      xs: "column",
                      sm: "column",
                      md: "row",
                      lg: "row",
                      xl: "row",
                    }}
                    mt={2}
                    ml={3}
                  >
                    <MDBox
                      style={{ width: "200px" }}
                      marginBottom={{
                        xs: "0px",
                        sm: "0px",
                        md: "0px",
                        lg: "0px",
                        xl: "0px",
                      }}
                    >
                      <Select
                        isClearable={true}
                        className="select-css"
                        placeholder="All Status"
                        options={options}
                        value={statusSelected}
                        onChange={(selectedOption) => {
                          changeStatus(selectedOption);
                        }}
                        autoComplete="off"
                      />{" "}
                    </MDBox>
                    <MDBox
                    style={{ 
                      width:"200px"
                    }}
                      marginLeft={{
                        xs: "0px",
                        sm: "0px",
                        md: "10px",
                        lg: "10px",
                        xl: "10px",
                      }}
                    >
                      <DatePicker
                      id="date"
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Date"
                        maxDate={new Date()}
                        onChange={(date) => setStartDate(date)}
                        isClearable={true}
                        withPortal
                        selectsStart
                        selected={startDate}
                        startDate={startDate}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox pt={1}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={true}
                    canSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    serverSidePagination={true}
                    paginationObj={paginationObj}
                    pageNoValue={pageNo}
                    rowsPerPageValue={rowsPerPage}
                    pageNoProp={importPageNo}
                    rowsPerPageProp={importRowsPerPage}
                  />
                </MDBox>
              </Card>
            </Grid>
            <Grid>
              <Modal
                open={openAcceptModal}
                onClose={handleCloseAcceptModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForAcceptModel}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Comment
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseAcceptModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDBox pt={1} pb={3} px={1}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formik.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography
                          mt={1}
                          variant="h6"
                          fontWeight="medium"
                          flexGrow={1}
                          style={{ fontWeight: "500" }}
                        >
                          Enter Comment
                        </MDTypography>
                        <MDInput
                          type="text"
                          name="comment"
                          multiline
                          rows={2}
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.comment}
                          error={
                            formik.touched.comment &&
                            formik.errors.comment &&
                            true
                          }
                        />
                        {formik.touched.comment && formik.errors.comment && (
                          <TextError msg={formik.errors.comment} />
                        )}
                      </MDBox>
                      <MDBox>
                        <Collapse in={isErrorAcceptClient}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorAcceptClient(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Please check the details. Server error!
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessAcceptClient}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessAcceptClient(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Client accepted successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={2}>
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                          disabled={!formik.isValid}
                        >
                          Accept
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Grid>
            {/*  for reject model  */}

            <Grid>
              <Modal
                open={openRejectModal}
                onClose={handleCloseRejectModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForAcceptModel}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Comment
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseRejectModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDBox pt={1} pb={3} px={1}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForReject.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography
                          mt={1}
                          variant="h6"
                          fontWeight="medium"
                          flexGrow={1}
                          style={{ fontWeight: "500" }}
                        >
                          Enter Comment
                        </MDTypography>
                        <MDInput
                          type="text"
                          name="comment"
                          multiline
                          rows={2}
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForReject.handleBlur}
                          onChange={formikForReject.handleChange}
                          value={formikForReject.values.comment}
                          error={
                            formikForReject.touched.comment &&
                            formikForReject.errors.comment &&
                            true
                          }
                        />
                        {formikForReject.touched.comment &&
                          formikForReject.errors.comment && (
                            <TextError msg={formikForReject.errors.comment} />
                          )}
                      </MDBox>
                      <MDBox>
                        <Collapse in={isErrorRejectedClient}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorRejectedClient(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Please check the details. Server error!
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessRejectedClient}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessRejectedClient(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Client rejected successfully
                          </Alert>
                        </Collapse>
                      </MDBox>

                      <MDBox mt={2}>
                        <MDButton
                          variant="contained"
                          color="error"
                          fullWidth
                          type="submit"
                          disabled={!formikForReject.isValid}
                        >
                          Reject
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Grid>
            <MDBox>
              <Modal
                open={openViewJobDescriptionModal}
                onClose={handleCloseViewJobDescriptionModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForView}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography
                      variant="h4"
                      fontWeight="medium"
                      flexGrow={1}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      Client Details
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseViewJobDescriptionModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDCard>
                    <MDBox mt={2}>
                      <div>
                        <Grid container spacing={1} ml={1}>
                          <Grid item xs={12}>
                            <MDBox display="flex">
                              <MDTypography
                                mr={1}
                                variant="h6"
                                style={{ fontSize: "15px" }}
                                fontWeight="medium"
                              >
                                Start Date :
                              </MDTypography>
                              <MDTypography
                                variant="text"
                                //  fontWeight="medium"
                                style={{ fontSize: "15px" }}
                              >
                                {dataForAcceptClient.startDate
                                  ? moment(dataForAcceptClient.startDate)
                                      .format("DD/MM/YYYY")
                                      .slice(0, 10)
                                  : ""}{" "}
                              </MDTypography>
                            </MDBox>
                          </Grid>

                          <Grid item xs={12}>
                            <MDBox display="flex">
                              <MDTypography
                                style={{ fontSize: "15px" }}
                                mr={1}
                                variant="h6"
                                fontWeight="medium"
                              >
                                Job Type :
                              </MDTypography>
                              <MDTypography
                                variant="text"
                                style={{ fontSize: "15px" }}
                              >
                                {dataForAcceptClient.jobType}
                              </MDTypography>
                            </MDBox>
                          </Grid>

                          {dataForAcceptClient.experience ? (
                            <>
                              <Grid item xs={12}>
                                <MDBox display="flex">
                                  <MDTypography
                                    mr={1}
                                    fontWeight="medium"
                                    variant="h6"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Experience :
                                  </MDTypography>
                                  <MDTypography
                                    variant="text"
                                    style={{ fontSize: "15px" }}
                                  >
                                    {dataForAcceptClient.experience +
                                      " " +
                                      "Year(s)"}
                                  </MDTypography>
                                </MDBox>
                              </Grid>
                            </>
                          ) : null}

                          {dataForAcceptClient.location ? (
                            <>
                              <Grid item xs={12}>
                                <MDBox display="flex">
                                  <MDTypography
                                    mr={1}
                                    fontWeight="medium"
                                    variant="h6"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Location :
                                  </MDTypography>
                                  <MDTypography
                                    variant="text"
                                    style={{ fontSize: "15px" }}
                                  >
                                    {dataForAcceptClient.location}
                                  </MDTypography>
                                </MDBox>
                              </Grid>
                            </>
                          ) : null}
                          {dataForAcceptClient.comment ? (
                            <>
                              <Grid item xs={12}>
                                <MDBox display="flex">
                                  <MDTypography
                                    mr={1}
                                    fontWeight="medium"
                                    variant="h6"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Comment :
                                  </MDTypography>
                                  <MDTypography
                                    variant="text"
                                    style={{ fontSize: "15px" }}
                                  >
                                    {dataForAcceptClient.comment}
                                  </MDTypography>
                                </MDBox>
                              </Grid>
                            </>
                          ) : null}
                          <Grid item xs={12}>
                            <MDBox display="flex">
                              <MDTypography
                                mr={1}
                                variant="h6"
                                style={{ fontSize: "15px" }}
                                fontWeight="medium"
                              >
                                Contract Period :
                              </MDTypography>
                              <MDTypography
                                variant="text"
                                style={{ fontSize: "15px" }}
                              >
                                {dataForAcceptClient.contractPeriod}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          <Grid item xs={12}>
                            <MDBox mb={3}>
                              <MDTypography
                                mr={1}
                                variant="h6"
                                fontWeight="medium"
                                style={{ fontSize: "15px" }}
                              >
                                Job Description :
                              </MDTypography>
                              <MDBox style={{ marginRight: "15px" }}>
                                <MDInput
                                  multiline
                                  inputProps={{ readOnly: true }}
                                  rows={6}
                                  fullWidth
                                  value={dataForAcceptClient.jobDescription}
                                />
                              </MDBox>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </div>
                    </MDBox>
                  </MDCard>
                </MDCard>
              </Modal>
              <MDBox>
                <Modal
                  open={openViewDocumentModal}
                  onClose={handleCloseViewDocumentModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForDocument}>
                    <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h5"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        View Document
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseViewDocumentModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>

                    <hr />

                    <MDBox pt={1} pb={3} px={1} height="100%">
                      <iframe
                        style={{
                          height: "100%",
                          width: "100%",
                          overflow: "auto",
                        }}
                        src={document}
                      ></iframe>
                    </MDBox>
                  </MDCard>
                </Modal>
              </MDBox>
              <MDBox></MDBox>
            </MDBox>
          </Grid>
        </MDBox>
      ) : (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!loading ? <p className=" font-size text-center text-md-left">
          Developed and maintained by :
          <a className="link" href="http://mobillor.com/">
            <strong style={{ color: "red" }}>
              {" "}
              Mobillor Technologies Pvt. Ltd.
            </strong>
          </a>
        </p> : null}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    allClientRequestsReducer: state.allClientRequestsReducer,
    allAccountManagerReducer: state.allAccountManagerReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    allClientRequests: (jwtToken, status, date, pageNo, rowsPerPage) =>
      dispatch(allClientRequests(jwtToken, status, date, pageNo, rowsPerPage)),
    getAllAccountManager: (jwtToken) =>
      dispatch(getAllAccountManager(jwtToken)),
    acceptAndRejectClient: (payload, jwtToken) =>
      dispatch(acceptAndRejectClient(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(ClientRequest);
