/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export { login } from "./authentication/login/loginActions";
export { resetPassword } from "./authentication/reset-password/resetPasswordActions";
export {
  setPassword,
  setPasswordForNewUser,
  setNewPassword,
} from "./authentication/set-password/setPasswordActions";
export {
  forgotPassword,
  validateOtpForForgotPassword,
} from "./authentication/forgot-password/forgotPasswordActions";
export { getProfile } from "./profile/profileActions";
export { validateOtp, getOtp, clientRegister } from "./otp/otpActions";

export {
  createClientDetails,
  getPreview,
  getClientRequestForClient,
  getListOfProfiles,
  getListOfShortlistedProfiles,
} from "./client-details/clientDetailsActions";

export {
  allClientRequests,
  acceptAndRejectClient,
  createClientRequests,
  getAllUnassignedClients,
  assignClient,
  getClientsByClientId,
  getAllClient,
  deleteClient,
  getClientById,
  blockClient,
} from "./client-request/clientRequestActions";

export {
  getAllAccountManager,
  createAccountManagers,
  deleteAccountManager,
  editAccountManager,
  getAccountManager,
} from "./account-manager/accountManagerActions";

export {
  getAssignClient,
  createPofile,
  getProfiles,
  assignProfiles,
} from "./assign-client/assignClientActions";

export {
  getClientRequestForManager,
  getClientForReAssign,
  transferClientForReAssign,
  removeClientForReAssign,
} from "./client-request-for-manager/clientRequestForManagerActions";

export { getAllReference } from "./reference/referenceActions";

export {
  getAllProfile,
  getUploadProfile,
  deleteUploadProfile,
  editUploadProfile,
  getProfileByClientId,
  acceptOrRejectProfile,
  sortListProfile,
  profilesNotification,
} from "./upload-profile/uploadProfileActions";

export{
   getNotificationForRegisteredClient

} from "./notification-for-registered-client/notificationForRegisteredClientActions"