/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  ALL_CLIENT_REQUESTS_REQUEST,
  ALL_CLIENT_REQUESTS_SUCCESS,
  ALL_CLIENT_REQUESTS_FAILURE,
  GET_ALL_UNASSIGNED_CLIENTS_REQUEST,
  GET_ALL_UNASSIGNED_CLIENTS_SUCCESS,
  GET_ALL_UNASSIGNED_CLIENTS_FAILURE,
  GET_CLIENTS_BY_CLIENTID_REQUEST,
  GET_CLIENTS_BY_CLIENTID_SUCCESS,
  GET_CLIENTS_BY_CLIENTID_FAILURE,
  GET_ALL_CLIENT_FAILURE,
  GET_ALL_CLIENT_REQUEST,
  GET_ALL_CLIENT_SUCCESS,
  GET_CLIENT_BY_ID_FAILURE,
  GET_CLIENT_BY_ID_SUCCESS,
  GET_CLIENT_BY_ID_REQUEST,
} from "./clientRequestTypes";

const initialState = {
  loading: false,
  clientsData: {},
  error: "",
};

const allClientRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_CLIENT_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_CLIENT_REQUESTS_SUCCESS:
      return {
        loading: false,
        clientsData: action.payload,
        error: "",
      };
    case ALL_CLIENT_REQUESTS_FAILURE:
      return {
        loading: false,
        clientsData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForSingleClient = {
  loading: false,
  clientsData: {},
  error: "",
};

const getClientByIdReducer = (state = initialStateForSingleClient, action) => {
  switch (action.type) {
    case GET_CLIENT_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENT_BY_ID_SUCCESS:
      return {
        loading: false,
        clientsData: action.payload,
        error: "",
      };
    case GET_CLIENT_BY_ID_FAILURE:
      return {
        loading: false,
        clientsData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForClients = {
  loading: false,
  clientsData: {},
  error: "",
};

const getAllClientsReducer = (state = initialStateForClients, action) => {
  switch (action.type) {
    case GET_ALL_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CLIENT_SUCCESS:
      return {
        loading: false,
        clientsData: action.payload,
        error: "",
      };
    case GET_ALL_CLIENT_FAILURE:
      return {
        loading: false,
        clientsData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForClientData = {
  loading: false,
  clientsData: {},
  error: "",
};

const getClientsByClientIdReducer = (
  state = initialStateForClientData,
  action
) => {
  switch (action.type) {
    case GET_CLIENTS_BY_CLIENTID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENTS_BY_CLIENTID_SUCCESS:
      return {
        loading: false,
        clientsData: action.payload,
        error: "",
      };
    case GET_CLIENTS_BY_CLIENTID_FAILURE:
      return {
        loading: false,
        clientsData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForClient = {
  loading: false,
  clientsData: {},
  error: "",
};

const getAllUnassignedClientsReducer = (
  state = initialStateForClient,
  action
) => {
  switch (action.type) {
    case GET_ALL_UNASSIGNED_CLIENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_UNASSIGNED_CLIENTS_SUCCESS:
      return {
        loading: false,
        clientsData: action.payload,
        error: "",
      };
    case GET_ALL_UNASSIGNED_CLIENTS_FAILURE:
      return {
        loading: false,
        clientsData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getAllUnassignedClientsReducer,
  allClientRequestsReducer,
  getClientsByClientIdReducer,
  getAllClientsReducer,
  getClientByIdReducer,
};
