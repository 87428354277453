import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Formik & Yup
import { Formik, Form } from "formik";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// @mui material components
import { Card, CardContent, Grid } from "@mui/material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "../../components/MDInput/index";

// Dashboard layout components
import BasicLayout from "layouts/authentication/components/BasicLayoutForForm";

// Functions from store
import { createClientDetails } from "../../store";

// Router Components
import { useNavigate } from "react-router-dom";

// Cookies
import Cookies from "universal-cookie";

const cookies = new Cookies();

const FormPreview = ({ createClientDetails, getPreviewReducer }) => {
  const cookies = new Cookies();
  let data = cookies.get("mobillor-talent-accelerator-user-data");
  let jwtToken = data?.data?.jwtToken;

  const [open, setOpen] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [msg, setMsg] = useState("");

  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] =
    useState("");

  const [clientData, setclientData] = useState({});

  const navigate = useNavigate();

  const clickHandler = () => {
    navigate(-1);
  };

  const handleChange = (id) => () => {
    setExpanded((pre) => {
      return {
        ...pre,
        [id]: !expanded[id],
      };
    });
  };

  const [expanded, setExpanded] = React.useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
  });
  useEffect(() => {
    let formdata = getPreviewReducer.preview ? getPreviewReducer.preview : {};
    setclientData(formdata);
  }, [getPreviewReducer]);

  const submitHandler = async (values) => {
    let response = await createClientDetails(values, jwtToken);
    if (response.status) {
      setFormSubmitted(true);
      setOpen(true);
      setMsg("Form is submitting... Please wait");
      setTimeout(() => {
        modalCloseHandler();
      }, 7000);
      setTimeout(() => {
        setMsg("Thanks for updating your Company Details. You are all set to share your Talent requests.");
      }, 5000);
      cookies.set("isFormFilled", "true");
    }
  };

  const modalCloseHandler = () => {
    setOpen(false);
    setFormSubmittedSuccessfully("Thanks for updating your Company Details. You are all set to share your Talent requests");
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Card>
          {!formSubmitted && (
            <CardContent align="center">
              <Formik
                initialValues={getPreviewReducer?.preview}
                onSubmit={submitHandler}
              >
                {(formik) => (
                  <Form>
                    <div>
                      <MDBox mt={2}>
                        <Container
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: "center",
                          }}
                        >
                          <MDTypography
                            variant="h3"
                            gutterBottom
                            textAlign="center"
                            width="100%"
                          >
                            Review the details before submitting the form
                          </MDTypography>
                        </Container>
                      </MDBox>
                      <Accordion
                        style={{ boxShadow: "none", width: "100%" }}
                        expanded={expanded.panel1 === true}
                        onChange={handleChange("panel1")}
                      >
                        <AccordionSummary
                          style={{
                            backgroundColor: "#2196f3",
                            borderBottom: "2px solid white",

                            width: "100%",

                            maxHeight: "50px",
                            minHeight: "50px",
                          }}
                          expandIcon={
                            <ExpandMoreIcon style={{ color: "white" }} />
                          }
                          id="panel1a-header"
                        >
                          <MDTypography
                            style={{ color: "white", margin: "0px" }}
                            mt={5}
                            variant="h5"
                            fontWeight="bold"
                          >
                            Business Partner Details
                          </MDTypography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Card style={{ padding: "15px" }}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item xl={3} lg={3} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Line of Business
                                  </MDTypography>
                                  <MDInput
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={clientData.lineOfBusiness}
                                  />
                                </MDBox>
                              </Grid>

                              <Grid item xl={3} lg={3} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Currency
                                  </MDTypography>
                                  <MDInput
                                    fullWidth
                                    inputProps={{ readOnly: true }}
                                    value={
                                      clientData.currency
                                        ? clientData.currency
                                        : "INR"
                                    }
                                  />
                                </MDBox>
                              </Grid>

                              <Grid item xl={3} lg={3} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Company Name
                                  </MDTypography>
                                  <MDInput
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={clientData.companyName}
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Building Number/Name
                                  </MDTypography>
                                  <MDInput
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={clientData.buildingNoName}
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Floor
                                  </MDTypography>
                                  <MDInput
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={clientData.floor}
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Street
                                  </MDTypography>
                                  <MDInput
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={clientData.street}
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Country
                                  </MDTypography>
                                  <MDInput
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={clientData.country}
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    State
                                  </MDTypography>
                                  <MDInput
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={clientData.state}
                                  />
                                </MDBox>
                              </Grid>

                              <Grid item xl={3} lg={3} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    City
                                  </MDTypography>
                                  <MDInput
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={clientData.city}
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Pincode
                                  </MDTypography>
                                  <MDInput
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={clientData.pinCode}
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Contact Person
                                  </MDTypography>
                                  <MDInput
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={clientData.contactPerson}
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Contact No
                                  </MDTypography>{" "}
                                  <MDInput
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={
                                      clientData.countryCode +
                                      "-" +
                                      clientData.contactNo
                                    }
                                  />
                                </MDBox>
                              </Grid>

                              <Grid item xl={3} lg={3} md={6} xs={12}>
                                <MDBox mb={2}>
                                  <MDTypography
                                    variant="h6"
                                    style={{ fontWeight: "500" }}
                                  >
                                    Email
                                  </MDTypography>
                                  <MDInput
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={clientData.email}
                                  />
                                </MDBox>
                              </Grid>

                              {clientData.industrialType &&
                                clientData.industrialType?.length > 0 ? (
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Industrial Type
                                    </MDTypography>
                                    <MDInput
                                      inputProps={{ readOnly: true }}
                                      fullWidth
                                      value={clientData.industrialType}
                                    />
                                  </MDBox>
                                </Grid>
                              ) : null}
                            </Grid>
                          </Card>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        style={{ boxShadow: "none", width: "100%" }}
                        expanded={expanded.panel2 === true}
                        onChange={handleChange("panel2")}
                      >
                        <AccordionSummary
                          style={{
                            backgroundColor: "#2196f3",
                            borderBottom: "2px solid white",
                            width: "100%",

                            maxHeight: "50px",
                            minHeight: "50px",
                          }}
                          expandIcon={
                            <ExpandMoreIcon style={{ color: "white" }} />
                          }
                          id="panel1a-header"
                        >
                          <MDTypography
                            style={{ color: "white", margin: "0px" }}
                            // mt={5}
                            variant="h5"
                            fontWeight="medium"
                            color="inherit"
                          >
                            Statutory Information
                          </MDTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Card style={{ padding: "15px" }}>
                            <Grid container spacing={1} alignItems="center">
                              {clientData.pan && (
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      PAN/TAX Number
                                    </MDTypography>
                                    <MDInput
                                      inputProps={{ readOnly: true }}
                                      fullWidth
                                      value={clientData.pan}
                                    />
                                  </MDBox>
                                </Grid>
                              )}

                              {clientData.gstNo && (
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      GST Number
                                    </MDTypography>
                                    <MDInput
                                      inputProps={{ readOnly: true }}
                                      fullWidth
                                      value={clientData.gstNo}
                                    />
                                  </MDBox>
                                </Grid>
                              )}

                              {clientData.paymentTerms && (
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Payment Terms
                                    </MDTypography>
                                    <MDInput
                                      inputProps={{ readOnly: true }}
                                      fullWidth
                                      value={clientData.paymentTerms}
                                    />
                                  </MDBox>
                                </Grid>
                              )}

                              {clientData.cinNumber && (
                                <Grid item xl={3} lg={3} md={6} xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography
                                      variant="h6"
                                      style={{ fontWeight: "500" }}
                                    >
                                      Company Registration Number
                                    </MDTypography>
                                    <MDInput
                                      inputProps={{ readOnly: true }}
                                      fullWidth
                                      value={clientData.cinNumber}
                                    />
                                  </MDBox>
                                </Grid>
                              )}
                            </Grid>
                          </Card>
                        </AccordionDetails>
                      </Accordion>

                      <MDButton
                        type="button"
                        color="info"
                        variant="contained"
                        onClick={clickHandler}
                        style={{ marginTop: "50px" }}
                      >
                        Edit
                      </MDButton>
                      <MDButton
                        style={{ marginLeft: "15px", marginTop: "50px" }}
                        type="submit"
                        color="success"
                        variant="contained"
                      >
                        Submit
                      </MDButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </CardContent>
          )}

          {formSubmitted && (
            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogContent>
                <DialogTitle
                  style={{
                    padding: "5px",
                    width: "fit-content",
                    fontSize: 20,
                    textAlign: "center",
                    // marginTop: "10px",
                  }}
                >
                  {msg}
                </DialogTitle>
              </DialogContent>
              <DialogActions style={{ marginTop: "-18px" }}>
                <MDButton
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={modalCloseHandler}
                >
                  Close
                </MDButton>
              </DialogActions>
            </Dialog>
          )}
        </Card>
        {formSubmitted && (
          <Container
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              background: "transparent",
              width: "100px",
              height: "100px",
              marginTop: "200px",
              borderRadius: "10px",
            }}
          >
            <MDTypography
              variant="h1"
              gutterBottom
              color="black"
              textAlign="center"
              alignItems="center"
              width="100%"
            >
              {formSubmittedSuccessfully}
            </MDTypography>
          </Container>
        )}

<p className=" font-size text-center text-md-left">
          Developed and maintained by :
          <a className="link" href="http://mobillor.com/">
            <strong style={{ color: "red" }}>
              {" "}
              Mobillor Technologies Pvt. Ltd.
            </strong>
          </a>
        </p>
      </DashboardLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    clientDetailsReducer: state.clientDetailsReducer,
    getPreviewReducer: state.getPreviewReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createClientDetails: (payload, token) =>
      dispatch(createClientDetails(payload, token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FormPreview);
