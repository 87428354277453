/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE,
  SET_PASSWORD_FOR_NEW_USER_REQUEST,
  SET_PASSWORD_FOR_NEW_USER_SUCCESS,
  SET_PASSWORD_FOR_NEW_USER_FAILURE,
  SET_NEWPASSWORD_REQUEST,
  SET_NEWPASSWORD_SUCCESS,
  SET_NEWPASSWORD_FAILURE,
} from "./setPasswordTypes";

import Cookies from "universal-cookie";


import axios from "axios";
import Config from "../../../config/index";
let auth3Url = Config.auth3Url;
let baseUrl = Config.baseUrl;

const cookies = new Cookies();



const setPasswordRequest = () => {
  return {
    type: SET_PASSWORD_REQUEST,
  };
};

const setPasswordSuccess = (payload) => {
  return {
    type: SET_PASSWORD_SUCCESS,
    payload: payload,
  };
};

const setPasswordFaliure = (error) => {
  return {
    type: SET_PASSWORD_FAILURE,
    payload: error,
  };
};

const setPassword = (setPasswordState) => {
  return (dispatch) => {
    let payload = {
      email: setPasswordState.email,
      newPassword: setPasswordState.newPassword,
    };
    dispatch(setPasswordRequest());
    let url = `${baseUrl}/users/reset_forgot_password`;
    return axios
      .put(url, payload)
      .then((response) => {
        dispatch(setPasswordSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(setPasswordFaliure(err.message));
        return { status: false };
      });
  };
};

const setPasswordForNewUserRequest = () => {
  return {
    type: SET_PASSWORD_FOR_NEW_USER_REQUEST,
  };
};

const setPasswordForNewUserSuccess = (payload) => {
  return {
    type: SET_PASSWORD_FOR_NEW_USER_SUCCESS,
    payload: payload,
  };
};

const setPasswordForNewUserFaliure = (error) => {
  return {
    type: SET_PASSWORD_FOR_NEW_USER_FAILURE,
    payload: error,
  };
};

const setPasswordForNewUser = (setPasswordState) => {
  return (dispatch) => {
    let payload = {
      email: setPasswordState.email,
      password: setPasswordState.newPassword,
    };
    dispatch(setPasswordForNewUserRequest());
    let url = `${baseUrl}/users/reset_forgot_password`;
    return axios
      .put(url, payload)
      .then((response) => {
        dispatch(setPasswordForNewUserSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(setPasswordForNewUserFaliure(err.message));
        return { status: false };
      });
  };
};

const setNewPasswordRequest = () => {
  return {
    type: SET_NEWPASSWORD_REQUEST,
  };
};

const setNewPasswordSuccess = (payload) => {
  return {
    type: SET_NEWPASSWORD_SUCCESS,
    payload: payload,
  };
};

const setNewPasswordFaliure = (error) => {
  return {
    type: SET_NEWPASSWORD_FAILURE,
    payload: error,
  };
};

const setNewPassword = (setNewPasswordState) => {
  return (dispatch) => {
    let payload = {
      email: setNewPasswordState.email,
      // otp: setNewPasswordState.otp,
      // hash: setNewPasswordState.hash,
      password: setNewPasswordState.newPassword,
      // confirmPassword: setNewPasswordState.confirmPassword,
    };
    dispatch(setNewPasswordRequest());
    let url = `${baseUrl}/users/reset_forgot_password`;
    return axios
      .put(url , payload)
      .then((response) => {
        dispatch(setNewPasswordSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(setNewPasswordFaliure(err.message));
        return { status: false };
      });
  };
};

export { setPassword, setPasswordForNewUser, setNewPassword };
