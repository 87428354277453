/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export const GET_UPLOAD_PROFILE_REQUEST = "GET_UPLOAD_PROFILE_REQUEST";
export const GET_UPLOAD_PROFILE_SUCCESS = "GET_UPLOAD_PROFILE_SUCCESS";
export const GET_UPLOAD_PROFILE_FAILURE = "GET_UPLOAD_PROFILE_FAILURE";

export const GET_ALL_PROFILE_REQUEST = "GET_ALL_PROFILE_REQUEST";
export const GET_ALL_PROFILE_SUCCESS = "GET_ALL_PROFILE_SUCCESS";
export const GET_ALL_PROFILE_FAILURE = "GET_ALL_PROFILE_FAILURE";

export const DELETE_UPLOAD_PROFILE_REQUEST = "DELETE_UPLOAD_PROFILE_REQUEST";
export const DELETE_UPLOAD_PROFILE_SUCCESS = "DELETE_UPLOAD_PROFILE_SUCCESS";
export const DELETE_UPLOAD_PROFILE_FAILURE = "DELETE_UPLOAD_PROFILE_FAILURE";

export const EDIT_UPLOAD_PROFILE_REQUEST = "EDIT_UPLOAD_PROFILE_REQUEST";
export const EDIT_UPLOAD_PROFILE_SUCCESS = "EDIT_UPLOAD_PROFILE_SUCCESS";
export const EDIT_UPLOAD_PROFILE_FAILURE = "EDIT_UPLOAD_PROFILE_FAILURE";

export const GET_PROFILE_BY_CLIENTID_REQUEST =
  "GET_PROFILE_BY_CLIENTID_REQUEST";
export const GET_PROFILE_BY_CLIENTID_SUCCESS =
  "GET_PROFILE_BY_CLIENTID_SUCCESS";
export const GET_PROFILE_BY_CLIENTID_FAILURE =
  "GET_PROFILE_BY_CLIENTID_FAILURE";

export const ACCEPT_OR_REJECT_PROFILE_REQUEST =
  "ACCEPT_OR_REJECT_PROFILE_REQUEST";
export const ACCEPT_OR_REJECT_PROFILE_SUCCESS =
  "ACCEPT_OR_REJECT_PROFILE_SUCCESS";
export const ACCEPT_OR_REJECT_PROFILE_FAILURE =
  "ACCEPT_OR_REJECT_PROFILE_FAILURE";

export const SORT_LIST_PROFILE_REQUEST = "SORT_LIST_PROFILE_REQUEST";
export const SORT_LIST_PROFILE_SUCCESS = "SORT_LIST_PROFILE_SUCCESS";
export const SORT_LIST_PROFILE_FAILURE = "SORT_LIST_PROFILE_FAILURE";

export const PROFILES_NOTIFICATION_REQUEST = "PROFILES_NOTIFICATION_REQUEST";
export const PROFILES_NOTIFICATION_SUCCESS = "PROFILES_NOTIFICATION_SUCCESS";
export const PROFILES_NOTIFICATION_FAILURE = "PROFILES_NOTIFICATION_FAILURE";
