/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
    GET_NOTIFICATION_FOR_CLIENT_REQUEST,
    GET_NOTIFICATION_FOR_CLIENT_SUCCESS,
    GET_NOTIFICATION_FOR_CLIENT_FAILURE,
  } from "./notificationForRegisteredClientTypes";
  
  
  const initialStateForNotification = {
    loading: false,
    notification: {},
    error: "",
  };
  
  const notificationForRegisteredClientReducer = (state = initialStateForNotification , action) => {
    switch (action.type) {
      case GET_NOTIFICATION_FOR_CLIENT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_NOTIFICATION_FOR_CLIENT_SUCCESS:
        return {
          loading: false,
          notification : action.payload,
          error: "",
        };
      case GET_NOTIFICATION_FOR_CLIENT_FAILURE:
        return {
          loading: false,
          notification : {},
          error: action.payload,
        }; 
      default:
        return state;
    } 
  };
   
  export { notificationForRegisteredClientReducer };
  