/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// import Checkboxs from "./Checkboxs";
import Checkbox from "./CheckBox";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import TextError from "utils/TextError";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

//  formik and yup
import { useFormik } from "formik";
import * as Yup from "yup";

// react-select and css
import Select from "react-select";
import "../../index.css";

// @mui material icons
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

// Functions from store
import {
  getAllAccountManager,
  createAccountManagers,
  deleteAccountManager,
  getAllUnassignedClients,
  assignClient,
  getClientForReAssign,
  removeClientForReAssign,
  transferClientForReAssign,
  editAccountManager,
  getAccountManager,
  getNotificationForRegisteredClient
} from "../../store";

// import cookies
import Cookies from "universal-cookie";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AccountManager = ({
  getAllAccountManager,
  getNotificationForRegisteredClient , 
  allAccountManagerReducer,
  accountManagerReducer,
  createAccountManagers,
  deleteAccountManager,
  getAllUnassignedClients,
  getAllUnassignedClientsReducer,
  assignClient,
  getClientForReAssign,
  getClientForReAssignReducer,
  removeClientForReAssign,
  editAccountManager,
  getAccountManager,
}) => {
  const cookies = new Cookies();
  let data = cookies.get("mobillor-talent-accelerator-user-data");
  let jwtToken = data?.data?.jwtToken;
  const styleForEdit = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 500, lg: 500, xl: 500 },
    boxShadow: 24,
    p: 1,
    pt: 2,
  };

  const styleForAssign = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    boxShadow: 24,
    p: 1,
    pt: 2,
  };

  const deleteStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 330, lg: 330, xl: 330 },
    boxShadow: 24,
    p: 2,
    pt: 3,
  };

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearch(globalFilter);
  };

  useEffect(() => {
    getAllAccountManager(jwtToken, pageNo, rowsPerPage);
  }, [getAllAccountManager, pageNo, rowsPerPage]);
  useEffect(() => {
    getNotificationForRegisteredClient(jwtToken);
  }, [getNotificationForRegisteredClient]);
  useEffect(() => {
    getAccountManager(jwtToken);
  }, [getAccountManager]);
  useEffect(() => {
    getAllUnassignedClients(jwtToken);
  }, [getAllUnassignedClients]);

  const [state, setState] = useState({
    columns: [
      { Header: "Email", accessor: "email" },
      { Header: "Name", accessor: "name" },
      { Header: "Manage  Accounts", accessor: "action", align: "center" },
    ],
    rows: [],
  });

  const { columns, rows } = state;
  const [loadingForReassign, setLoadingForReassign] = useState(false);
  const [paginationObj, setPaginationObj] = useState({});
  let loading = allAccountManagerReducer.loading;
  useEffect(() => {
    let accountManager = [];
    let data = allAccountManagerReducer?.accountManagerData?.data
      ? allAccountManagerReducer?.accountManagerData?.data
      : [];

    if (allAccountManagerReducer?.accountManagerData?.data?.length) {
      setPaginationObj(
        allAccountManagerReducer?.accountManagerData?.pagination
      );
      data?.map((data) => {
        const singledata = {
          email: data.email,
          name: data.name,
          action: (
            <>
              <span style={{ display: "flex" }}>
                <Tooltip title="Assign Client">
                  <MDButton
                    ml={5}
                    variant="gradient"
                    color="info"
                    iconOnly
                    type="button"
                    onClick={() => {
                      handleOpenAssignModal(data);
                    }}
                  >
                    {" "}
                    <Icon> add_icon</Icon>
                  </MDButton>
                </Tooltip>

                <Tooltip title="Edit/Re-Assign Client">
                  <MDButton
                    style={{ marginLeft: "10px" }}
                    variant="gradient"
                    color="success"
                    iconOnly
                    type="button"
                    onClick={() => {
                      handleOpenReAssignModal(data);
                    }}
                  >
                    {" "}
                    <Icon> edit_icon</Icon>
                  </MDButton>
                </Tooltip>

                <Tooltip title="Delete">
                  <MDButton
                    style={{ marginLeft: "10px" }}
                    variant="gradient"
                    color="error"
                    iconOnly
                    type="button"
                    onClick={() => handleOpenDeleteAccountManagerModal(data)}
                  >
                    <DeleteIcon />
                  </MDButton>
                </Tooltip>
              </span>
            </>
          ),
        };
        accountManager.push(singledata);
      });
    }
    setState({ ...state, rows: accountManager });
  }, [allAccountManagerReducer]);

  const [openAccountManagerModal, setOpenAccountManagerModal] = useState(false);
  const [dataForClient, setDataForClient] = useState({});

  const handleOpenAccountManagerModal = () => {
    setOpenAccountManagerModal(true);
  };

  const handleCloseAccountManagerModal = () => {
    setOpenAccountManagerModal(false);
  };

  const [isSuccessAccountManager, setIsSuccessAccountManager] = useState(false);
  const [isErrorAccountManager, setIsErrorAccountManager] = useState(false);

  useEffect(() => {
    if (isSuccessAccountManager) {
      setTimeout(() => {
        setIsSuccessAccountManager(false);
      }, 3000);
    }
  }, [isSuccessAccountManager]);

  useEffect(() => {
    if (isErrorAccountManager) {
      setTimeout(() => {
        setIsErrorAccountManager(false);
      }, 3000);
    }
  }, [isErrorAccountManager]);

  const initialValues = {
    name: "",
    email: "",
  };

  const emailRegex = /^[A-Za-z0-9._%+-]+@mobillor\.(com|net)$/
  const validationSchema = Yup.object({
    name: Yup.string().required("Enter the name"),
    email: Yup.string()
      .email("Invalid email format") 
      .required("Enter the email").matches(emailRegex, 'Email domain should be mobillor.net/com'),
  });

  const onSubmit = async (values, { resetForm }) => {
    let response = await createAccountManagers(values, jwtToken);
    if (response.status === true) {
      setIsSuccessAccountManager(true);
      getAllAccountManager(jwtToken, pageNo, rowsPerPage);
      getAccountManager(jwtToken);
      getAllUnassignedClients(jwtToken);
      getClientForReAssign(jwtToken, email);
      resetForm();
    }
    if (response.status == false) {
      setIsErrorAccountManager(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
  });

  const [openDeleteAccountManagerModal, setOpenDeleteAccountManagerModal] =
    useState(false);
  const [singleAccountManagerDataForDelete, setAccountManagerDataForDelete] =
    useState({});

  const handleOpenDeleteAccountManagerModal = (data = {}) => {
    setAccountManagerDataForDelete(data);
    setOpenDeleteAccountManagerModal(true);
  };
  const handleCloseDeleteAccountManagerModal = () => {
    setOpenDeleteAccountManagerModal(false);
  };
  const [isErrorDeleteAccountManager, setIsErrorDeleteAccountManager] =
    useState(false);

  useEffect(() => {
    if (isErrorDeleteAccountManager) {
      setTimeout(() => {
        setIsErrorDeleteAccountManager(false);
      }, 3000);
    }
  }, [isErrorDeleteAccountManager]);

  const deleteAccountManagerData = async () => {
    let accountManagerId = singleAccountManagerDataForDelete.accountManagerId;
    let response = await deleteAccountManager(accountManagerId, jwtToken);
    if (response.status) {
      getAllAccountManager(jwtToken, pageNo, rowsPerPage);
      getAccountManager(jwtToken);
      handleCloseDeleteAccountManagerModal();
    }
    if (!response.status) {
      setIsErrorDeleteAccountManager(true);
    }
  };

  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [dataForAssignClient, setDataForAssignClient] = useState({});

  const handleOpenAssignModal = (data = {}) => {
    setDataForClient(data);
    setDataForAssignClient(data);
    setOpenAssignModal(true);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
  };

  const [isSuccessAssignClient, setIsSuccessAssignClient] = useState(false);
  const [isErrorAssignClient, setIsErrorAssignClient] = useState(false);
  useEffect(() => {
    if (isSuccessAssignClient) {
      setTimeout(() => {
        setIsSuccessAssignClient(false);
      }, 3000);
    }
  }, [isSuccessAssignClient]);

  useEffect(() => {
    if (isErrorAssignClient) {
      setTimeout(() => {
        setIsErrorAssignClient(false);
      }, 3000);
    }
  }, [isErrorAssignClient]);

  const [accountManagerOptions, setaccountManagerOptions] = useState([]);

  useEffect(() => {
    let accountManagerDataTemp = [];
    let accountManagerData = getAllUnassignedClientsReducer?.clientsData
      ? getAllUnassignedClientsReducer?.clientsData
      : [];

    if (getAllUnassignedClientsReducer?.clientsData.length) {
      accountManagerData?.map((account) => {
        accountManagerDataTemp.push({
          label: account.companyName, 
          value: account.clientId,
        });
      }); 
    }

    setaccountManagerOptions(accountManagerDataTemp); 
  }, [getAllUnassignedClientsReducer]);

  const [accountManagerSelected, setaccountManagerSelected] = useState("");
  const handleaccountManagerSelected = (selectedOption) => {
    setaccountManagerSelected(selectedOption);
  };

  const [clientOptions, setClientOptions] = useState([]);

  let loadingForReassignClient = getClientForReAssignReducer.loading;
  useEffect(() => {
    let accountManagerDataTemp = [];
    let accountManagerData = getClientForReAssignReducer?.clientsData
      ? getClientForReAssignReducer?.clientsData
      : [];
    if (getClientForReAssignReducer?.clientsData.length) {
      accountManagerData?.map((account) => {
        accountManagerDataTemp.push({
          name: account.companyName,
          id: account.clientId,
        });
      });
    }
    setClientOptions(accountManagerDataTemp);
  }, [getClientForReAssignReducer]);

  const [accountManagerSelectedForEdit, setAccountManagerSelectedForEdit] =
    useState("");
  const handleaccountManagerSelectedForEdit = (selectedOption) => {
    setAccountManagerSelectedForEdit(selectedOption);
  };

  const [clientOptionsForEdit, setClientOptionsForEdit] = useState([]);
  useEffect(() => {
    let accountManagerDataTemp = [];
    let accountManagerData = accountManagerReducer?.accountManagerData
      ? accountManagerReducer?.accountManagerData
      : [];

    const filtered =
      accountManagerData && Array.isArray(accountManagerData)
        ? accountManagerData?.filter((employee) => {
            return (
              employee.accountManagerId !=
              dataForReAssignClient?.accountManagerId
            );
          })
        : [];

    if (accountManagerReducer?.accountManagerData.length) {
      filtered?.map((account) => {
        accountManagerDataTemp.push({
          label: account.name,
          value: account.accountManagerId,
        });
      });
    }
    setClientOptionsForEdit(accountManagerDataTemp);
  }, [getClientForReAssignReducer]);

  const initialValuesForAssign = {
    clientId: [],
  };

  const validationSchemaForAssign = Yup.object({});

  const onSubmitForAssign = async (values, { resetForm }) => {
    values.accountManagerId = dataForClient.accountManagerId;
    values.clientId = accountManagerSelected;
    let response = await assignClient(values, jwtToken);
    if (response.status === true) {
      setIsSuccessAssignClient(true);
      setaccountManagerSelected([]);
      resetForm();
      getAllAccountManager(jwtToken, pageNo, rowsPerPage);
      getAccountManager(jwtToken);
      getAllUnassignedClients(jwtToken);
      getNotificationForRegisteredClient(jwtToken); 
    }
    if (response.status == false) {
      setIsErrorAssignClient(true);
    }
  };

  const formikForAssign = useFormik({
    initialValues: initialValuesForAssign,
    onSubmit: onSubmitForAssign,
    validationSchema: validationSchemaForAssign,
  });

  const [openReAssignModal, setOpenReAssignModal] = useState(false);
  const [dataForReAssignClient, setDataForReAssignClient] = useState({});

  let email = dataForReAssignClient ? dataForReAssignClient.email : "";

  useEffect(() => {
    getClientForReAssign(jwtToken, email);
  }, [getClientForReAssign, email]);
  //
  const handleOpenReAssignModal = (data = {}) => {
    setDataForClient(data);
    setDataForReAssignClient(data);
    // setLoadingForReassign(true);
    // setTimeout(() => {
    // setLoadingForReassign(false);
    setOpenReAssignModal(true);
    // }, 4000);
  };

  const handleCloseReAssignModal = () => {
    setOpenReAssignModal(false);
  };

  const [isSuccessReAssignClient, setIsSuccessReAssignClient] = useState(false);
  const [isErrorReAssignClient, setIsErrorReAssignClient] = useState(false);
  useEffect(() => {
    if (isSuccessReAssignClient) {
      setTimeout(() => {
        setIsSuccessReAssignClient(false);
      }, 3000);
    }
  }, [isSuccessReAssignClient]);

  useEffect(() => {
    if (isErrorReAssignClient) {
      setTimeout(() => {
        setIsErrorReAssignClient(false);
      }, 3000);
    }
  }, [isErrorReAssignClient]);

  const initialValuesForReAssign = {
    name: dataForReAssignClient ? dataForReAssignClient.name : "",
  };

  const validationSchemaForReAssign = Yup.object({
    name: Yup.string().required("Enter the name"),
  });

  const onSubmitForReAssign = async (values, { resetForm }) => {
    values.clientId = isCheck;
    values.newAccountManagerId = accountManagerSelectedForEdit?.value;
    values.accountManagerId = dataForReAssignClient?.accountManagerId;
    let response = await editAccountManager(jwtToken, values);

    if (response.status === true) {
      setIsSuccessReAssignClient(true);
      setAccountManagerSelectedForEdit("");
      resetForm();
      getClientForReAssign(jwtToken, email);
      getAllAccountManager(jwtToken, pageNo, rowsPerPage);
      getAccountManager(jwtToken);
      setList([]);
      setIsCheck([]);
      setIsCheckAll(false);
      setShowFilters(!showFilters);
    }
    if (response.status == false) {
      setIsErrorReAssignClient(true);
    }
  };

  const formikForReAssign = useFormik({
    initialValues: initialValuesForReAssign,
    onSubmit: onSubmitForReAssign,
    validationSchema: validationSchemaForReAssign,
    enableReinitialize: true,
  });

  let changeForButton = async (data) => {
    let arr = [];
    arr.push(data);
    let payload = {
      accountManagerId: dataForClient?.accountManagerId,
      clientId: arr,
    };
    let response = await removeClientForReAssign(jwtToken, payload);
    getClientForReAssign(jwtToken, email);
    getAllAccountManager(jwtToken, pageNo, rowsPerPage);
    getAccountManager(jwtToken);
  };

  const [showFilters, setShowFilters] = useState(false);

  const showFiltersHandler = () => {
    setShowFilters(!showFilters);
  };

  //  for all checked box
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(clientOptions);
  }, [list, clientOptions]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  const catalog = list.map(({ id, name }) => {
    return (
      <>
        <div>
          <Checkbox
            key={id}
            type="checkbox"
            name={name}
            id={id}
            handleClick={handleClick}
            isChecked={isCheck.includes(id)}
          />
          <MDTypography
            variant="h6"
            style={{ display: "inline", marginLeft: "10px", fontWeight: "500" }}
          >
            {name}
          </MDTypography>

          <MDButton
            color="error"
            size="small"
            style={{
              float: "right",
            }}
            onClick={() => {
              changeForButton(id);
            }}
            variant="outlined"
            iconOnly
          >
            <Icon> remove_icon </Icon>
          </MDButton>
        </div>
      </>
    );
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading ? (
        <>
          <MDBox pt={2} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                    mb={-6}
                  >
                    <MDBox>
                      <MDTypography variant="h4" gutterBottom>
                        Account Managers
                      </MDTypography>
                    </MDBox>
                    <MDBox color="text" px={2}>
                      <Tooltip title="Create Account Manager">
                        <MDButton
                          color="info"
                          variant="outlined"
                          type="button"
                          onClick={() => {
                            handleOpenAccountManagerModal();
                          }}
                        >
                          Create
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      canSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                      serverSidePagination={true}
                      paginationObj={paginationObj}
                      pageNoValue={pageNo}
                      rowsPerPageValue={rowsPerPage}
                      pageNoProp={importPageNo}
                      rowsPerPageProp={importRowsPerPage}
                    />
                  </MDBox>
                  <Modal
                    open={openAccountManagerModal}
                    onClose={handleCloseAccountManagerModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <MDCard sx={styleForAssign}>
                      <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          flexGrow={1}
                        >
                          Create Account Manager
                        </MDTypography>
                        <MDBox>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={handleCloseAccountManagerModal}
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                      </MDBox>

                      <hr />

                      <MDBox pt={1} pb={3} px={1}>
                        <MDBox
                          component="form"
                          role="form"
                          onSubmit={formik.handleSubmit}
                        >
                          <MDBox mb={2}>
                            <MDTypography
                              mt={1}
                              variant="h6"
                              fontWeight="medium"
                              flexGrow={1}
                              style={{ fontWeight: "500" }}
                            >
                              Enter Name
                            </MDTypography>
                            <MDInput
                              type="text"
                              name="name"
                              fullWidth
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.name}
                              error={
                                formik.touched.name &&
                                formik.errors.name &&
                                true
                              }
                            />
                            {formik.touched.name && formik.errors.name && (
                              <TextError msg={formik.errors.name} />
                            )}
                          </MDBox>
                          <MDBox mb={2}>
                            <MDTypography
                              mt={1}
                              variant="h6"
                              fontWeight="medium"
                              flexGrow={1}
                              style={{ fontWeight: "500" }}
                            >
                              Enter Email
                            </MDTypography>
                            <MDInput
                              type="email"
                              name="email"
                              fullWidth
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              error={
                                formik.touched.email &&
                                formik.errors.email &&
                                true
                              }
                            />
                            {formik.touched.email && formik.errors.email && (
                              <TextError msg={formik.errors.email} />
                            )}
                          </MDBox>
                          <MDBox>
                            <Collapse in={isErrorAccountManager}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorAccountManager(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Please check the details. Server error!
                              </Alert>
                            </Collapse>
                            <Collapse in={isSuccessAccountManager}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessAccountManager(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Account manager created successfully
                              </Alert>
                            </Collapse>
                          </MDBox>
                          <MDBox mt={2}>
                            <MDButton
                              variant="contained"
                              color="info"
                              fullWidth
                              type="submit"
                              disabled={!formik.isValid}
                            >
                              Create
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDCard>
                  </Modal>
                  <Modal
                    open={openDeleteAccountManagerModal}
                    onClose={handleCloseDeleteAccountManagerModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <MDCard sx={deleteStyle}>
                      <MDTypography>
                        Are you sure you want to delete?
                      </MDTypography>
                      <MDBox mt={2}>
                        <div style={{ display: "flex" }}>
                          <div>
                            <MDButton
                              style={{ width: "100%", marginRight: "0.5rem" }}
                              color="info"
                              variant="outlined"
                              onClick={handleCloseDeleteAccountManagerModal}
                            >
                              Cancel
                            </MDButton>
                          </div>
                          <div>
                            <MDButton
                              style={{ width: "100%", marginLeft: "0.5rem" }}
                              color="error"
                              onClick={deleteAccountManagerData}
                            >
                              Delete
                            </MDButton>
                          </div>
                        </div>
                      </MDBox>
                      <MDBox mt={2}>
                        <Collapse in={isErrorDeleteAccountManager}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorDeleteAccountManager(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Please first reassign or remove the clients assigned
                            to account manager.
                          </Alert>
                        </Collapse>
                      </MDBox>
                    </MDCard>
                  </Modal>
                  <Modal
                    open={openAssignModal}
                    onClose={handleCloseAssignModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <MDCard sx={styleForAssign}>
                      <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          flexGrow={1}
                        >
                          Assign
                        </MDTypography>
                        <MDBox>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={handleCloseAssignModal}
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                      </MDBox>

                      <hr />

                      <MDBox pt={1} pb={3} px={1}>
                        <MDBox
                          component="form"
                          role="form"
                          onSubmit={formikForAssign.handleSubmit}
                        >
                          <MDBox mb={2}>
                            <MDTypography
                              mt={1}
                              variant="h6"
                              fontWeight="medium"
                              flexGrow={1}
                              style={{ fontWeight: "500" }}
                            >
                              Company Name
                            </MDTypography>
                            <Select
                              className="select-css"
                              placeholder="Choose one..."
                              name="clientId"
                              isMulti
                              options={accountManagerOptions}
                              value={accountManagerSelected}
                              onChange={(selectedOption) => {
                                handleaccountManagerSelected(selectedOption);
                                formikForAssign.handleChange("clientId")(
                                  selectedOption.label
                                );
                              }}
                            />
                            {formikForAssign.errors.clientId && (
                              <TextError
                                msg={formikForAssign.errors.clientId}
                              />
                            )}
                          </MDBox>
                          <MDBox>
                            <Collapse in={isErrorAssignClient}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorAssignClient(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Please check the details. Server error!
                              </Alert>
                            </Collapse>
                            <Collapse in={isSuccessAssignClient}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessAssignClient(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Client assign successfully
                              </Alert>
                            </Collapse>
                          </MDBox>
                          <MDBox mt={2}>
                            {/* {  
                        accountManagerSelected.length ? <> </> : 
                        } */}
                            <MDButton
                              variant="contained"
                              color="info"
                              fullWidth
                              type="submit"
                              // disabled={!formikForAssign.isValid}
                              disabled={!accountManagerSelected.length}
                            >
                              Assign
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDCard>
                  </Modal>

                  <Modal
                    open={openReAssignModal}
                    onClose={handleCloseReAssignModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <MDCard sx={styleForEdit}>
                      <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          flexGrow={1}
                        >
                          Edit/Re-Assign
                        </MDTypography>
                        <MDBox>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={handleCloseReAssignModal}
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                      </MDBox>

                      <hr />

                      <MDBox pb={2} px={4} py={4}>
                        <MDBox
                          component="form"
                          role="form"
                          onSubmit={formikForReAssign.handleSubmit}
                        >
                          <MDBox mb={2} mt={-3}>
                            <MDTypography
                              variant="h6"
                              fontWeight="medium"
                              flexGrow={1}
                              style={{ fontWeight: "500" }}
                            >
                              Name
                            </MDTypography>

                            <MDInput
                              maxLength={10}
                              // label="Full Name"
                              type="text"
                              name="name"
                              fullWidth
                              autoComplete="off"
                              onChange={formikForReAssign.handleChange}
                              onBlur={formikForReAssign.handleBlur}
                              value={formikForReAssign.values.name}
                              error={
                                formikForReAssign.touched.name &&
                                formikForReAssign.errors.name &&
                                true
                              }
                            />
                            {formikForReAssign.touched.name &&
                              formikForReAssign.errors.name && (
                                <TextError
                                  msg={formikForReAssign.errors.name}
                                />
                              )}
                          </MDBox>
                          {loadingForReassignClient && (
                            <Backdrop
                              sx={{
                                color: "blue",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                              }}
                              open
                            >
                              <CircularProgress color="inherit" />
                            </Backdrop>
                          )}
                          <MDBox>
                            <MDCard
                              style={{
                                // height:"200px" ,
                                border: "1px solid gray",
                                padding: "10px",
                                // overflow: "scroll",
                              }}
                            >
                              <MDBox>
                                <MDBox style={{ display: "flex" }}>
                                  <MDTypography
                                    mt={1}
                                    variant="h6"
                                    fontWeight="medium"
                                    flexGrow={1}
                                  >
                                    Clients
                                  </MDTypography>
                                  <MDButton
                                    color="info"
                                    size="small"
                                    variant="contained"
                                    disabled={!isCheck.length}
                                    onClick={showFiltersHandler}
                                  >
                                    Transfer
                                  </MDButton>
                                </MDBox>
                                <MDBox
                                  mt={2}
                                  style={{
                                    height: "150px",
                                    padding: "10px",
                                    overflow: "scroll",
                                  }}
                                >
                                  <div>
                                    <Checkbox
                                      type="checkbox"
                                      name="selectAll"
                                      id="selectAll"
                                      handleClick={handleSelectAll}
                                      isChecked={isCheckAll}
                                    />
                                    <MDTypography
                                      variant="h6"
                                      style={{
                                        display: "inline",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {" "}
                                      Select All{" "}
                                    </MDTypography>

                                    {catalog}
                                  </div>
                                </MDBox>

                                <MDBox mt={2}>
                                  {showFilters && (
                                    <MDBox>
                                      <MDBox>
                                        <Select
                                          className="select-css"
                                          placeholder="Choose one..."
                                          options={clientOptionsForEdit}
                                          value={accountManagerSelectedForEdit}
                                          onChange={(selectedOption) => {
                                            handleaccountManagerSelectedForEdit(
                                              selectedOption
                                            );
                                            formikForReAssign.handleChange(
                                              "clientId"
                                            )(selectedOption.label);
                                          }}
                                        />{" "}
                                      </MDBox>
                                    </MDBox>
                                  )}
                                </MDBox>
                              </MDBox>
                            </MDCard>
                          </MDBox>
                          <MDBox mt={2}>
                            <Collapse in={isErrorReAssignClient}>
                              <Alert
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorReAssignClient(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Please check the details. Server error!
                              </Alert>
                            </Collapse>
                            <Collapse in={isSuccessReAssignClient}>
                              <Alert
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessReAssignClient(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Client edit and re-assign successfully
                              </Alert>
                            </Collapse>
                          </MDBox>
                          <MDBox mt={2}>
                            {/* {  
                        accountManagerSelected.length ? <> </> : 
                        } */}
                            <MDButton
                              variant="contained"
                              color="success"
                              fullWidth
                              type="submit"
                              // disabled={!formikForAssign.isValid}
                            >
                              Submit
                            </MDButton>
                          </MDBox>
                        </MDBox>
                        <MDBox></MDBox>
                      </MDBox>
                    </MDCard>
                  </Modal>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      ) : (
        <>
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}

      {!loading ? <p className=" font-size text-center text-md-left">
          Developed and maintained by :
          <a className="link" href="http://mobillor.com/">
            <strong style={{ color: "red" }}>
              {" "}
              Mobillor Technologies Pvt. Ltd.
            </strong>
          </a>
        </p> : null}
      {/* {!loadingForReassignClient ? <Footer /> : null} */}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    allAccountManagerReducer: state.allAccountManagerReducer,
    accountManagerReducer: state.accountManagerReducer,
    allClientRequestsReducer: state.allClientRequestsReducer,
    getAllUnassignedClientsReducer: state.getAllUnassignedClientsReducer,
    getClientForReAssignReducer: state.getClientForReAssignReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getAllAccountManager: (jwtToken, pageNo, rowsPerPage) =>
      dispatch(getAllAccountManager(jwtToken, pageNo, rowsPerPage)),
    getAccountManager: (jwtToken) => dispatch(getAccountManager(jwtToken)),
    createAccountManagers: (payload, jwtToken) =>
      dispatch(createAccountManagers(payload, jwtToken)),
    deleteAccountManager: (accountManagerId, jwtToken) =>
      dispatch(deleteAccountManager(accountManagerId, jwtToken)),
    getAllUnassignedClients: (jwtToken) =>
      dispatch(getAllUnassignedClients(jwtToken)),
    assignClient: (payload, jwtToken) =>
      dispatch(assignClient(payload, jwtToken)),
    getClientForReAssign: (jwtToken, email) =>
      dispatch(getClientForReAssign(jwtToken, email)),
    transferClientForReAssign: (jwtToken, payload) =>
      dispatch(transferClientForReAssign(jwtToken, payload)),
    removeClientForReAssign: (jwtToken, payload) =>
      dispatch(removeClientForReAssign(jwtToken, payload)),
    editAccountManager: (jwtToken, payload) =>
      dispatch(editAccountManager(jwtToken, payload)),
      getNotificationForRegisteredClient:(jwtToken) => dispatch(getNotificationForRegisteredClient(jwtToken))
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(AccountManager);
