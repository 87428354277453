/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "./resetPasswordTypes";
import axios from "axios";
import Config from "../../../config/index";
let auth3Url = Config.auth3Url;
let baseUrl = Config.baseUrl;

const resetPasswordRequest = () => {
  return {
    type: RESET_PASSWORD_REQUEST,
  };
};

const resetPasswordSuccess = (payload) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: payload,
  };
};

const resetPasswordFaliure = (error) => {
  return {
    type: RESET_PASSWORD_FAILURE,
    payload: error,
  };
};

const resetPassword = (resetPasswordState) => {
  let payload = {
    email: resetPasswordState.email, 
    oldPassword: resetPasswordState.oldPassword,
    newPassword: resetPasswordState.newPassword,
    // confirmPassword: resetPasswordState.confirmPassword,
  };
  return (dispatch) => { 
    dispatch(resetPasswordRequest());
    let url = `${baseUrl}/users/reset_password`; 
    return axios
      .put(url , payload)  
      .then((response) => {
        dispatch(resetPasswordSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(resetPasswordFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { resetPassword };
