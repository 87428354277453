// /*********************
//  * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
//  * __________________
//  *
//  *  Mobillor Technologies Pvt. Ltd.
//  *  All Rights Reserved.
//  *
//  * NOTICE:  All information contained herein is, and remains
//  * the property of Mobillor Technologies Pvt. Ltd.
//  * The intellectual and technical concepts contained
//  * herein are proprietary to Mobillor Technologies Pvt. Ltd.
//  * may be covered by Rebublic Of India and Foreign Patents,
//  * patents in process, and are protected by trade secret or copyright law.
//  * Dissemination of this information or reproduction of this material
//  * is strictly forbidden unless prior written permission is obtained
//  * from Mobillor Technologies Pvt. Ltd.
//  */

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Grid from "@mui/material/Grid";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CircleIcon from "@mui/icons-material/Circle";

//  file viewer 
import FileViewer from "react-file-viewer";

// Dashboard components
import MDBox from "components/MDBox";
import MDButton from "./../../components/MDButton/index";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";

import TextError from "utils/TextError";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// function from store

import {
  createClientRequests,
  getClientRequestForClient,
  getListOfProfiles,
  acceptOrRejectProfile,
  sortListProfile,
  profilesNotification,
} from "../../store";

// moment
import moment from "moment";

//  some date functions
import { format, addMinutes } from "date-fns";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//  react select and styling
import Select from "react-select";
import "../../index.css";

// DatePicker components and  styling
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Aws & uuid
import AWS from "aws-sdk";
import { v4 } from "uuid";

//  Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

const CreateClientRequest = ({
  createClientRequests,
  getClientRequestForClient,
  getClientRequestForClientsReducer,
  getListOfProfiles,
  listOfProfilesReducer,
  acceptOrRejectProfile,
  sortListProfile,
  profilesNotification,
  profilesNotificationReducer,
}) => {
  const styleForEdit = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 500, lg: 600, xl: 600 },
    height: "90%",
    boxShadow: 24,
    p: 1,
    pt: 3,
  };
  const styleForAcceptOrReject = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    boxShadow: 24,
    p: 2,
    pt: 3,
  };

  const styleForView = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 600, lg: 600, xl: 600 },
    boxShadow: 24,
    p: 2,
    pt: 3,
    mb: 2,
  };
  const styleForDocument = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 600, lg: 900, xl: 900 },
    overflow: "auto",
    height: "90%",
    boxShadow: 24,
    p: 1,
    pt: 3,
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 520,
    boxShadow: 24,
    p: 2,
    pt: 3,
  };
  const notifyForClientRequest = () => {
    toast.info("First you have to fill the client details form", {
      position: "top-center",
      className: "toast-message",
    });
  };

  let data = cookies.get("mobillor-talent-accelerator-user-data");
  let jwtToken = data?.data?.jwtToken;
  let clientId = data.data.data.clientId;
  let isFormFilled = data?.data?.data?.isFormFilled;
  let formFilled = cookies.get("isFormFilled");
  const [statusSelected, setStatusSelected] = useState("");
  const [startDate, setStartDate] = useState("");
  const [requestId, setRequestId] = useState("");

  const isoStartDate = new Date(
    (startDate ? startDate : new Date()).getTime() -
    (startDate ? startDate : new Date()).getTimezoneOffset() * 60000
  ).toISOString();

  let status = statusSelected ? statusSelected?.value : "";
  let date = startDate ? isoStartDate.slice(0, 10) : "";

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearch(globalFilter);
  };

  useEffect(() => {
    getClientRequestForClient(
      clientId,
      jwtToken,
      status,
      date,
      pageNo,
      rowsPerPage
    );
  }, [clientId, status, date, pageNo, rowsPerPage]);

  useEffect(() => {
    profilesNotification(jwtToken, clientId);
  }, [profilesNotification, clientId]);

  const options = [
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },
    { value: "Pending", label: "Pending" },
  ];
  const changeStatus = (selectedData) => {
    setStatusSelected(selectedData);
  };

  const [openSortListModal, setopenSortListModal] = useState(false);
  const [isErrorSortList, setisErrorSortList] = useState(false);

  useEffect(() => {
    if (isErrorSortList) {
      setTimeout(() => {
        setisErrorSortList(false);
      }, 3000);
    }
  }, [isErrorSortList]);
  const [selectedSortListData, setselectedSortListData] = useState([]);
  const [uncheck, setUncheck] = useState(false);
  const [sortListData, setSortListData] = useState({});
  const handleopenSortListModal = (data = {}) => {
    setSortListData(data);
    setopenSortListModal(true);
  };

  const handleCloseSortListModal = () => {
    setopenSortListModal(false);
  };

  const sortListHandler = (event, data) => {
    handleopenSortListModal(data);
  };
  const SortListsData = (data) => {
    setselectedSortListData(data);
  };

  const onClickSortList = async () => {
    let sortListData = [];
    selectedSortListData.map((data) => {
      const object = {
        profileId: data.profileId,
      };
      sortListData.push(object);
    });

    let payload = {
      clientId: clientId,
      requestId: requestId,
      profileId: sortListData,
    };

    let res = await sortListProfile(jwtToken, payload);

    if (res.status) {
      handleCloseSortListModal();
      window.location.reload();
      getClientRequestForClient(
        clientId,
        jwtToken,
        status,
        date,
        pageNo,
        rowsPerPage
      );

    }
    if (!res.status) {
      setisErrorSortList(true);
    }
  };

  const [open, setOpen] = useState(false);

  const [title, setTitle] = useState("");

  const [jobDescFile, setjobDescFile] = useState({});
  const [loc, setLoc] = useState("");
  const [uid, setUid] = useState("");
  const [state, setState] = useState({
    columns: [
      { Header: "Job Title", accessor: "jobTitle" },
      { Header: "Request Time", accessor: "requestTime" },
      { Header: "Skill", accessor: "skill" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Action", accessor: "jobDescription", align: "center" },
    ],
    rows: [],
  });

  const { columns, rows } = state;


  let requestsData = profilesNotificationReducer?.profileData?.data
    ? profilesNotificationReducer?.profileData?.data
    : [];
  let notification = requestsData?.length;

  const [document, setDocument] = useState("");
  const type = "docx";
  const onError = (e) => {
   
  };

  let loading = getClientRequestForClientsReducer?.loading;
  const [paginationObj, setPaginationObj] = useState({});
  useEffect(() => {
    let tempRequest = [];
    let requestsData = getClientRequestForClientsReducer?.clientsData?.data
      ? getClientRequestForClientsReducer?.clientsData?.data
      : [];

    setPaginationObj(
      getClientRequestForClientsReducer?.clientsData?.pagination
    );

   
    requestsData?.map((user) => {
      const formatDate = new Date(user.cd);
      const singleUser = {
        privateKey: user.requestId,
        companyName: user.companyName,
        jobTitle: user.jobTitle,
        skill: user.technology,
        requestId: user.requestId,
        requestTime: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy"
        ),
        jobDescription: (
          <span style={{ display: "flex" }}>
            <Tooltip title="View Document">
              <MDButton
                ml={3}
                variant="gradient"
                color="primary"
                iconOnly
                type="button"
                onClick={() => {
                  handleOpenViewDocumentModal(user);
                }}
              >
                {" "}
                <FileOpenIcon />
              </MDButton>
            </Tooltip>
            <Tooltip title="View">
              <MDButton
                style={{ marginLeft: "10px" }}
                variant="gradient"
                color="info"
                iconOnly
                type="button"
                onClick={() => {
                  handleopenViewModal(user);
                }}
              >
                <Icon> visibility_icon </Icon>
              </MDButton>
            </Tooltip>
          </span>
        ),

        status: user.status,
      };
      tempRequest.push(singleUser);
    });

    setState({ ...state, rows: tempRequest });
  }, [getClientRequestForClientsReducer]);




  useEffect(() => {
    if (jobDescFile.name === undefined) {
    } else {
      upload();
    }
  }, [jobDescFile]);

  const [dataForClient, setdataForClient] = useState({});

  const [openViewModal, setopenViewModal] = useState(false);

  const handleopenViewModal = (data = {}) => {
    setdataForClient(data);
    setopenViewModal(true);
  };

  const handleCloseViewModal = () => {
    setopenViewModal(false);
  };

  const [openViewDocumentModal, setOpenViewDocumentModal] = useState(false);

  const handleOpenViewDocumentModal = (Data = {}) => {
    setDocument(Data.url);
    setOpenViewDocumentModal(true);
  };

  const handleCloseViewDocumentModal = () => {
    setOpenViewDocumentModal(false);
  };

  const [openViewResumeModal, setOpenViewResumeModal] = useState(false);

  const handleOpenViewResumeModal = async (Data = {}) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });
    const url = await s3.getSignedUrlPromise("getObject", {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: Data.resume,
      Expires: 1800,
    });
    setDocument(url);
    setOpenViewResumeModal(true);
  };

  const handleCloseViewResumeModal = () => {
    setOpenViewResumeModal(false);
  };

  const [selectedworkPlace, setSelectedworkPlace] = useState("");

  const handleworkPlaceChange = (selectedworkPlace) => {
    setSelectedworkPlace(selectedworkPlace);
  };

  const [selectedJob, setSelectedJob] = useState("");

  const handleJobChange = (selectedJob) => {
    setSelectedJob(selectedJob);
  };
  const [selectedContactPeriod, setSelectedContactPeriod] = useState("");

  const handleContactPeriodChange = (selectedContactPeriod) => {
    setSelectedContactPeriod(selectedContactPeriod);
  };

  const ContactPeriodOptions = [
    { value: "3 Months", label: "3 Months" },
    { value: "6 Months", label: "6 Months" },
    { value: "More than 6 months", label: "More than 6 months" },
  ];

  const workPlaceOptions = [
    { value: "On Site", label: "On Site" },
    { value: "Hybrid", label: "Hybrid" },
    { value: "Remote", label: "Remote" },
  ];
  const jobOptions = [
    { value: "Contract Full-time", label: "Contract Full-time" },
    { value: "Contract Part-time", label: "Contract Part-time" },
    { value: "Contract To Hire", label: "Contract To Hire" },
  ];

  const initialValues = {
    jobTitle: "",
    workplaceType: "",
    contractPeriod: "",
    startDate: "",
    jobDescription: "",
    location: "",
    clientId: "",
    technology: "",
    experience: "",
  };

  const validationSchema = Yup.object({
    jobTitle: Yup.string().required("Enter a Job title"),
    workplaceType: Yup.string().required("Enter a work place type"),
    jobType: Yup.string().required("Enter a job type"),
    contractPeriod: Yup.string().required("Enter a contract period"),
    startDate: Yup.string().required("Enter a start date"),
    technology: Yup.string().required("Enter a technology"),
    experience: Yup.string()
      .required("Enter a experience")
      .min(1, "Invalid data")
      .max(2, "Invalid data")
      .test("Is positive", "must be positive", (val) => val >= 0),
  });

  const [isSuccessCreateClientRequest, setisSuccessCreateClientRequest] =
    useState(false);
  const [isErrorCreateClientRequest, setisErrorCreateClientRequest] =
    useState(false);

  useEffect(() => {
    if (isSuccessCreateClientRequest) {
      setTimeout(() => {
        setisSuccessCreateClientRequest(false);
      }, 3000);
    }
  }, [isSuccessCreateClientRequest]);

  useEffect(() => {
    if (isErrorCreateClientRequest) {
      setTimeout(() => {
        setisErrorCreateClientRequest(false);
      }, 3000);
    }
  }, [isErrorCreateClientRequest]);

  const handleChange = (event) => {
    setjobDescFile(event.target.files[0]);
  };

  const [documentName, setDocumentName] = useState("");
  var UUID;
  var location;
  var uuid = v4();

  const upload = () => {
    let filename = `${uuid}_${jobDescFile.name}`;
    setDocumentName(filename);
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    var params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: filename,
      Body: jobDescFile,
      ContentType: "application/pdf",
    };

    s3.upload(params, (err, data) => {
      if (err) {
      } else {
        location = data.Location;
        UUID = uuid;
        setLoc(location);
        setUid(uuid);
      }
    });
  };

  const onSubmit = async (values, { resetForm }) => {

    values.contractPeriod = selectedContactPeriod?.value;
    values.workplaceType = selectedworkPlace?.value;
    values.jobDescFile = documentName;
    values.clientId = clientId;
    let response = await createClientRequests(values, jwtToken);
    if (response.status === true) {
      setisSuccessCreateClientRequest(true);
      notificationAfterCreateRequest();
      handleCloseCreateRequestModal();
      getClientRequestForClient(
        clientId,
        jwtToken,
        status,
        date,
        pageNo,
        rowsPerPage
      );
      resetForm();
    }
    if (response.status === false) {
      setisErrorCreateClientRequest(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const navigate = useNavigate();

  const [openRequestModal, setopenRequestModal] = useState(false);

  const handleOpenCreateRequestModal = (asnData = {}) => {
    setopenRequestModal(true);
  };

  const handleCloseCreateRequestModal = () => {
    setopenRequestModal(false);
  };

  const handleCreateRequest = () => {
    if (isFormFilled == true || formFilled == "true") {
      handleOpenCreateRequestModal();
    } else {
      cookies.set("create-request", "true");
      navigate("/client-form");
      notifyForClientRequest();
    }
  };

  const [openAcceptProfileModal, setopenAcceptProfileModal] = useState(false);
  const [singleProfileDataForProfile, setProfileDataForProfile] = useState({});

  const handleopenAcceptProfileModal = (department = {}) => {
    setProfileDataForProfile(department);
    setopenAcceptProfileModal(true);
  };
  const handleCloseProfileModal = () => {
    setopenAcceptProfileModal(false);
  };
  const [isErrorAcceptProfile, setisErrorAcceptProfile] = useState(false);

  useEffect(() => {
    if (isErrorAcceptProfile) {
      setTimeout(() => {
        setisErrorAcceptProfile(false);
      }, 3000);
    }
  }, [isErrorAcceptProfile]);

  const acceptProfileData = async () => {
    let status = "Accepted";
    let profileId = singleProfileDataForProfile.profileId;
    let response = await acceptOrRejectProfile(
      jwtToken,
      profileId,
      clientId,
      requestId,
      status
    );

    if (response.status) {

      handleCloseProfileModal();
    }
    if (!response.status) {
      setisErrorAcceptProfile(true);
    }
  };

  //  for reject profile
  const [openRejectProfileModal, setopenRejectProfileModal] = useState(false);
  const [singleProfileData, setProfileData] = useState({});

  const handleopenRejectProfileModal = (department = {}) => {
    setProfileData(department);
    setopenRejectProfileModal(true);
  };
  const handleCloseRejectProfileModal = () => {
    setopenRejectProfileModal(false);
  };
  const [isErrorRejectProfile, setisErrorRejectProfile] = useState(false);

  useEffect(() => {
    if (isErrorRejectProfile) {
      setTimeout(() => {
        setisErrorRejectProfile(false);
      }, 3000);
    }
  }, [isErrorRejectProfile]);

  const rejectProfileData = async () => {
    let status = "Rejected";
    let profileId = singleProfileData.profileId;
    let response = await acceptOrRejectProfile(
      jwtToken,
      profileId,
      clientId,
      requestId,
      status
    );
    if (response.status) {

      handleCloseRejectProfileModal();
    }
    if (!response.status) {
      setisErrorRejectProfile(true);
    }
  };

  const notificationAfterCreateRequest = () => {
    toast.success("Thanks for Submitting your Talent Request. Mobillor team will get back to you soon.", {
      position: "top-center",
      className: "toast-message-for-create-request",
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-6}
              >
                <MDBox>
                  <MDTypography variant="h4" gutterBottom>
                    Resource Request
                  </MDTypography>
                </MDBox>
                <Tooltip title="Create Resource Request">
                  <MDBox color="text" px={2}>
                    <MDButton
                      color="info"
                      mr={10}
                      onClick={() => {
                        handleCreateRequest();
                      }}
                    >
                      Create resource request
                    </MDButton>
                  </MDBox>
                </Tooltip>
              </MDBox>
              <MDBox mt={2}>
                <MDBox
                  style={{ display: "flex" }}
                  flexDirection={{
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  }}
                  mt={2}
                  ml={3}
                >
                  <MDBox
                    style={{ width: "200px" }}
                    marginBottom={{
                      xs: "0px",
                      sm: "0px",
                      md: "0px",
                      lg: "0px",
                      xl: "0px",
                    }}
                  >
                    <Select
                      isClearable={true}
                      className="select-css"
                      placeholder="All Status"
                      options={options}
                      value={statusSelected}
                      onChange={(selectedOption) => {
                        changeStatus(selectedOption);
                      }}
                      autoComplete="off"
                    />{" "}
                  </MDBox>
                  <MDBox
                    style={{ 
                      width:"200px"
                    }} 
                    marginLeft={{
                      xs: "0px",
                      sm: "0px",
                      md: "10px",
                      lg: "10px",
                      xl: "10px",
                    }}
                  >
                    <DatePicker
                      id="date-picker"
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select Date"
                      maxDate={new Date()}
                      onChange={(date) => setStartDate(date)}
                      isClearable={true}
                      withPortal
                      selectsStart
                      selected={startDate}
                      startDate={startDate}
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                  serverSidePagination={true}
                  paginationObj={paginationObj}
                  pageNoValue={pageNo}
                  rowsPerPageValue={rowsPerPage}
                  pageNoProp={importPageNo}
                  rowsPerPageProp={importRowsPerPage}
                />
              </MDBox>
              <Modal
                open={openRequestModal}
                onClose={handleCloseCreateRequestModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForEdit}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Create Request
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseCreateRequestModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                            padding: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDBox pb={3} px={1} overflow="auto">
                    <MDBox pt={1} px={1}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formik.handleSubmit}
                      >
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500", fontSize: 14 }}
                          >
                            Job Title
                            <span
                              style={{
                                color: "red",
                                marginLeft: 5,
                                marginBottom: 15,
                              }}
                            >
                              *
                            </span>
                          </MDTypography>
                          <MDInput
                            type="text"
                            name="jobTitle"
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.jobTitle}
                            error={
                              formik.touched.jobTitle &&
                              formik.errors.jobTitle &&
                              true
                            }
                            inputProps={{
                              style: {
                                height: "10px",
                              },
                            }}
                          />
                          {formik.touched.jobTitle &&
                            formik.errors.jobTitle && (
                              <TextError msg={formik.errors.jobTitle} />
                            )}
                        </MDBox>

                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500", fontSize: 14 }}
                          >
                            Technology{" "}
                            <span
                              style={{
                                color: "red",
                                marginLeft: 5,
                                marginBottom: 15,
                              }}
                            >
                              *
                            </span>
                          </MDTypography>
                          <MDInput
                            type="text"
                            name="technology"
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.technology}
                            error={
                              formik.touched.technology &&
                              formik.errors.technology &&
                              true
                            }
                            inputProps={{
                              style: {
                                height: "10px",
                              },
                            }}
                          />
                          {formik.touched.technology &&
                            formik.errors.technology && (
                              <TextError msg={formik.errors.technology} />
                            )}
                        </MDBox>

                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500", fontSize: 14 }}
                          >
                            Experience (In Year/s){" "}
                            <span
                              style={{
                                color: "red",
                                marginLeft: 5,
                                marginBottom: 15,
                              }}
                            >
                              *
                            </span>
                          </MDTypography>
                          <MDInput
                            type="number"
                            name="experience"
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.experience}
                            error={
                              formik.touched.experience &&
                              formik.errors.experience &&
                              true
                            }
                            maxLength="4"
                            inputProps={{
                              maxLength: 4,
                              style: {
                                height: "10px",
                              },
                            }}
                          />
                          {formik.touched.experience &&
                            formik.errors.experience && (
                              <TextError msg={formik.errors.experience} />
                            )}
                        </MDBox>
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500", fontSize: 14 }}
                          >
                            Job Type{" "}
                            <span
                              style={{
                                color: "red",
                                marginLeft: 5,
                                marginBottom: 15,
                              }}
                            >
                              *
                            </span>
                          </MDTypography>
                          <Select
                            className="select-css"
                            name="jobType"
                            value={selectedJob}
                            options={jobOptions}
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "jobType" },
                              });
                            }}
                            onChange={(selectedOption) => {
                              handleJobChange(selectedOption);
                              formik.handleChange("jobType")(
                                selectedOption.value
                              );
                            }}
                            inputProps={{
                              style: {
                                height: "10px",
                              },
                            }}
                          />

                          {formik.touched.jobType && formik.errors.jobType && (
                            <TextError msg={formik.errors.jobType} />
                          )}
                        </MDBox>

                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500", fontSize: 14 }}
                          >
                            Work Place Type{" "}
                            <span
                              style={{
                                color: "red",
                                marginLeft: 5,
                                marginBottom: 15,
                              }}
                            >
                              *
                            </span>
                          </MDTypography>
                          <Select
                            className="select-css"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "workplaceType" },
                              });
                            }}
                            name="workplaceType"
                            value={selectedworkPlace}
                            options={workPlaceOptions}
                            onChange={(selectedOption) => {
                              handleworkPlaceChange(selectedOption);
                              formik.handleChange("workplaceType")(
                                selectedOption.value
                              );
                            }}
                            inputProps={{
                              style: {
                                height: "10px",
                              },
                            }}
                          />

                          {formik.touched.workplaceType &&
                            formik.errors.workplaceType && (
                              <TextError msg={formik.errors.workplaceType} />
                            )}
                        </MDBox>

                        {selectedworkPlace?.value == "Hybrid" && (
                          <>
                            <MDBox mb={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Location{" "}
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: 5,
                                    marginBottom: 15,
                                  }}
                                >
                                  *
                                </span>
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="location"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.location}
                                error={
                                  formik.touched.location &&
                                  formik.errors.location &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "10px",
                                  },
                                }}
                              />
                              {formik.touched.location &&
                                formik.errors.location && (
                                  <TextError msg={formik.errors.location} />
                                )}
                            </MDBox>
                          </>
                        )}

                        {selectedworkPlace?.value == "On Site" && (
                          <>
                            <MDBox mb={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Location{" "}
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: 5,
                                    marginBottom: 15,
                                  }}
                                >
                                  *
                                </span>
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="location"
                                fullWidth
                                autoComplete="off"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.location}
                                error={
                                  formik.touched.location &&
                                  formik.errors.location &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "10px",
                                  },
                                }}
                              />
                              {formik.touched.location &&
                                formik.errors.location && (
                                  <TextError msg={formik.errors.location} />
                                )}
                            </MDBox>
                          </>
                        )}

                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500", fontSize: 14 }}
                          >
                            Contract Period{" "}
                            <span
                              style={{
                                color: "red",
                                marginLeft: 5,
                                marginBottom: 15,
                              }}
                            >
                              *
                            </span>
                          </MDTypography>
                          <Select
                            className="select-css"
                            name="contractPeriod"
                            onBlur={() => {
                              formik.handleBlur({
                                target: { name: "contractPeriod" },
                              });
                            }}
                            value={selectedContactPeriod}
                            options={ContactPeriodOptions}
                            onChange={(selectedOption) => {
                              handleContactPeriodChange(selectedOption);
                              formik.handleChange("contractPeriod")(
                                selectedOption.value
                              );
                            }}
                            inputProps={{
                              style: {
                                height: "10px",
                              },
                            }}
                          />

                          {formik.touched.contractPeriod &&
                            formik.errors.contractPeriod && (
                              <TextError msg={formik.errors.contractPeriod} />
                            )}
                        </MDBox>

                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500", fontSize: 14 }}
                          >
                            Start Date{" "}
                            <span
                              style={{
                                color: "red",
                                marginLeft: 5,
                                marginBottom: 15,
                              }}
                            >
                              *
                            </span>
                          </MDTypography>
                          <MDInput
                            type="date"
                            name="startDate"
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.startDate}
                            error={
                              formik.touched.startDate &&
                              formik.errors.startDate &&
                              true
                            }
                            inputProps={{
                              style: {
                                height: "10px",
                              },
                            }}
                          />
                          {formik.touched.startDate &&
                            formik.errors.startDate && (
                              <TextError msg={formik.errors.startDate} />
                            )}
                        </MDBox>

                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500", fontSize: 14 }}
                          >
                            Job Description (1000 Words)
                          </MDTypography>
                          <MDInput
                            multiline
                            rows={5}
                            type="text"
                            name="jobDescription"
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.jobDescription}
                            error={
                              formik.touched.jobDescription &&
                              formik.errors.jobDescription &&
                              true
                            }
                          />
                          {formik.touched.jobDescription &&
                            formik.errors.jobDescription && (
                              <TextError msg={formik.errors.jobDescription} />
                            )}
                        </MDBox>

                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500", fontSize: 14 }}
                          >
                            Job Description File (Only Pdf)
                          </MDTypography>
                          <input
                            name="jobDescFile"
                            style={{
                              borders: "none",
                              marginTop: "10px",
                              width: "100%",
                            }}
                            className="choose_file"
                            type="file"
                            autoComplete="off"
                            // accept=".pdf"
                            //   name="agreement"
                            id="chooseFile"
                            onChange={(event) => handleChange(event)}
                          />
                        </MDBox>

                        <MDBox>
                          <Collapse in={isErrorCreateClientRequest}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setisErrorCreateClientRequest(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Please check the details. Server error!
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessCreateClientRequest}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setisSuccessCreateClientRequest(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Client request submitted successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={2}>
                          <MDButton
                            color="info"
                            type="submit"
                            fullWidth
                            disabled={!formik.isValid}
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <Modal
          open={openViewModal}
          onClose={handleCloseViewModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDCard sx={styleForView}>
            <MDBox ml={1} mt={-1} display="flex" alignItems="center">
              <MDTypography
                variant="h4"
                fontWeight="medium"
                flexGrow={1}
                style={{ display: "flex", justifyContent: "center" }}
              >
                Client Details
              </MDTypography>
              <MDBox>
                <Tooltip title="Close">
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={handleCloseViewModal}
                    style={{
                      background: "whitesmoke",
                      color: "black",
                      borderRadius: 5,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </MDBox>
            </MDBox>

            <hr />

            <MDCard>
              <MDBox mt={2}>
                <div>
                  <Grid container spacing={1} ml={1}>
                    <Grid item xs={12}>
                      <MDBox display="flex">
                        <MDTypography
                          mr={1}
                          variant="h6"
                          style={{ fontSize: "15px" }}
                        // fontWeight="medium"
                        >
                          Start Date :
                        </MDTypography>
                        <MDTypography
                          variant="text"
                          //  fontWeight="medium"
                          style={{ fontSize: "15px" }}
                        >
                          {dataForClient.startDate
                            ? moment(dataForClient.startDate)
                              .format("DD/MM/YYYY")
                              .slice(0, 10)
                            : ""}{" "}
                        </MDTypography>
                      </MDBox>
                    </Grid>

                    <Grid item xs={12}>
                      <MDBox display="flex">
                        <MDTypography
                          style={{ fontSize: "15px" }}
                          mr={1}
                          variant="h6"
                          fontWeight="medium"
                        >
                          Job Type :
                        </MDTypography>
                        <MDTypography
                          variant="text"
                          style={{ fontSize: "15px" }}
                        >
                          {dataForClient.jobType}
                        </MDTypography>
                      </MDBox>
                    </Grid>

                    {dataForClient.experience ? (
                      <>
                        <Grid item xs={12}>
                          <MDBox display="flex">
                            <MDTypography
                              mr={1}
                              fontWeight="medium"
                              variant="h6"
                              style={{ fontSize: "15px" }}
                            >
                              Experience :
                            </MDTypography>
                            <MDTypography
                              variant="text"
                              style={{ fontSize: "15px" }}
                            >
                              {dataForClient.experience + " " + " Year(s)"}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </>
                    ) : null}

                    {dataForClient.location ? (
                      <>
                        <Grid item xs={12}>
                          <MDBox display="flex">
                            <MDTypography
                              mr={1}
                              fontWeight="medium"
                              variant="h6"
                              style={{ fontSize: "15px" }}
                            >
                              Location :
                            </MDTypography>
                            <MDTypography
                              variant="text"
                              style={{ fontSize: "15px" }}
                            >
                              {dataForClient.location}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </>
                    ) : null}
                    <Grid item xs={12}>
                      <MDBox display="flex">
                        <MDTypography
                          mr={1}
                          variant="h6"
                          style={{ fontSize: "15px" }}
                          fontWeight="medium"
                        >
                          Contract Period :
                        </MDTypography>
                        <MDTypography
                          variant="text"
                          style={{ fontSize: "15px" }}
                        >
                          {dataForClient.contractPeriod}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    {dataForClient.comment ? (
                      <>
                        <Grid item xs={12}>
                          <MDBox display="flex">
                            <MDTypography
                              mr={1}
                              variant="h6"
                              style={{ fontSize: "15px" }}
                              fontWeight="medium"
                            >
                              Comment :
                            </MDTypography>
                            <MDTypography
                              variant="text"
                              style={{ fontSize: "15px" }}
                            >
                              {dataForClient.comment}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </>
                    ) : null}

                    <>
                      <Grid item xs={12}>
                        <MDBox mb={3} mr={2}>
                          <MDTypography
                            mr={1}
                            variant="h6"
                            fontWeight="medium"
                            style={{ fontSize: "15px" }}
                          >
                            Job Description :
                          </MDTypography>
                          <MDInput
                            inputProps={{ readOnly: true }}
                            multiline
                            rows={5}
                            fullWidth
                            value={dataForClient.jobDescription}
                          />
                        </MDBox>
                    
                      </Grid>
                    </>
                  </Grid>
                </div>
              </MDBox>
            </MDCard>
          </MDCard>
        </Modal>
        <MDBox>
          <Modal
            open={openViewDocumentModal}
            onClose={handleCloseViewDocumentModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={styleForDocument}>
              <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                  View Document
                </MDTypography>
                <MDBox>
                  <Tooltip title="Close">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleCloseViewDocumentModal}
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>

              <hr />

              <MDBox pt={1} pb={3} px={1} height="100%">
                <iframe
                  style={{
                    height: "100%",
                    width: "100%",
                    overflow: "auto",
                  }}
                  src={document}
                ></iframe>

                {/* <FileViewer
style={{
  height: "100%",
  width: "100%",
  overflow: "auto",
}} 
        className="file-viewer-style"
        fileType={type}
        filePath={document}
        onError={onError}
      />  */}

              </MDBox>
            </MDCard>
          </Modal>
        </MDBox>
        <MDBox>
          <Modal
            open={openViewResumeModal}
            onClose={handleCloseViewDocumentModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={styleForDocument}>
              <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                  View Resume
                </MDTypography>
                <MDBox>
                  <Tooltip title="Close">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      onClick={handleCloseViewResumeModal}
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>

              <hr />

              <MDBox pt={1} pb={3} px={1} height="100%">
                <iframe
                  style={{
                    height: "100%",
                    width: "100%",
                    overflow: "auto",
                  }}
                  src={document}
                ></iframe>
              </MDBox>
            </MDCard>
          </Modal>
        </MDBox>
        <MDBox>
          <Modal
            open={openAcceptProfileModal}
            onClose={handleCloseProfileModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={styleForAcceptOrReject}>
              <MDTypography>Are you sure you want to accept?</MDTypography>
              <MDBox mt={2}>
                <div style={{ display: "flex" }}>
                  <div>
                    <MDButton
                      style={{ width: "100%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseProfileModal}
                    >
                      Cancel
                    </MDButton>
                  </div>
                  <div>
                    <MDButton
                      style={{ width: "100%", marginLeft: "0.5rem" }}
                      color="success"
                      onClick={acceptProfileData}
                    >
                      Accept
                    </MDButton>
                  </div>
                </div>
              </MDBox>
              <MDBox mt={2}>
                <Collapse in={isErrorAcceptProfile}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setisErrorAcceptProfile(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Please check the details. Server error!
                  </Alert>
                </Collapse>
              </MDBox>
            </MDCard>
          </Modal>
        </MDBox>
        <MDBox>
          <Modal
            open={openRejectProfileModal}
            onClose={handleCloseRejectProfileModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={styleForAcceptOrReject}>
              <MDTypography>Are you sure you want to reject?</MDTypography>
              <MDBox mt={2}>
                <div style={{ display: "flex" }}>
                  <div>
                    <MDButton
                      style={{ width: "100%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseRejectProfileModal}
                    >
                      Cancel
                    </MDButton>
                  </div>
                  <div>
                    <MDButton
                      style={{ width: "100%", marginLeft: "0.5rem" }}
                      color="error"
                      onClick={rejectProfileData}
                    >
                      Reject
                    </MDButton>
                  </div>
                </div>
              </MDBox>
              <MDBox mt={2}>
                <Collapse in={isErrorRejectProfile}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setisErrorRejectProfile(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Please check the details. Server error!
                  </Alert>
                </Collapse>
              </MDBox>
            </MDCard>
          </Modal>
        </MDBox>
        <MDBox>
          <Modal
            open={openSortListModal}
            onClose={handleCloseSortListModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard sx={style}>
              <MDTypography>
                Are you sure you want to short list these profiles ?
              </MDTypography>
              <MDBox mt={2}>
                <MDButton
                  style={{ width: "48%", marginRight: "0.5rem" }}
                  color="info"
                  variant="outlined"
                  onClick={handleCloseSortListModal}
                >
                  Cancel
                </MDButton>
                <MDButton
                  style={{ width: "48%", marginLeft: "0.5rem" }}
                  color="success"
                  onClick={onClickSortList}
                >
                  Short List
                </MDButton>
              </MDBox>
              <MDBox mt={2}>
                <Collapse in={isErrorSortList}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setisErrorSortList(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Could not sort list these profiles
                  </Alert>
                </Collapse>
              </MDBox>
            </MDCard>
          </Modal>
        </MDBox>
      </MDBox>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <ToastContainer />
      <p className=" font-size text-center text-md-left">
          Developed and maintained by :
          <a className="link" href="http://mobillor.com/">
            <strong style={{ color: "red" }}>
              {" "}
              Mobillor Technologies Pvt. Ltd.
            </strong>
          </a>
        </p>
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getClientRequestForClientsReducer: state.getClientRequestForClientsReducer,
    listOfProfilesReducer: state.listOfProfilesReducer,
    profilesNotificationReducer: state.profilesNotificationReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getClientRequestForClient: (
      clientId,
      jwtToken,
      status,
      date,
      pageNo,
      rowsPerPage
    ) =>
      dispatch(
        getClientRequestForClient(
          clientId,
          jwtToken,
          status,
          date,
          pageNo,
          rowsPerPage
        )
      ),
    sortListProfile: (jwtToken, payload) =>
      dispatch(sortListProfile(jwtToken, payload)),

    createClientRequests: (payload, jwtToken) =>
      dispatch(createClientRequests(payload, jwtToken)),
    acceptOrRejectProfile: (jwtToken, profileId, clientId, requestId, status) =>
      dispatch(
        acceptOrRejectProfile(jwtToken, profileId, clientId, requestId, status)
      ),
    profilesNotification: (jwtToken, clientId) =>
      dispatch(profilesNotification(jwtToken, clientId)),
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(CreateClientRequest);
