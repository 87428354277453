/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import TextError from "utils/TextError";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//  import aws or uuid
import AWS from "aws-sdk";
import { v4 } from "uuid";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

//  Formik & Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// DatePicker components and  styling
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//  moment
import moment from "moment";

//  some date functions
import { format, addMinutes } from "date-fns";

// @mui material icons
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";

// Functions from store
import {
  acceptAndRejectClient,
  getClientRequestForManager,
  getProfiles,
  assignProfiles,
} from "../../store";

//  Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

const ClientRequestForManager = ({
  acceptAndRejectClient,
  getClientRequestForManager,
  getClientRequestForManagerReducer,
  getProfiles,
  profilesReducer,
  assignProfiles,
}) => {
  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: "#4caf50",
          },
          colorPrimary: {
            "&.Mui-checked": {
              // Controls checked color for the thumb
              color: "#4caf50",
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.5,
            backgroundColor: "#4caf50",
            ".Mui-checked.Mui-checked + &": {
              // Controls checked color for the track
              opacity: 0.6,
              backgroundColor: "#4caf50",
            },
          },
        },
      },
    },
  });

  const themeForRejected = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: "#d32f2f",
          },
          colorPrimary: {
            "&.Mui-checked": {
              // Controls checked color for the thumb
              color: "#d32f2f",
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.5,
            backgroundColor: "#d32f2f",
            ".Mui-checked.Mui-checked + &": {
              // Controls checked color for the track
              opacity: 0.6,
              backgroundColor: "#d32f2f",
            },
          },
        },
      },
    },
  });
  const themeForPending = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: "white",
          },
          colorPrimary: {
            "&.Mui-checked": {
              // Controls checked color for the thumb
              color: "white",
            },
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.5,
            backgroundColor: "gray",
            ".Mui-checked.Mui-checked + &": {
              // Controls checked color for the track
              opacity: 0.6,
              backgroundColor: "gray",
            },
          },
        },
      },
    },
  });

  let data = cookies.get("mobillor-talent-accelerator-user-data");
  let email = data?.data?.data?.email;
  let jwtToken = data?.data?.jwtToken;
  let accountManagerId = data?.data?.data?.accountManagerId;
  const [statusSelected, setStatusSelected] = useState("");
  const [startDate, setStartDate] = useState("");

  const isoStartDate = new Date(
    (startDate ? startDate : new Date()).getTime() -
      (startDate ? startDate : new Date()).getTimezoneOffset() * 60000
  ).toISOString();

  let status = statusSelected ? statusSelected?.value : "";
  let date = startDate ? isoStartDate.slice(0, 10) : "";

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearch(globalFilter);
  };

  useEffect(() => {
    getClientRequestForManager(
      accountManagerId,
      jwtToken,
      status,
      date,
      pageNo,
      rowsPerPage
    );
  }, [getClientRequestForManager, status, date, pageNo, rowsPerPage]);

  useEffect(() => {
    getProfiles(jwtToken);
  }, [getProfiles]);

  const options = [
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },
    { value: "Pending", label: "Pending" },
  ];
  const changeStatus = (selectedData) => {
    setStatusSelected(selectedData);
  };

  const styleForEdit = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    boxShadow: 24,
    p: 1,
    pt: 3,
  };

  const styleForView = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 600, lg: 600, xl: 600 },
    boxShadow: 24,
    p: 2,
    pt: 3,
  };
  const styleForDocument = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 600, lg: 900, xl: 900 },
    overflow: "auto",
    height: "90%",
    boxShadow: 24,
    p: 1,
    pt: 3,
  };

  const styleForAssign = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 600, lg: 750, xl: 750 },
    height: "86%",
    overflow: "auto",
    boxShadow: 24,
    p: 1,
    pt: 2,
  };

  const [state, setState] = useState({
    columns: [
      { Header: "Company Name", accessor: "companyName" },
      { Header: "Request Time", accessor: "requestTime" },
      { Header: "Job Title", accessor: "jobTitle" },
      { Header: "Skill", accessor: "skill" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Accept Request", accessor: "acceptRequest", align: "center" },
      { Header: "Action", accessor: "jobDescription", align: "center" },
    ],
    rows: [],
  });

  const { columns, rows } = state;
  const [stateForProfile, setStateForProfiles] = useState({
    columns: [
      { Header: "Name", accessor: "name" },
      { Header: "possible Joining", accessor: "possibleJoining" },
      { Header: "noticePeriod", accessor: "noticePeriod" },
      { Header: "Technology", accessor: "technology" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: [],
  });

  const { columns: itemColumns, rows: itemRows } = stateForProfile;

  useEffect(() => {
    let tempProfiles = [];
    let data = profilesReducer.profiles.data
      ? profilesReducer.profiles.data
      : [];

    if (profilesReducer.profiles.data?.length) {
      data?.map((data) => {
        const formatDate = new Date(data.possibleJoining);
        const singledata = {
          privateKey: data.profileId,
          profileId: data.profileId,
          name: data.name,
          technology: data.technology.map((item) => item.value).join(" , "),
          possibleJoining: format(
            addMinutes(formatDate, formatDate.getTimezoneOffset()),
            "do MMMM yyyy"
          ),
          noticePeriod: data.noticePeriod,
          action: (
            <span style={{ display: "flex" }}>
              <Tooltip title="View Document">
                <MDButton
                  style={{ marginLeft: "10px" }}
                  ml={3}
                  variant="gradient"
                  color="secondary"
                  iconOnly
                  type="button"
                  onClick={() => {
                    handleOpenViewDocumentForProfileModal(data);
                  }}
                >
                  <FileOpenIcon />
                </MDButton>
              </Tooltip>
            </span>
          ),
        };
        tempProfiles.push(singledata);
      });
    }
    setStateForProfiles({ ...stateForProfile, rows: tempProfiles });
  }, [profilesReducer.profiles.data]);

  const [document, setDocument] = useState("");
  let loading = getClientRequestForManagerReducer.loading;
  const [paginationObj, setPaginationObj] = useState({});
  useEffect(() => {
    let tempRequests = [];
    let data = getClientRequestForManagerReducer?.clientsData?.data
      ? getClientRequestForManagerReducer?.clientsData?.data
      : [];

    if (getClientRequestForManagerReducer?.clientsData?.data?.length) {
      setPaginationObj(
        getClientRequestForManagerReducer?.clientsData?.pagination 
      );
      data?.map((data) => {
        const formatDate = new Date(data.cd);
        const singledata = {
          companyName: data.companyName,
          jobTitle: data.jobTitle,
          skill: data.technology,
          requestTime: format(
            addMinutes(formatDate, formatDate.getTimezoneOffset()),
            "do MMMM yyyy"
          ),
          jobDescription: (
            <span style={{ display: "flex" }}>
              <Tooltip title="View">
                <MDButton
                  style={{ marginLeft: "10px" }}
                  variant="gradient"
                  color="info"
                  iconOnly
                  type="button"
                  onClick={() => {
                    handleOpenViewJobDescriptionModal(data);
                  }}
                >
                  <Icon> visibility_icon </Icon>
                </MDButton>
              </Tooltip>
              <Tooltip title="View Document">
                <MDButton
                  style={{ marginLeft: "10px" }}
                  ml={3}
                  variant="gradient"
                  color="primary"
                  iconOnly
                  type="button"
                  onClick={() => {
                    handleOpenViewDocumentModal(data);
                    setDocument(data.url);
                  }}
                >
                  {" "}
                  <FileOpenIcon />
                </MDButton>
              </Tooltip>

              <Tooltip title="Reject Request">
                <MDButton
                  style={{ marginLeft: "10px" }}
                  variant="gradient"
                  color="error"
                  iconOnly
                  disabled={data.status === "Rejected"}
                  type="button"
                  onClick={() => {
                    handleOpenRejectModal(data);
                  }}
                >
                  <Icon> cancel_icon </Icon>
                </MDButton>
              </Tooltip>
              {/* {data.status === "Accepted" ? (
                <Tooltip title="Assign Profile">
                  <MDButton
                    style={{
                      background: "#7D3C98",
                      color: "white",
                      marginLeft: "10px",
                    }}
                    ml={5}
                    variant="contained"
                    color="white"
                    iconOnly
                    type="button"
                    onClick={() => {
                      handleOpenAssignModal(data);
                    }}
                  >
                    {" "}
                    <Icon> add_icon</Icon>
                  </MDButton>
                </Tooltip>
              ) : (
                <Tooltip title="Assign Profile">
                  <MDButton
                    disabled
                    style={{
                      background: "#7D3C98",
                      color: "white",
                      marginLeft: "10px",
                    }}
                    ml={5}
                    variant="contained"
                    color="white"
                    iconOnly
                    type="button"
                  >
                    {" "}
                    <Icon> add_icon</Icon>
                  </MDButton>
                </Tooltip>
              )} */}
            </span>
          ),
          status: data.status,
          acceptRequest: (
            <>
              {data.status === "Accepted" && (
                <>
                  <ThemeProvider theme={theme}>
                    <Switch
                      inputProps={{ readOnly: true }}
                      // disabled
                      checked={data.status === "Accepted" ? true : false}
                      onChange={() => {
                        // handleOpenAcceptModal(data);
                      }}
                    />
                  </ThemeProvider>
                </>
              )}
              {data.status === "Pending" && (
                <>
                  <ThemeProvider theme={""}>
                    <Switch
                      checked={data.status === "Accepted" ? true : false}
                      onChange={() => {
                        handleOpenAcceptModal(data);
                      }}
                    />
                  </ThemeProvider>
                </>
              )}

              {data.status === "Rejected" && (
                <>
                  <ThemeProvider theme={themeForRejected}>
                    <Switch
                      checked={data.status === "Accepted" ? true : false}
                      onChange={() => {
                        handleOpenAcceptModal(data);
                      }}
                    />
                  </ThemeProvider>
                </>
              )}
            </>
          ),
        };
        tempRequests.push(singledata);
      });
    }
    setState({ ...state, rows: tempRequests });
  }, [getClientRequestForManagerReducer]);

  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [dataForViewClient, setdataForViewClient] = useState({});

  const handleOpenAcceptModal = (data = {}) => {
    setdataForViewClient(data);
    setOpenAcceptModal(true);
  };

  const handleCloseAcceptModal = () => {
    setOpenAcceptModal(false);
  };

  const [isSuccessAcceptClient, setIsSuccessAcceptClient] = useState(false);
  const [isErrorAcceptClient, setIsErrorAcceptClient] = useState(false);

  useEffect(() => {
    if (isSuccessAcceptClient) {
      setTimeout(() => {
        setIsSuccessAcceptClient(false);
      }, 3000);
    }
  }, [isSuccessAcceptClient]);

  useEffect(() => {
    if (isErrorAcceptClient) {
      setTimeout(() => {
        setIsErrorAcceptClient(false);
      }, 3000);
    }
  }, [isErrorAcceptClient]);

  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [dataForAssignProfile, setDataForAssignProfile] = useState({});
  const [dataOfClient, setDataOfClient] = useState({});

  const handleOpenAssignModal = (data = {}) => {
    setDataOfClient(data);
    // setDataForAssignProfile(data);
    setOpenAssignModal(true);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
  };

  const [isSuccessAssignProfiles, setIsSuccessAssignProfiles] = useState(false);
  const [isErrorAssignProfiles, setIsErrorAssignProfiles] = useState(false);
  useEffect(() => {
    if (isSuccessAssignProfiles) {
      setTimeout(() => {
        setIsSuccessAssignProfiles(false);
      }, 3000);
    }
  }, [isSuccessAssignProfiles]);

  useEffect(() => {
    if (isErrorAssignProfiles) {
      setTimeout(() => {
        setIsErrorAssignProfiles(false);
      }, 3000);
    }
  }, [isErrorAssignProfiles]);

  const [profileOptions, setProfileOptions] = useState([]);
  useEffect(() => {
    let profileDataTemp = [];
    let profileData = profilesReducer.profiles.data
      ? profilesReducer.profiles.data
      : [];
    if (profilesReducer?.profiles.data) {
      profileData?.map((account) => {
        profileDataTemp.push({
          label: account.name,
          value: account.profileId,
        });
      });
    }
    setProfileOptions(profileDataTemp);
  }, [profilesReducer]);

  const [profileSelected, setProfileSelected] = useState("");
  const handleProfileChange = (selectedOption) => {
    setProfileSelected(selectedOption);
  };

  const initialValues = {
    requestId: "",
    comment: "",
    status: "",
  };

  const validationSchema = Yup.object({
    comment: Yup.string().required("Enter a comment"),
  });

  const onSubmitForAccept = async (values, { resetForm }) => {
    values.requestId = dataForViewClient.requestId;
    values.status = "Accepted";
    let response = await acceptAndRejectClient(values, jwtToken);
    if (response.status) {
      setIsSuccessAcceptClient(true);
      resetForm();
      getClientRequestForManager(
        accountManagerId,
        jwtToken,
        status, 
        date,
        pageNo,
        rowsPerPage
      );
    }
    if (response.status == false) {
      setIsErrorAcceptClient(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmitForAccept,
    validationSchema: validationSchema,
  });

  const [openRejectModal, setOpenRejectModal] = useState(false);

  const handleOpenRejectModal = (data = {}) => {
    setdataForViewClient(data);
    setOpenRejectModal(true);
  };

  const handleCloseRejectModal = () => {
    setOpenRejectModal(false);
  };

  const [isSuccessRejectedClient, setIsSuccessRejectedClient] = useState(false);
  const [isErrorRejectedClient, setIsErrorRejectedClient] = useState(false);
  useEffect(() => {
    if (isSuccessRejectedClient) {
      setTimeout(() => {
        setIsSuccessRejectedClient(false);
      }, 3000);
    }
  }, [isSuccessRejectedClient]);

  useEffect(() => {
    if (isErrorRejectedClient) {
      setTimeout(() => {
        setIsErrorAcceptClient(false);
      }, 3000);
    }
  }, [isErrorRejectedClient]);

  const [openViewJobDescriptionModal, setOpenViewJobDescriptionModal] =
    useState(false);

  const handleOpenViewJobDescriptionModal = (data = {}) => {
    setdataForViewClient(data);
    setOpenViewJobDescriptionModal(true);
  };

  const handleCloseViewJobDescriptionModal = () => {
    setOpenViewJobDescriptionModal(false);
  };

  const [openViewDocumentModal, setOpenViewDocumentModal] = useState(false);

  const handleOpenViewDocumentModal = (Data = {}) => {
    setOpenViewDocumentModal(true);
  };

  const handleCloseViewDocumentModal = () => {
    setOpenViewDocumentModal(false);
  };

  const [documentForProfile, setDocumentForProfile] = useState("");

  const [openViewDocumentForProfileModal, setOpenViewDocumentForProfileModal] =
    useState(false);

  const handleOpenViewDocumentForProfileModal = async (Data = {}) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });
    const url = await s3.getSignedUrlPromise("getObject", {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: Data.resume,
      Expires: 1800,
    });
    setDocumentForProfile(url);
    setOpenViewDocumentForProfileModal(true);
  };

  const handleCloseViewDocumentForProfileModal = () => {
    setOpenViewDocumentForProfileModal(false);
  };
  const initialValuesForReject = {
    requestId: "",
    comment: "",
    status: "",
  };

  const validationSchemaForReject = Yup.object({
    comment: Yup.string().required("Enter a comment"),
  });

  const onSubmitForReject = async (values, { resetForm }) => {
    values.requestId = dataForViewClient.requestId;
    values.status = "Rejected";
    let response = await acceptAndRejectClient(values, jwtToken);
    if (response.status === true) {
      setIsSuccessRejectedClient(true);
      resetForm();
      getClientRequestForManager(
        accountManagerId,
        jwtToken,
        status,
        date,
        pageNo,
        rowsPerPage
      );
    }
    if (response.status == false) {
      setIsErrorRejectedClient(true);
    }
  };

  const formikForReject = useFormik({
    initialValues: initialValuesForReject,
    onSubmit: onSubmitForReject,
    validationSchema: validationSchemaForReject,
  });

  const initialValuesForAssign = {
    profileData: [],
  };

  const validationSchemaForAssign = Yup.object({
    profileData: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string(),
        })
      )
      .required("Select profiles"),
  });

  let assignProfile = async () => {
    let values = {};
    let sortListData = [];
    selectedSortListData.map((data) => {
      const object = {
        profileId: data.profileId,
      };
      sortListData.push(object);
    });
    values.clientId = dataOfClient.clientId;
    values.requestId = dataOfClient.requestId;
    values.profileData = sortListData;
    let response = await assignProfiles(values, jwtToken);
    if (response.status === true) {
      setIsSuccessAssignProfiles(true);
      getProfiles(jwtToken);
      getClientRequestForManager(
        accountManagerId,
        jwtToken,
        status,
        date,
        pageNo,
        rowsPerPage
      );
    }
    if (response.status == false) {
      setIsErrorAssignProfiles(true);
    }
  };
  const onSubmitForAssign = async (values, { resetForm }) => {
    values.clientId = dataOfClient.clientId;
    values.requestId = dataOfClient.requestId;
    values.profileData = [];
    let response = await assignProfiles(values, jwtToken);
    if (response.status === true) {
      setIsSuccessAssignProfiles(true);
      getClientRequestForManager(
        accountManagerId,
        jwtToken,
        status,
        date,
        pageNo,
        rowsPerPage
      );
    }
    if (response.status == false) {
      setIsErrorAssignProfiles(true);
    }
  };

  const formikForAssign = useFormik({
    initialValues: initialValuesForAssign,
    onSubmit: onSubmitForAssign,
    // validationSchema: validationSchemaForAssign,
  });

  const [clientOptions, setClientOptions] = useState([]);

  useEffect(() => {
    let accountManagerDataTemp = [];
    let accountManagerData = profilesReducer.profiles.data
      ? profilesReducer.profiles.data
      : [];

    if (profilesReducer.profiles.length) {
      accountManagerData?.map((account) => {
        accountManagerDataTemp.push({
          name: account.name,
          id: account.profileId,
          technology: account.email,
        });
      });
    }
    setClientOptions(accountManagerData);
  }, [profilesReducer]);

  const [selectedSortListData, setselectedSortListData] = useState([]);
  const SortListsData = (data) => {
    setselectedSortListData(data);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!loading ? (
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Resource Requests
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDBox
                    style={{ display: "flex" }}
                    flexDirection={{
                      xs: "column",
                      sm: "column",
                      md: "row",
                      lg: "row",
                      xl: "row",
                    }}
                    mt={2}
                    ml={2}
                  >
                    <MDBox
                      style={{ width: "200px" }}
                      marginBottom={{
                        xs: "0px",
                        sm: "0px",
                        md: "0px",
                        lg: "0px",
                        xl: "0px",
                      }}
                    >
                      <Select
                        isClearable={true}
                        className="select-css"
                        placeholder="All Status"
                        options={options}
                        value={statusSelected}
                        onChange={(selectedOption) => {
                          changeStatus(selectedOption);
                        }}
                        autoComplete="off"
                      />{" "}
                    </MDBox>
                    <MDBox
                      style={{ 
                        width:"200px"
                      }}
                      marginLeft={{
                        xs: "0px",
                        sm: "0px",
                        md: "10px",
                        lg: "10px",
                        xl: "10px",
                      }}
                    >
                      <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Date"
                        maxDate={new Date()}
                        onChange={(date) => setStartDate(date)}
                        isClearable={true}
                        withPortal
                        selectsStart
                        selected={startDate}
                        startDate={startDate}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    color="Dark"
                    table={{ columns, rows }}
                    isSorted={true}
                    canSearch={true}
                    entriesPerPage={{
                      defaultValue: 5,
                      entries: [5, 10, 15, 20, 25, 30],
                    }}
                    showTotalEntries={true}
                    noEndBorder
                    serverSidePagination={true}
                    paginationObj={paginationObj}
                    pageNoValue={pageNo}
                    rowsPerPageValue={rowsPerPage}
                    pageNoProp={importPageNo}
                    rowsPerPageProp={importRowsPerPage}
                  />
                </MDBox>
              </Card>
            </Grid>
            <Grid>
              <Modal
                open={openAssignModal}
                onClose={handleCloseAssignModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForAssign}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Assign Profile
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseAssignModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDBox pt={1} pb={3} px={1}>
                    <MDBox>
                      <MDBox>
                        <MDCard>
                          <MDBox>
                            <MDBox
                              style={{
                                height: 450,
                                overflow: "scroll",
                              }}
                            >
                              <MDBox>
                                <DataTable
                                  table={{
                                    columns: itemColumns,
                                    rows: itemRows,
                                  }}
                                  isSorted={true}
                                  canSearch={true}
                                  selection={true}
                                  selectionButtonClick={SortListsData}
                                  entriesPerPage={{
                                    defaultValue: 5,
                                    entries: [5, 10, 15, 20, 25, 30],
                                  }}
                                  showTotalEntries={true}
                                  noEndBorder
                                />
                              </MDBox>
                            </MDBox>
                          </MDBox>

                          <MDBox>
                            <Collapse in={isErrorAssignProfiles}>
                              <Alert
                                style={{ marginTop: "10px" }}
                                severity="error"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsErrorAssignProfiles(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              > 
                                Please check the details. Server error!
                              </Alert>
                            </Collapse>
                            <Collapse in={isSuccessAssignProfiles}>
                              <Alert
                                style={{ marginTop: "10px" }}
                                severity="success"
                                action={
                                  <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setIsSuccessAssignProfiles(false);
                                    }}
                                  >
                                    <CloseIcon fontSize="inherit" />
                                  </IconButton>
                                }
                              >
                                Profile assigned successfully
                              </Alert>
                            </Collapse>
                          </MDBox>
                          <MDBox>
                            <MDButton
                              variant="contained"
                              style={{ background: "#7D3C98", color: "white" }}
                              fullWidth
                              type="submit"
                              onClick={assignProfile}
                              disabled={!selectedSortListData.length}
                            >
                              Assign
                            </MDButton> 
                          </MDBox>
                        </MDCard>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openAcceptModal}
                onClose={handleCloseAcceptModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForEdit}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Comment
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseAcceptModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDBox pt={1} pb={3} px={1}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formik.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography
                          mt={1}
                          variant="h6"
                          fontWeight="medium"
                          flexGrow={1}
                          style={{ fontWeight: "500" }}
                        >
                          Enter Comment
                        </MDTypography>
                        <MDInput
                          type="text"
                          name="comment"
                          multiline
                          rows={2}
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.comment}
                          error={
                            formik.touched.comment &&
                            formik.errors.comment &&
                            true
                          }
                        />
                        {formik.touched.comment && formik.errors.comment && (
                          <TextError msg={formik.errors.comment} />
                        )}
                      </MDBox>
                      <MDBox>
                        <Collapse in={isErrorAcceptClient}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorAcceptClient(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Please check the details. Server error!
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessAcceptClient}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessAcceptClient(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Client accepted successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={2}>
                        <MDButton
                          variant="contained"
                          color="success"
                          fullWidth
                          type="submit"
                          disabled={!formik.isValid}
                        >
                          Accept
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Grid>

            <Grid>
              <Modal
                open={openRejectModal}
                onClose={handleCloseRejectModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForEdit}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h5" fontWeight="medium" flexGrow={1}>
                      Comment
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseRejectModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDBox pt={1} pb={3} px={1}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formikForReject.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography
                          mt={1}
                          variant="h6"
                          fontWeight="medium"
                          flexGrow={1}
                          style={{ fontWeight: "500" }}
                        >
                          Enter Comment
                        </MDTypography>
                        <MDInput
                          type="text"
                          name="comment"
                          multiline
                          rows={2}
                          fullWidth
                          autoComplete="off"
                          onBlur={formikForReject.handleBlur}
                          onChange={formikForReject.handleChange}
                          value={formikForReject.values.comment}
                          error={
                            formikForReject.touched.comment &&
                            formikForReject.errors.comment &&
                            true
                          }
                        />
                        {formikForReject.touched.comment &&
                          formikForReject.errors.comment && (
                            <TextError msg={formikForReject.errors.comment} />
                          )}
                      </MDBox>
                      <MDBox>
                        <Collapse in={isErrorRejectedClient}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorRejectedClient(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Please check the details. Server error!
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessRejectedClient}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessRejectedClient(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Client rejected successfully
                          </Alert>
                        </Collapse>
                      </MDBox>

                      <MDBox mt={2}>
                        <MDButton
                          variant="contained"
                          color="error"
                          fullWidth
                          type="submit"
                          disabled={!formikForReject.isValid}
                        >
                          Reject
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
            </Grid>
            <MDBox>
              <Modal
                open={openViewJobDescriptionModal}
                onClose={handleCloseViewJobDescriptionModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={styleForView}>
                  <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                    <MDTypography
                      variant="h4"
                      fontWeight="medium"
                      flexGrow={1}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      Client Details
                    </MDTypography>
                    <MDBox>
                      <Tooltip title="Close">
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          onClick={handleCloseViewJobDescriptionModal}
                          style={{
                            background: "whitesmoke",
                            color: "black",
                            borderRadius: 5,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>

                  <hr />

                  <MDCard>
                    <MDBox mt={2}>
                      <div>
                        <Grid container spacing={1} ml={1}>
                          <Grid item xs={12}>
                            <MDBox display="flex">
                              <MDTypography
                                mr={1}
                                variant="h6"
                                style={{ fontSize: "15px" }}
                                fontWeight="medium"
                              >
                                Start Date :
                              </MDTypography>
                              <MDTypography
                                variant="text"
                                //  fontWeight="medium"
                                style={{ fontSize: "15px" }}
                              >
                                {dataForViewClient.startDate
                                  ? moment(dataForViewClient.startDate)
                                      .format("DD/MM/YYYY")
                                      .slice(0, 10)
                                  : ""}{" "}
                              </MDTypography>
                            </MDBox>
                          </Grid>

                          <Grid item xs={12}>
                            <MDBox display="flex">
                              <MDTypography
                                style={{ fontSize: "15px" }}
                                mr={1}
                                variant="h6"
                                fontWeight="medium"
                              >
                                Job Type :
                              </MDTypography>
                              <MDTypography
                                variant="text"
                                style={{ fontSize: "15px" }}
                              >
                                {dataForViewClient.jobType}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          {dataForViewClient.experience ? (
                            <>
                              <Grid item xs={12}>
                                <MDBox display="flex">
                                  <MDTypography
                                    mr={1}
                                    fontWeight="medium"
                                    variant="h6"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Experience :
                                  </MDTypography>
                                  <MDTypography
                                    variant="text"
                                    style={{ fontSize: "15px" }}
                                  >
                                    {dataForViewClient.experience +
                                      " " +
                                      "Year(s)"}
                                  </MDTypography>
                                </MDBox>
                              </Grid>
                            </>
                          ) : null}

                          {dataForViewClient.location ? (
                            <>
                              <Grid item xs={12}>
                                <MDBox display="flex">
                                  <MDTypography
                                    mr={1}
                                    fontWeight="medium"
                                    variant="h6"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Location :
                                  </MDTypography>
                                  <MDTypography
                                    variant="text"
                                    style={{ fontSize: "15px" }}
                                  >
                                    {dataForViewClient.location}
                                  </MDTypography>
                                </MDBox>
                              </Grid>
                            </>
                          ) : null}
                          {dataForViewClient.comment ? (
                            <>
                              <Grid item xs={12}>
                                <MDBox display="flex">
                                  <MDTypography
                                    mr={1}
                                    fontWeight="medium"
                                    variant="h6"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Comment :
                                  </MDTypography>
                                  <MDTypography
                                    variant="text"
                                    style={{ fontSize: "15px" }}
                                  >
                                    {dataForViewClient.comment}
                                  </MDTypography>
                                </MDBox>
                              </Grid>
                            </>
                          ) : null}
                          <Grid item xs={12}>
                            <MDBox display="flex">
                              <MDTypography
                                mr={1}
                                variant="h6"
                                style={{ fontSize: "15px" }}
                                fontWeight="medium"
                              >
                                Contract Period :
                              </MDTypography>
                              <MDTypography
                                variant="text"
                                style={{ fontSize: "15px" }}
                              >
                                {dataForViewClient.contractPeriod}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                          <Grid item xs={12}>
                            <MDBox mb={3} mr={2}>
                              <MDTypography
                                mr={1}
                                variant="h6"
                                fontWeight="medium"
                                style={{ fontSize: "15px" }}
                              >
                                Job Description :
                              </MDTypography>
                              <MDInput
                                inputProps={{ readOnly: true }}
                                multiline
                                rows={5}
                                fullWidth
                                value={dataForViewClient.jobDescription}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                      </div>
                    </MDBox>
                  </MDCard>
                </MDCard>
              </Modal>
              <MDBox>
                <Modal
                  open={openViewDocumentModal}
                  onClose={handleCloseViewDocumentModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                > 
                  <MDCard sx={styleForDocument}>
                    <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h5"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        View Document
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseViewDocumentModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>

                    <hr />

                    <MDBox pt={1} pb={3} px={1} height="100%">
                      <iframe
                        style={{
                          height: "100%",
                          width: "100%",
                          overflow: "auto",
                        }}
                        src={document}
                      ></iframe>
                    </MDBox>
                  </MDCard>
                </Modal>
              </MDBox>
              <MDBox>
                <Modal
                  open={openViewDocumentForProfileModal}
                  onClose={handleCloseViewDocumentForProfileModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                > 
                  <MDCard sx={styleForDocument}>
                    <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h5" 
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        View Document
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseViewDocumentForProfileModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>

                    <hr />

                    <MDBox pt={1} pb={3} px={1} height="100%">
                      <iframe
                        style={{
                          height: "100%",
                          width: "100%",
                          overflow: "auto",
                        }}
                        src={documentForProfile}
                      ></iframe>
                    </MDBox>
                  </MDCard>
                </Modal>
              </MDBox>
            </MDBox>
          </Grid>
        </MDBox>
      ) : (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!loading ? <p className=" font-size text-center text-md-left">
          Developed and maintained by :
          <a className="link" href="http://mobillor.com/">
            <strong style={{ color: "red" }}>
              {" "}
              Mobillor Technologies Pvt. Ltd.
            </strong>
          </a>
        </p> : null}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    getClientRequestForManagerReducer: state.getClientRequestForManagerReducer,
    profilesReducer: state.profilesReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getClientRequestForManager: (
      accountManagerId,
      jwtToken,
      status,
      date,
      pageNo,
      rowsPerPage
    ) =>
      dispatch(
        getClientRequestForManager(
          accountManagerId,
          jwtToken,
          status,
          date,
          pageNo,
          rowsPerPage
        )
      ),
    acceptAndRejectClient: (payload, jwtToken) =>
      dispatch(acceptAndRejectClient(payload, jwtToken)),
    getProfiles: (jwtToken) => dispatch(getProfiles(jwtToken)),
    assignProfiles: (payload, jwtToken) =>
      dispatch(assignProfiles(payload, jwtToken)),
  };
};

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(ClientRequestForManager);












