import {
  PUT_CLIENTDETAILS_REQUEST,
  PUT_CLIENTDETAILS_SUCCESS,
  PUT_CLIENTDETAILS_FAILURE,
  GET_PREVIEW_SUCCESS,
  GET_CLIENT_REQUESTS_FOR_CLIENT_REQUEST,
  GET_CLIENT_REQUESTS_FOR_CLIENT_SUCCESS,
  GET_CLIENT_REQUESTS_FOR_CLIENT_FAILURE,
  GET_LIST_OF_PROFILES_REQUEST,
  GET_LIST_OF_PROFILES_SUCCESS,
  GET_LIST_OF_PROFILES_FAILURE,
  GET_LIST_OF_SHORTLISTED_PROFILES_REQUEST,
  GET_LIST_OF_SHORTLISTED_PROFILES_SUCCESS,
  GET_LIST_OF_SHORTLISTED_PROFILES_FAILURE
} from "./clientDetailsTypes";

const initialStateForVendorDetails = {
  loading: false,
  submitClientDetails: {},
  error: "",
};

const clientDetailsReducer = (state = initialStateForVendorDetails, action) => {
  switch (action.type) {
    case PUT_CLIENTDETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUT_CLIENTDETAILS_SUCCESS:
      return {
        loading: false,
        submitClientDetails: action.payload,
        error: "",
      };
    case PUT_CLIENTDETAILS_FAILURE:
      return {
        loading: false,
        submitClientDetails: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForListOfProfiles = {
  loading: false,
  listOfProfiles: {},
  error: "",
};

const listOfProfilesReducer = (
  state = initialStateForListOfProfiles,
  action
) => {
  switch (action.type) {
    case GET_LIST_OF_PROFILES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_OF_PROFILES_SUCCESS:
      return {
        loading: false,
        listOfProfiles: action.payload,
        error: "",
      };
    case GET_LIST_OF_PROFILES_FAILURE:
      return {
        loading: false,
        listOfProfiles: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForListOfShortlistedProfiles = {
  loading: false,
  listOfShortlistedProfiles: {},
  error: "",
};

const listOfShortlistedProfilesReducer = (state = initialStateForListOfShortlistedProfiles, action) => {
  switch (action.type) {
    case GET_LIST_OF_SHORTLISTED_PROFILES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_OF_SHORTLISTED_PROFILES_SUCCESS:
      return {
        loading: false,
        listOfShortlistedProfiles: action.payload,
        error: "",
      };
    case GET_LIST_OF_SHORTLISTED_PROFILES_FAILURE:
      return {
        loading: false,
        listOfShortlistedProfiles: {},
        error: action.payload,
      };
    default:
      return state;
  }
};


const initialStateForPreview = {
  loading: false,
  preview: {},
  error: "",
};

const getPreviewReducer = (state = initialStateForPreview, action) => {
  switch (action.type) {
    case GET_PREVIEW_SUCCESS:
      return {
        loading: false,
        preview: action.payload,
        error: "",
      };

    default:
      return state;
  }
};

const initialState = {
  loading: false,
  clientsData: {},
  error: "",
};

const getClientRequestForClientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENT_REQUESTS_FOR_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENT_REQUESTS_FOR_CLIENT_SUCCESS:
      return {
        loading: false,
        clientsData: action.payload,
        error: "",
      };
    case GET_CLIENT_REQUESTS_FOR_CLIENT_FAILURE:
      return {
        loading: false,
        clientsData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  clientDetailsReducer,
  getPreviewReducer,
  getClientRequestForClientsReducer,
  listOfProfilesReducer,
  listOfShortlistedProfilesReducer
};
