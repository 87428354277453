// /*********************
//  * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
//  * __________________
//  *
//  *  Mobillor Technologies Pvt. Ltd.
//  *  All Rights Reserved.
//  *
//  * NOTICE:  All information contained herein is, and remains
//  * the property of Mobillor Technologies Pvt. Ltd.
//  * The intellectual and technical concepts contained
//  * herein are proprietary to Mobillor Technologies Pvt. Ltd.
//  * may be covered by Rebublic Of India and Foreign Patents,
//  * patents in process, and are protected by trade secret or copyright law.
//  * Dissemination of this information or reproduction of this material
//  * is strictly forbidden unless prior written permission is obtained
//  * from Mobillor Technologies Pvt. Ltd.
//  */

import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import BlockIcon from "@mui/icons-material/Block";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import TextError from "utils/TextError";

// Functions from store
import {
  getAllClient,
  deleteClient,
  getClientById,
  blockClient,
} from "../../store";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const Clients = ({
  getAllClientsReducer,
  getAllClient,
  deleteClient,
  getClientById,
  getClientByIdReducer,
  blockClient,
}) => {
  const deleteStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 400, lg: 330, xl: 330 },
    boxShadow: 24,
    p: 2,
    pt: 3,
  };
  const styleForView = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 600, lg: 900, xl: 900 },
    boxShadow: 24,
    p: 2,
    pt: 3,
  };

  let data = cookies.get("mobillor-talent-accelerator-user-data");
  let jwtToken = data?.data?.jwtToken;

  const [clientId, setClientId] = useState("");

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearch(globalFilter);
  };

  useEffect(() => {
    getAllClient(jwtToken, pageNo, rowsPerPage);
  }, [getAllClient, pageNo, rowsPerPage]);

  useEffect(() => {
    getClientById(jwtToken, clientId);
  }, [clientId, getClientById]);

  let loadingForView = getClientByIdReducer?.loading;
  let clientData;
  useEffect(() => {
    clientData = getClientByIdReducer?.clientsData
      ? getClientByIdReducer?.clientsData
      : "";
  }, [getClientByIdReducer]);

  let clientsData = getClientByIdReducer?.clientsData
    ? getClientByIdReducer?.clientsData
    : "";

  const [state, setState] = useState({
    columns: [
      { Header: "Company Name", accessor: "companyName"},
      { Header: "Contact Person", accessor: "contactPerson" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Contact Number", accessor: "contactNo", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: [],
  });
  const { columns, rows } = state;
  const [paginationObj, setPaginationObj] = useState({});
  let loading = getAllClientsReducer.loading;
  useEffect(() => {
    let tempClients = [];
    let data = getAllClientsReducer?.clientsData?.data
      ? getAllClientsReducer?.clientsData?.data
      : [];

    if (getAllClientsReducer?.clientsData?.data?.length) {
      setPaginationObj(getAllClientsReducer?.clientsData?.pagination);
      data?.map((data) => {
        const singledata = {
          email: data.email,
          companyName: data.companyName,
          clientName: data.userName,
          contactNo: data.contactDetails?.contactNo ,
          contactPerson:data.contactPerson ,
          action: (
            <>
              <span style={{ display: "flex" }}>
                <Tooltip title="Client Details">
                  <MDButton
                    ml={5}
                    variant="gradient"
                    color="info"
                    iconOnly
                    type="button"
                    onClick={() => {
                      handleopenViewClientModal(data);
                    }}
                  >
                    <Icon> visibility_icon </Icon>
                  </MDButton>
                </Tooltip>

                {data.isBlocked === true ? (
                  <Tooltip title="Disabled">
                    <MDButton
                      type="button"
                      variant="gradient"
                      style={{
                        background: "grey",
                        color: "white",
                        opacity: 0.5,
                        marginLeft: "10px",
                      }}
                      disabled
                      iconOnly
                    >
                      <BlockIcon />
                    </MDButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Block Client">
                    <MDButton
                      style={{ marginLeft: "10px" }}
                      variant="gradient"
                      color="warning"
                      iconOnly
                      type="button"
                      onClick={() => handleOpenBlockClientModal(data)}
                    >
                      <BlockIcon />
                    </MDButton>
                  </Tooltip>
                )}

                <Tooltip title="Delete Client">
                  <MDButton
                    style={{ marginLeft: "10px" }}
                    variant="gradient"
                    color="error"
                    iconOnly
                    type="button"
                    onClick={() => handleOpenDeleteClientModal(data)}
                  >
                    <DeleteIcon />
                  </MDButton>
                </Tooltip>
              </span>
            </>
          ),
        };
        tempClients.push(singledata);
      });
    }
    setState({ ...state, rows: tempClients });
  }, [getAllClientsReducer]);

  //  DELETE CLIENT

  const [openDeleteClientModal, setOpenDeleteClientModal] = useState(false);
  const [singleClientDataForDelete, setClientDataForDelete] = useState({});

  const handleOpenDeleteClientModal = (department = {}) => {
    setClientDataForDelete(department);
    setOpenDeleteClientModal(true);
  };
  const handleCloseDeleteClientModal = () => {
    setOpenDeleteClientModal(false);
  };
  const [isErrorDeleteClient, setIsErrorDeleteClient] = useState(false);

  useEffect(() => {
    if (isErrorDeleteClient) {
      setTimeout(() => {
        setIsErrorDeleteClient(false);
      }, 3000);
    }
  }, [isErrorDeleteClient]);

  const deleteClientData = async () => {
    let clientId = singleClientDataForDelete.clientId;
    let response = await deleteClient(jwtToken, clientId);
    if (response.status) {
      getAllClient(jwtToken, pageNo, rowsPerPage);
      handleCloseDeleteClientModal();
    }
    if (!response.status) {
      setIsErrorDeleteClient(true);
    }
  };
  //  Block client
  const [openBlockClientModal, setOpenBlockClientModal] = useState(false);
  const [clientDataForBlock, setClientDataForBlock] = useState({});

  const handleOpenBlockClientModal = (department = {}) => {
    setClientDataForBlock(department);
    setOpenBlockClientModal(true);
  };
  const handleCloseBlockClientModal = () => {
    setOpenBlockClientModal(false);
  };
  const [isErrorBlockClient, setIsErrorBlockClient] = useState(false);

  useEffect(() => {
    if (isErrorBlockClient) {
      setTimeout(() => {
        setIsErrorBlockClient(false);
      }, 3000);
    }
  }, [isErrorBlockClient]);

  const blockClientData = async () => {
    let clientId = clientDataForBlock.clientId;
    let response = await blockClient(jwtToken, clientId);
    if (response.status) {
      getAllClient(jwtToken, pageNo, rowsPerPage);
      handleCloseBlockClientModal();
    }
    if (!response.status) {
      setIsErrorBlockClient(true);
    }
  };
  //  VIEW JOB DESCRIPTION MODEL
  const [openViewClientModal, setopenViewClientModal] = useState(false);

  const handleopenViewClientModal = (data = {}) => {
    setClientId(data.clientId);
    setopenViewClientModal(true);
  };

  const handleCloseViewClientModal = () => {
    setopenViewClientModal(false);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {!loading ? (
          <>
            <MDBox pt={2} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={3}
                      mb={-6}
                    >
                      <MDBox>
                        <MDTypography variant="h4" gutterBottom>
                          Clients
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox pt={3}>
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={true}
                        canSearch={true}
                        entriesPerPage={{
                          defaultValue: 5,
                          entries: [5, 10, 15, 20, 25, 30],
                        }}
                        showTotalEntries={true}
                        noEndBorder
                        serverSidePagination={true}
                        paginationObj={paginationObj}
                        pageNoValue={pageNo}
                        rowsPerPageValue={rowsPerPage}
                        pageNoProp={importPageNo}
                        rowsPerPageProp={importRowsPerPage}
                      />
                    </MDBox>
                  </Card>
                </Grid>
                <Grid>
                  <Modal
                    open={openDeleteClientModal}
                    onClose={handleCloseDeleteClientModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <MDCard sx={deleteStyle}>
                      <MDTypography>
                        Are you sure you want to delete?
                      </MDTypography>
                      <MDBox mt={2}>
                        <div style={{ display: "flex" }}>
                          <div>
                            <MDButton
                              style={{ width: "100%", marginRight: "0.5rem" }}
                              color="info"
                              variant="outlined"
                              onClick={handleCloseDeleteClientModal}
                            >
                              Cancel
                            </MDButton>
                          </div>
                          <div>
                            <MDButton
                              style={{ width: "100%", marginLeft: "0.5rem" }}
                              color="error"
                              onClick={deleteClientData}
                            >
                              Delete
                            </MDButton>
                          </div>
                        </div>
                      </MDBox>
                      <MDBox mt={2}>
                        <Collapse in={isErrorDeleteClient}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorDeleteClient(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Please check the details. Server error!
                          </Alert>
                        </Collapse>
                      </MDBox>
                    </MDCard>
                  </Modal>
                </Grid>
                <Grid>
                  <Modal
                    open={openBlockClientModal}
                    onClose={handleCloseBlockClientModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <MDCard sx={deleteStyle}>
                      <MDTypography>
                        Are you sure you want to Block?
                      </MDTypography>
                      <MDBox mt={2}>
                        <div style={{ display: "flex" }}>
                          <div>
                            <MDButton
                              style={{ width: "100%", marginRight: "0.5rem" }}
                              color="info"
                              variant="outlined"
                              onClick={handleCloseBlockClientModal}
                            >
                              Cancel
                            </MDButton>
                          </div>
                          <div>
                            <MDButton
                              style={{ width: "100%", marginLeft: "0.5rem" }}
                              color="warning"
                              onClick={blockClientData}
                            >
                              Block
                            </MDButton>
                          </div>
                        </div>
                      </MDBox>
                      <MDBox mt={2}>
                        <Collapse in={isErrorBlockClient}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorBlockClient(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Please check the details. Server error!
                          </Alert>
                        </Collapse>
                      </MDBox>
                    </MDCard>
                  </Modal>
                </Grid>
                <Grid>
                  <Modal
                    open={openViewClientModal}
                    onClose={handleCloseViewClientModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <MDCard sx={styleForView}>
                      <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                        <MDTypography
                          variant="h4"
                          fontWeight="medium"
                          flexGrow={1}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          Client Details
                        </MDTypography>
                        <MDBox>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={handleCloseViewClientModal}
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                      </MDBox>

                      <hr />

                      <MDCard>
                        <MDBox mt={2}>
                          <div>
                            <MDCard>
                              <MDBox mt={2}>
                                <div>
                                  <MDCard>
                                    {loadingForView && (
                                      <Backdrop
                                        sx={{
                                          color: "blue",
                                          zIndex: (theme) =>
                                            theme.zIndex.drawer + 1,
                                        }}
                                        open
                                      >
                                        <CircularProgress color="inherit" />
                                      </Backdrop>
                                    )}
                                    <MDCard
                                      style={{
                                        border: "1px solid gray",
                                        padding: "20px",
                                      }}
                                    >
                                      <MDBox>
                                        <MDTypography
                                          variant="h5"
                                          style={{
                                            color: "gray",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {" "}
                                          Business Partner Details{" "}
                                        </MDTypography>
                                      </MDBox>
                                      <Grid container spacing={1} ml={1}>
                                        <Grid item xs={6} xl={4} lg={4}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              variant="h4"
                                              style={{ fontSize: "15px" }}
                                            >
                                              Line of Business :
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {clientsData[0]?.lineOfBusiness}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={6} xl={4} lg={4}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              style={{ fontSize: "15px" }}
                                              mr={1}
                                              variant="h6"
                                              fontWeight="medium"
                                            >
                                              Currency :
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {clientsData[0]?.currency}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={6} xl={4} lg={4}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              style={{ fontSize: "15px" }}
                                              mr={1}
                                              variant="h6"
                                              fontWeight="medium"
                                            >
                                              Company Name :
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {" "}
                                              {clientsData[0]?.supplierName}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>

                                        <Grid item xs={6} xl={4} lg={4}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              mr={1}
                                              fontWeight="medium"
                                              variant="h6"
                                              style={{ fontSize: "15px" }}
                                            >
                                              Building Number/Name :
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {
                                                clientsData[0]?.address
                                                  ?.buildingNoName
                                              }
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>

                                        <Grid item xs={6} xl={4} lg={4}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              mr={1}
                                              variant="h6"
                                              style={{ fontSize: "15px" }}
                                              fontWeight="medium"
                                            >
                                              Floor :
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {" "}
                                              {clientsData[0]?.address?.floor}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>

                                        <Grid item xs={6} xl={4} lg={4}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              mr={1}
                                              variant="h6"
                                              style={{ fontSize: "15px" }}
                                              fontWeight="medium"
                                            >
                                              Street :
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {" "}
                                              {clientsData[0]?.address?.street}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>

                                        <Grid item xs={6} xl={4} lg={4}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              mr={1}
                                              variant="h6"
                                              style={{ fontSize: "15px" }}
                                              fontWeight="medium"
                                            >
                                              Country :
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {" "}
                                              {clientsData[0]?.address?.country}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>

                                        <Grid item xs={6} xl={4} lg={4}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              mr={1}
                                              variant="h6"
                                              style={{ fontSize: "15px" }}
                                              fontWeight="medium"
                                            >
                                              State :
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {" "}
                                              {clientsData[0]?.address?.state}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>

                                        <Grid item xs={6} xl={4} lg={4}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              mr={1}
                                              variant="h6"
                                              style={{ fontSize: "15px" }}
                                              fontWeight="medium"
                                            >
                                              City :
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {clientsData[0]?.address?.city}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>

                                        <Grid item xs={6} xl={4} lg={4}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              mr={1}
                                              variant="h6"
                                              style={{ fontSize: "15px" }}
                                              fontWeight="medium"
                                            >
                                              Pincode :
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {clientsData[0]?.address?.pincode}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>
                                        <Grid item xs={6} xl={4} lg={4}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              mr={1}
                                              variant="h6"
                                              style={{ fontSize: "15px" }}
                                              fontWeight="medium"
                                            >
                                              Contact Person :
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {" "}
                                              {
                                                clientsData[0]?.contactPerson
                                              }{" "}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>

                                        <Grid item xs={6} xl={4} lg={4}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              mr={1}
                                              variant="h6"
                                              style={{ fontSize: "15px" }}
                                              fontWeight="medium"
                                            >
                                              Contact Number :
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {" "}
                                              {clientsData[0]?.contactDetails
                                                ?.countryCode +
                                                "-" +
                                                clientsData[0]?.contactDetails
                                                  ?.contactNo}{" "}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>

                                        <Grid item xs={6} xl={4} lg={4}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              mr={1}
                                              variant="h6"
                                              style={{ fontSize: "15px" }}
                                              fontWeight="medium"
                                            >
                                              Email:
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {" "}
                                              {clientsData[0]?.email}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>

                                        {clientsData.industrialType &&
                                        clientsData.industrialType?.length >
                                          0 ? (
                                          <Grid item xs={6} xl={4} lg={4}>
                                            <MDBox mb={2}>
                                              <MDTypography
                                                mr={1}
                                                variant="h6"
                                                style={{ fontSize: "15px" }}
                                                fontWeight="medium"
                                              >
                                                Industrial Type :
                                              </MDTypography>
                                              <MDInput
                                                inputProps={{ readOnly: true }}
                                                fullWidth
                                                value={
                                                  clientsData[0]?.industrialType
                                                }
                                              />
                                            </MDBox>
                                          </Grid>
                                        ) : null}
                                      </Grid>
                                    </MDCard>

                                    <MDCard
                                      style={{
                                        marginTop: "10px",
                                        border: "1px solid gray",
                                        padding: "20px",
                                        // overflow: "scroll",
                                      }}
                                    >
                                      <MDTypography
                                        variant="h5"
                                        style={{
                                          color: "gray",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Statutory Information
                                      </MDTypography>

                                      <Grid container spacing={1} ml={1}>
                                        <Grid item xs={6} xl={6} lg={6}>
                                          <MDBox display="flex">
                                            <MDTypography
                                              mr={1}
                                              variant="h6"
                                              style={{ fontSize: "15px" }}
                                              fontWeight="medium"
                                            >
                                              PAN/TAX Number :
                                            </MDTypography>
                                            <MDTypography
                                              variant="text"
                                              style={{ fontSize: "15px" }}
                                            >
                                              {" "}
                                              {clientsData[0]?.pan}
                                            </MDTypography>
                                          </MDBox>
                                        </Grid>
                                        {clientsData[0]?.gstNo && (
                                          <Grid item xs={6} xl={6} lg={6}>
                                            <MDBox display="flex">
                                              <MDTypography
                                                mr={1}
                                                variant="h6"
                                                style={{ fontSize: "15px" }}
                                                fontWeight="medium"
                                              >
                                                GST Number
                                              </MDTypography>
                                              <MDTypography
                                                variant="text"
                                                style={{ fontSize: "15px" }}
                                              >
                                                {" "}
                                                {clientsData[0]?.gstNo}{" "}
                                              </MDTypography>
                                            </MDBox>
                                          </Grid>
                                        )}

                                        {clientsData[0]?.paymentTerms && (
                                          <Grid item xs={6} xl={6} lg={6}>
                                            <MDBox display="flex">
                                              <MDTypography
                                                mr={1}
                                                variant="h6"
                                                style={{ fontSize: "15px" }}
                                                fontWeight="medium"
                                              >
                                                Payment Terms
                                              </MDTypography>
                                              <MDTypography
                                                variant="text"
                                                style={{ fontSize: "15px" }}
                                              >
                                                {" "}
                                                {clientsData[0]?.paymentTerms}
                                              </MDTypography>
                                            </MDBox>
                                          </Grid>
                                        )}

                                        {clientsData[0]?.cinNumber && (
                                          <Grid item xs={6} xl={6} lg={6}>
                                            <MDBox display="flex">
                                              <MDTypography
                                                mr={1}
                                                variant="h6"
                                                style={{ fontSize: "15px" }}
                                                fontWeight="medium"
                                              >
                                                Company Registration Number
                                              </MDTypography>
                                              <MDTypography
                                                variant="text"
                                                style={{ fontSize: "15px" }}
                                              >
                                                {" "}
                                                {clientsData[0]?.cinNumber}
                                              </MDTypography>
                                            </MDBox>
                                          </Grid>
                                        )}
                                      </Grid>
                                    </MDCard>
                                  </MDCard>
                                </div>
                              </MDBox>
                            </MDCard>
                          </div>
                        </MDBox>
                      </MDCard>
                    </MDCard>
                  </Modal>
                </Grid>
              </Grid>
            </MDBox>
          </>
        ) : (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        {!loading ? <p className=" font-size text-center text-md-left">
          Developed and maintained by :
          <a className="link" href="http://mobillor.com/">
            <strong style={{ color: "red" }}>
              {" "}
              Mobillor Technologies Pvt. Ltd.
            </strong>
          </a>
        </p> : null}
      </DashboardLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getAllClientsReducer: state.getAllClientsReducer,
    getClientByIdReducer: state.getClientByIdReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    deleteClient: (jwtToken, clientId) =>
      dispatch(deleteClient(jwtToken, clientId)),
    blockClient: (jwtToken, clientId) =>
      dispatch(blockClient(jwtToken, clientId)),
    getAllClient: (jwtToken, pageNo, rowsPerPage) =>
      dispatch(getAllClient(jwtToken, pageNo, rowsPerPage)),
    getClientById: (jwtToken, clientId) =>
      dispatch(getClientById(jwtToken, clientId)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Clients);
