/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import banner from "../../../assets/banner.jpg";

// Functions from store
import {
  login,
  forgotPassword,
  validateOtpForForgotPassword,
} from "../../../store";

// Config file
import Config from "../../../config/index";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

function Login({
  isAuth,
  isAuthCall,
  forgotPassword,
  forgotPasswordData,
  validateOtpForForgotPassword,
  validateOtpForForgotPasswordReducer,
}) {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [isErrorForgotPassword, setIsErrorForgotPassword] = useState(false);
  const [isSuccessForgotPassword, setIsSuccessForgotPassword] = useState(false);
  const [emailSend, setEmailSend] = useState(false);

  const [type, setType] = useState("login");
  let userDetails = cookies.get("forgot-password-mobillor-talent-accelerator");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const errorStyling = {
    color: "red",
    width: "16rem",
    fontSize: "14px",
    fontStyle: "italic",
  };

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  }, [isError]);

  useEffect(() => {
    if (isErrorForgotPassword) {
      setTimeout(() => {
        setIsErrorForgotPassword(false);
      }, 3000);
    }
  }, [isErrorForgotPassword]);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the Email"),
    password: Yup.string().required("Enter the Password"),
  });

  const [loading, setLoading] = useState(false);
  const onSubmit = async (values) => {
    let response = await isAuthCall(values);


    if (!response.status) {
      setIsError(true);
    }
    if (response.status && response.data.data.isLastLogin === true) {
      setLoading(true);
      window.sessionStorage.setItem("activeSession", true);
      cookies.set(
        "mobillor-talent-accelerator-user-data",
        JSON.stringify(response),
        {
          path: "/",
        }
      );

      setTimeout(() => {
        setLoading(false);
        navigate("/");
      }, 1000);
    }

    if (response.data.status && response.data.data.isLastLogin === false) {
      cookies.set("mobillor-talent-accelerator-user-data", JSON.stringify(response), {
        path: "/",
      });

      navigate("/authentication/set-password");
    }
  };

  const intervalRef = useRef(null);
  const [timer, setTimer] = useState("00:00");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return { total, days, hours, minutes, seconds };
  }

  function startTimer(deadline) {
    let { total, minutes, seconds } = getTimeRemaining(deadline);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      clearInterval(intervalRef.current);
    }
  }

  function clearTimer(endtime) {
    setTimer("02:00");
    if (intervalRef.current) clearInterval(intervalRef.current);
    const id = setInterval(() => {
      startTimer(endtime);
    }, 1000);
    intervalRef.current = id;
  }
  function getDeadlineTime() {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  }

  const initialValuesForForgotPassword = {
    email: "",
  };

  const validationSchemaForForgotPassword = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the Email"),
  });

  const onSubmitForForgotPassword = async (values) => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    clearTimer(getDeadlineTime());
    setBtnDisabled(true);
    setShowMessage(true);
    let response = await forgotPassword(values);
    if (response.status) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        setEmailSend(true);
        cookies.set(
          "forgot-password-mobillor-talent-accelerator",
          JSON.stringify(response.data),
          { path: "/" }
        );
      }, [500])

    }
  };

  const initialValuesForOtpSubmit = {
    email: userDetails ? userDetails.data.email : "",
    otp: null,
    hash: userDetails ? userDetails.data.hash : "",
  };

  const validationSchemaForOtpSubmit = Yup.object({
    otp: Yup.number().required("Enter the OTP"),
  });

  const onSubmitForOtpSubmit = async (values) => {
    let payload = {
      email: userDetails ? userDetails.data.email : "",
      otp: values.otp,
      hash: userDetails ? userDetails.data.hash : "",
    };

    let response = await validateOtpForForgotPassword(payload);

    if (response.status) {
      navigate("/set-new-password");
    }
  };

  const resendOtp = async () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    clearTimer(getDeadlineTime());
    setBtnDisabled(true);
    setShowMessage(true);

    let payload = {
      email: userDetails ? userDetails.data.email : "",
    };

    let response = await forgotPassword(payload);

    if (response.status) {
      setEmailSend(true);
      cookies.set(
        "forgot-password-mobillor-talent-accelerator",
        JSON.stringify(response.data),
        { path: "/" }
      );

      // navigate("/set-new-password");
    }
  };

  const forgotButtonHandler = () => {
    setType("forgotpassword");
  };

  const backButtonHandler = () => {
    setType("login");
  };

  const handleRegisterPage = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      navigate("/registration")
    }, [500])

  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const formikForForgotPassword = useFormik({
    initialValues: initialValuesForForgotPassword,
    onSubmit: onSubmitForForgotPassword,
    validationSchema: validationSchemaForForgotPassword,
  });

  const formikForOtpSubmit = useFormik({
    initialValues: initialValuesForOtpSubmit,
    onSubmit: onSubmitForOtpSubmit,
    validationSchema: validationSchemaForOtpSubmit,
  });

  return (
    <BasicLayout image={banner} topbutton="Register as a client" pagelink="/">
      <Card style={{ marginTop: "80px", width:"310px",marginLeft:"auto", marginRight:"auto" }}>
        <MDBox
          variant="gradient"
          style={{ backgroundColor: "red" }}
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h5" fontWeight="medium" color="white" mt={0}>
            {type === "login" ? "Sign In To Mobillor Talent Accelerator" : "Forgot Password"}
          </MDTypography>
        </MDBox>

        {!emailSend ? (
          <MDBox pt={0} pb={0} px={0}>
            {type === "forgotpassword" && (
              <MDBox
                component="form"
                role="form"
                onSubmit={formikForForgotPassword.handleSubmit}
              >
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    fullWidth
                    name="email"
                    onChange={formikForForgotPassword.handleChange}
                    onBlur={formikForForgotPassword.handleBlur}
                    value={formikForForgotPassword.values.email}
                    error={
                      formikForForgotPassword.touched.email &&
                      formikForForgotPassword.errors.email &&
                      true
                    }
                  />
                  {formikForForgotPassword.touched.email &&
                    formikForForgotPassword.errors.email && (
                      <TextError msg={formikForForgotPassword.errors.email} />
                    )}
                </MDBox>
                <Collapse in={isErrorForgotPassword}>
                  <Alert
                    style={errorStyling}
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsErrorForgotPassword(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Something went wrong!
                  </Alert>
                </Collapse>
                <Collapse in={isSuccessForgotPassword}>
                  <Alert
                    style={errorStyling}
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsSuccessForgotPassword(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Please check your E-mail!
                  </Alert>
                </Collapse>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="error"
                    onClick={backButtonHandler}
                  >
                    back
                  </MDButton>
                  <MDButton
                    style={{ backgroundColor: "red", float: "right" }}
                    // variant="gradient"
                    color="info"
                    type="submit"
                    disabled={!formikForForgotPassword.isValid}
                  >
                    submit
                  </MDButton>
                </MDBox>
              </MDBox>
            )}
            {type === "login" && (
              <MDBox
                component="form"
                role="form"
                onSubmit={formik.handleSubmit}
              >
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    fullWidth
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email && formik.errors.email && true}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <TextError msg={formik.errors.email} />
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    label="Password"
                    type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                    fullWidth
                    defaultValue={showPassword && "white"}
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    error={
                      formik.touched.password && formik.errors.password && true
                    }
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ marginRight: 10 }}
                        >
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility style={{width: 20}} /> : <VisibilityOff style={{width: 20}} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <TextError msg={formik.errors.password} />
                  )}
                </MDBox>
                <Collapse in={isError}>
                  <Alert
                    style={errorStyling}
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsError(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Invalid E-Mail/Password
                  </Alert>
                </Collapse>

                <MDBox ml={-1}>
                  <MDTypography
                    variant="button"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1, fontSize: "12px" }}
                    color="info"
                    fontWeight="medium"
                    textGradient
                    onClick={forgotButtonHandler}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Forgot Password?
                  </MDTypography>
                 
                  {/* <MDTypography
                   
                   
                    sx={{ cursor: "pointer", float: "right", fontSize: "14px", marginTop:"8px"  }}
                    color="error"
                    fontWeight="medium"
                    textGradient
                    onClick={handleRegisterPage}
                  >
                    Register as a client
                  </MDTypography> */}
                  
                </MDBox>
                <MDBox mt={2} mb={0} >
                  
                  <MDButton
                    style={{ backgroundColor: "red", float: "right" }}
                    // variant="gradient"
                    color="info"
                    type="submit"
                    disabled={!formik.isValid}
                  >
                    sign in
                  </MDButton>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        ) : (
          <MDBox pt={4} pb={3} px={3}>
            {type === "forgotpassword" && (
              <MDBox
                component="form"
                role="form"
                onSubmit={formikForOtpSubmit.handleSubmit}
              >
                <MDBox mb={2}>
                  <MDInput
                    label="Enter OTP"
                    type="number"
                    name="otp"
                    fullWidth
                    autoComplete="off"
                    onBlur={formikForOtpSubmit.handleBlur}
                    onChange={formikForOtpSubmit.handleChange}
                    value={formikForOtpSubmit.values.otp}
                    error={
                      formikForOtpSubmit.touched.otp &&
                      formikForOtpSubmit.errors.otp &&
                      true
                    }
                  />
                  {formikForOtpSubmit.touched.otp &&
                    formikForOtpSubmit.errors.otp && (
                      <TextError msg={formikForOtpSubmit.errors.otp} />
                    )}

                  {showMessage && (
                    <MDTypography
                      fontSize="15px"
                      marginTop="10px"
                      marginBottom="10px"
                    >
                      Check your mail for the OTP
                    </MDTypography>
                  )}

                  {showMessage && (
                    <MDTypography marginRight="20px" fontSize="15px">
                      OTP expires in {timer}
                    </MDTypography>
                  )}
                  {timer === "00:00" ? (
                    <MDButton
                      color="white"
                      size="medium"
                      variant="contained"
                      onClick={resendOtp}
                      style={{
                        marginBottom: "-40px",
                        marginTop: "-10px",
                        backgroundColor: "#616161",
                        color: "white",
                      }}
                    >
                      {btnDisabled ? "Resend OTP" : "Send OTP"}
                    </MDButton>
                  ) : (
                    <MDButton
                      disabled
                      color="white"
                      size="medium"
                      variant="contained"
                      // onClick={resendOtp}
                      style={{
                        marginBottom: "-40px",
                        marginTop: "-10px",
                        backgroundColor: "#616161",
                        color: "white",
                      }}
                    >
                      {btnDisabled ? "Resend OTP" : "Send OTP"}
                    </MDButton>
                  )}
                </MDBox>

                <Collapse in={isErrorForgotPassword}>
                  <Alert
                    style={errorStyling}
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsErrorForgotPassword(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Something went wrong!
                  </Alert>
                </Collapse>
                <Collapse in={isSuccessForgotPassword}>
                  <Alert
                    style={errorStyling}
                    severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsSuccessForgotPassword(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Please check your E-mail!
                  </Alert>
                </Collapse>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="error"
                    onClick={backButtonHandler}
                  >
                    back
                  </MDButton>
                  <MDButton
                    style={{ backgroundColor: "red", float: "right" }}
                    // variant="gradient"
                    color="info"
                    type="submit"
                    disabled={!formikForOtpSubmit.isValid}
                  >
                    submit
                  </MDButton>
                </MDBox>
              </MDBox>
            )}
            {type === "login" && (
              <MDBox
                component="form"
                role="form"
                onSubmit={formik.handleSubmit}
              >
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    fullWidth
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email && formik.errors.email && true}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <TextError msg={formik.errors.email} />
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Password"
                    fullWidth
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    error={
                      formik.touched.password && formik.errors.password && true
                    }
                  />
                  {formik.touched.password && formik.errors.password && (
                    <TextError msg={formik.errors.password} />
                  )}
                </MDBox>
                <Collapse in={isError}>
                  <Alert
                    style={errorStyling}
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setIsError(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    Invalid E-Mail/Password
                  </Alert>
                </Collapse>

                <MDBox display="flex" alignItems="center" ml={-1}>
                  <MDTypography
                    variant="button"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    color="info"
                    fontWeight="medium"
                    textGradient
                    onClick={forgotButtonHandler}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Forgot Password?
                  </MDTypography>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    style={{ backgroundColor: "red", float: "right" }}
                    // variant="gradient"
                    color="info"
                    type="submit"
                    disabled={!formik.isValid}
                  >
                    sign in
                  </MDButton>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        )}
      </Card>
      {loading && (
        <Backdrop
          sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </BasicLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.login,
    forgotPasswordData: state.forgotPassword,
    validateOtpForForgotPasswordReducer:
      state.validateOtpForForgotPasswordReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    isAuthCall: (payload) => dispatch(login(payload)),
    forgotPassword: (payload) => dispatch(forgotPassword(payload)),
    validateOtpForForgotPassword: (payload) =>
      dispatch(validateOtpForForgotPassword(payload)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Login);
