/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_ALL_ACCOUNTMANAGER_REQUEST,
  GET_ALL_ACCOUNTMANAGER_SUCCESS,
  GET_ALL_ACCOUNTMANAGER_FAILURE,
  GET_ACCOUNTMANAGER_REQUEST,
  GET_ACCOUNTMANAGER_SUCCESS,
  GET_ACCOUNTMANAGER_FAILURE,
} from "./accountManagerTypes";

const initialState = {
  loading: false,
  accountManagerData: {},
  error: "",
};

const allAccountManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ACCOUNTMANAGER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_ACCOUNTMANAGER_SUCCESS:
      return {
        loading: false,
        accountManagerData: action.payload,
        error: "",
      };
    case GET_ALL_ACCOUNTMANAGER_FAILURE:
      return {
        loading: false,
        accountManagerData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialState2 = {
  loading: false,
  accountManagerData: {},
  error: "",
};

const accountManagerReducer = (state = initialState2, action) => {
  switch (action.type) {
    case GET_ACCOUNTMANAGER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCOUNTMANAGER_SUCCESS:
      return {
        loading: false,
        accountManagerData: action.payload,
        error: "",
      };
    case GET_ACCOUNTMANAGER_FAILURE:
      return {
        loading: false,
        accountManagerData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export { allAccountManagerReducer, accountManagerReducer };
