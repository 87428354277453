/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
    GET_NOTIFICATION_FOR_CLIENT_REQUEST,
    GET_NOTIFICATION_FOR_CLIENT_SUCCESS,
    GET_NOTIFICATION_FOR_CLIENT_FAILURE,
  } from "./notificationForRegisteredClientTypes";
  import axios from "axios";
  import Config from "../../config/index";
  let auth3Url = Config.auth3Url;
  let baseUrl = Config.baseUrl;
  
 
  
  const getNotificationForRegisteredClientRequest = () => {
    return {
      type: GET_NOTIFICATION_FOR_CLIENT_REQUEST,
    };
  };
  
  const getNotificationForRegisteredClientSuccess = (payload) => {
    return {
      type: GET_NOTIFICATION_FOR_CLIENT_SUCCESS,
      payload: payload,
    };
  };
  
  const getNotificationForRegisteredClientFaliure = (error) => {
    return {
      type: GET_NOTIFICATION_FOR_CLIENT_FAILURE,
      payload: error,
    };
  }; 
  
  const getNotificationForRegisteredClient = (jwtToken) => {
    let headers = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      }, 
    }; 
    return (dispatch) => {
      dispatch(getNotificationForRegisteredClientRequest());
      let url = `${baseUrl}/clients/notification_for_registered_clients`;
      return axios
        .get(url, headers) 
        .then((response) => {
          dispatch(getNotificationForRegisteredClientSuccess(response.data));
          return { status: true, data: response.data };
        })
        .catch((err) => {
          dispatch(getNotificationForRegisteredClientFaliure(err.message));
          return { status: false };
        });
    };
  }; 
  
  export { getNotificationForRegisteredClient};
  