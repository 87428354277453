/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
} from "./profileTypes";
import axios from "axios";
import Config from "../../config/index";
let auth3Url = Config.auth3Url;

const profileRequest = () => {
  return {
    type: PROFILE_REQUEST,
  };
};

const profileSuccess = (payload) => {
  return {
    type: PROFILE_SUCCESS,
    payload: payload,
  };
};

const profileFaliure = (error) => {
  return {
    type: PROFILE_FAILURE,
    payload: error,
  };
};

const getProfile = (userId) => {
  return function (dispatch) {
    dispatch(profileRequest());
    let url = `${auth3Url}/profile/${userId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(profileSuccess(response.data));
      })
      .catch((error) => {
        dispatch(profileFaliure(error.message));
      });
  };
};

export { getProfile };
