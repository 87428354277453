/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { combineReducers } from "redux";
import loginReducer from "./authentication/login/loginReducer";
import resetPasswordReducer from "./authentication/reset-password/resetPasswordReducer";
import {
  setPasswordReducer,
  setPasswordForNewUserReducer,
  setNewPasswordReducer,
} from "./authentication/set-password/setPasswordReducer";
import {
  forgotPasswordReducer,
  validateOtpForForgotPasswordReducer,
} from "./authentication/forgot-password/forgotPasswordReducer";
import profileReducer from "./profile/profileReducer";
import { otpValidateReducer, otpReducer } from "./otp/otpReducers";

import {
  clientDetailsReducer,
  getPreviewReducer,
  getClientRequestForClientsReducer,
  listOfProfilesReducer,
  listOfShortlistedProfilesReducer,
} from "./client-details/clientDetailsReducers";
import {
  allClientRequestsReducer,
  getAllUnassignedClientsReducer,
  getClientsByClientIdReducer,
  getAllClientsReducer,
  getClientByIdReducer,
} from "./client-request/clientRequestReducers";
import {
  allAccountManagerReducer,
  accountManagerReducer,
} from "./account-manager/accountManagerReducers";
import {
  assignClientReducer,
  profilesReducer,
} from "./assign-client/assignClientReducers";
import {
  getClientRequestForManagerReducer,
  getClientForReAssignReducer,
} from "./client-request-for-manager/clientRequestForManagerReducers";
import allReferenceReducer from "./reference/referenceReducers";
import {
  getUploadProfileReducer,
  getAllProfileReducer,
  getProfileByClientIdReducer,
  profilesNotificationReducer,
} from "./upload-profile/uploadProfileReducers";
import {
  notificationForRegisteredClientReducer
} from "./notification-for-registered-client/notificationForRegisteredClientReducers"

const rootReducer = combineReducers({
  login: loginReducer,
  resetPassword: resetPasswordReducer,
  setPasswordReducer: setPasswordReducer,
  setPasswordForNewUserReducer: setPasswordForNewUserReducer,
  setNewPasswordReducer: setNewPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  validateOtpForForgotPasswordReducer: validateOtpForForgotPasswordReducer,
  profile: profileReducer,
  otpValidateReducer: otpValidateReducer,
  otpReducer: otpReducer,
  clientDetailsReducer: clientDetailsReducer,
  getPreviewReducer: getPreviewReducer,
  getClientRequestForClientsReducer: getClientRequestForClientsReducer,
  listOfProfilesReducer: listOfProfilesReducer,
  listOfShortlistedProfilesReducer: listOfShortlistedProfilesReducer,
  allClientRequestsReducer: allClientRequestsReducer,
  getAllUnassignedClientsReducer: getAllUnassignedClientsReducer,
  allAccountManagerReducer: allAccountManagerReducer,
  accountManagerReducer: accountManagerReducer,
  assignClientReducer: assignClientReducer,
  profilesReducer: profilesReducer,
  getClientRequestForManagerReducer: getClientRequestForManagerReducer,
  getClientForReAssignReducer: getClientForReAssignReducer,
  getClientsByClientIdReducer: getClientsByClientIdReducer,
  getAllClientsReducer: getAllClientsReducer,
  getClientByIdReducer: getClientByIdReducer,
  allReferenceReducer: allReferenceReducer,
  getUploadProfileReducer: getUploadProfileReducer,
  getProfileByClientIdReducer: getProfileByClientIdReducer,
  profilesNotificationReducer: profilesNotificationReducer,
  getAllProfileReducer: getAllProfileReducer,
  notificationForRegisteredClientReducer: notificationForRegisteredClientReducer ,
});

export default rootReducer;
