// /*********************
//  * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
//  * __________________
//  *
//  *  Mobillor Technologies Pvt. Ltd.
//  *  All Rights Reserved.
//  *
//  * NOTICE:  All information contained herein is, and remains
//  * the property of Mobillor Technologies Pvt. Ltd.
//  * The intellectual and technical concepts contained
//  * herein are proprietary to Mobillor Technologies Pvt. Ltd.
//  * may be covered by Rebublic Of India and Foreign Patents,
//  * patents in process, and are protected by trade secret or copyright law.
//  * Dissemination of this information or reproduction of this material
//  * is strictly forbidden unless prior written permission is obtained
//  * from Mobillor Technologies Pvt. Ltd.
//  */

import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";

// @mui material components
import Card from "@mui/material/Card";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";
import TextError from "utils/TextError";

// some date functions
import { format, addMinutes } from "date-fns";
import Select from "react-select";

//  formik and yup
import { useFormik } from "formik";
import * as Yup from "yup";

//  import aws or uuid
import AWS from "aws-sdk";
import { v4 } from "uuid";

// Functions from store
import {
  getUploadProfile,
  deleteUploadProfile,
  editUploadProfile,
  createPofile,
} from "../../store";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const ViewUploadProfile = ({
  getUploadProfile,
  getUploadProfileReducer,
  deleteUploadProfile,
  editUploadProfile,
  createPofile,
}) => {
  const styleForCreateProfile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width:  { xs: 380, sm: 380, md: 400, lg: 450, xl: 450 },
    height: "90%",
    boxShadow: 24,
    p: 1,
    pt: 2,
  };

  const deleteStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    boxShadow: 24,
    p: 2,
    pt: 3,
  };
  const styleForEdit = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 400, lg: 450, xl: 450 },
    height: "90%",
    boxShadow: 24,
    p: 1,
    pt: 2,
  };

  const styleForDocument = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: 380, sm: 380, md: 600, lg: 900, xl: 900 },
    overflow: "auto",
    height: "90%",
    boxShadow: 24,
    p: 1,
    pt: 3,
  };

  let data = cookies.get("mobillor-talent-accelerator-user-data");
  let jwtToken = data?.data?.jwtToken;

  const [clientId, setClientId] = useState("");

  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");

  const importPageNo = (pageIndex) => {
    setPageNo(pageIndex);
  };

  const importRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
  };

  const importSearch = (globalFilter) => {
    setSearch(globalFilter);
  };

  useEffect(() => {
    getUploadProfile(jwtToken, pageNo, rowsPerPage);
  }, [getUploadProfile, pageNo, rowsPerPage]);

  const [state, setState] = useState({
    columns: [
      { Header: "Name", accessor: "name" },
      { Header: "Email", accessor: "email" },
      {
        Header: "Possible Joining",
        accessor: "possibleJoining",
        align: "center",
      },
      {
        Header: "Experience (Years)",
        accessor: "yearsOfExperience",
        align: "center",
      },
      { Header: "Technology", accessor: "technology", align: "center" },
      {
        Header: "Notice Period (Days)",
        accessor: "noticePeriod",
        align: "center",
      },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: [],
  });
  const { columns, rows } = state;
  const [paginationObj, setPaginationObj] = useState({});
  let loading = getUploadProfileReducer.loading;
  useEffect(() => {
    let tempProfile = [];
    let profileData = getUploadProfileReducer?.profileData.data
      ? getUploadProfileReducer?.profileData.data
      : [];

    if (getUploadProfileReducer?.profileData?.data?.length) {
      setPaginationObj(getUploadProfileReducer?.profileData?.pagination);
      profileData?.map((data) => {
        const formatDate = new Date(data.possibleJoining);
        const singledata = {
          name: data.name,
          email: data.email,
          yearsOfExperience: data.yearsOfExperience,
          possibleJoining: format(
            addMinutes(formatDate, formatDate.getTimezoneOffset()),
            "do MMMM yyyy"
          ),
          noticePeriod: data.noticePeriod,
          technology: data.technology.map((item) => item.value).join(" , "),
          action: (
            <>
              <span style={{ display: "flex" }}>
                <Tooltip title="Edit">
                  <MDButton
                    ml={5}
                    variant="gradient"
                    color="success"
                    iconOnly
                    type="button"
                    onClick={() => {
                      handleopenEditModal(data);
                    }}
                  >
                    <Icon> edit_icon </Icon>
                  </MDButton>
                </Tooltip>

                <Tooltip title="View Resume">
                  <MDButton
                    style={{ marginLeft: "10px" }}
                    variant="gradient"
                    color="primary"
                    iconOnly
                    type="button"
                    onClick={() => {
                      handleOpenViewDocumentModal(data);
                    }}
                  >
                    {" "}
                    <FileOpenIcon />
                  </MDButton>
                </Tooltip>

                <Tooltip title="Delete">
                  <MDButton
                    style={{ marginLeft: "10px" }}
                    variant="gradient"
                    color="error"
                    iconOnly
                    type="button"
                    onClick={() => handleopenDeleteProfileModal(data)}
                  >
                    <DeleteIcon />
                  </MDButton>
                </Tooltip>
              </span>
            </>
          ),
        };
        tempProfile.push(singledata);
      });
    }
    setState({ ...state, rows: tempProfile });
  }, [getUploadProfileReducer]);

  const [openDeleteProfileModal, setopenDeleteProfileModal] = useState(false);
  const [singleProfileDataForDelete, setProfileDataForDelete] = useState({});

  const handleopenDeleteProfileModal = (department = {}) => {
    setProfileDataForDelete(department);
    setopenDeleteProfileModal(true);
  };
  const handleCloseProfileClientModal = () => {
    setopenDeleteProfileModal(false);
  };
  const [isErrorDeleteProfile, setisErrorDeleteProfile] = useState(false);

  useEffect(() => {
    if (isErrorDeleteProfile) {
      setTimeout(() => {
        setisErrorDeleteProfile(false);
      }, 3000);
    }
  }, [isErrorDeleteProfile]);

  const deleteProfileData = async () => {
    let profileId = singleProfileDataForDelete.profileId;
    let response = await deleteUploadProfile(jwtToken, profileId);
    if (response.status) {
      getUploadProfile(jwtToken, pageNo, rowsPerPage);
      handleCloseProfileClientModal();
    }
    if (!response.status) {
      setisErrorDeleteProfile(true);
    }
  };
  //    view model
  const [document, setDocument] = useState("");

  const [openViewDocumentModal, setOpenViewDocumentModal] = useState(false);

  const handleOpenViewDocumentModal = async (Data = {}) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });
    const url = await s3.getSignedUrlPromise("getObject", {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: Data.resume,
      Expires: 1800,
    });
    setDocument(url);
    setOpenViewDocumentModal(true);
  };

  const handleCloseViewDocumentModal = () => {
    setOpenViewDocumentModal(false);
  };

  const [openEditModal, setopenEditModal] = useState(false);
  const [dataForEditProfile, setdataForEditProfile] = useState({});
  const [dataForProfile, setdataForProfile] = useState("");

  // let email = dataForEditProfile ? dataForEditProfile.email : "";

  const handleopenEditModal = (data = {}) => {
    setdataForProfile(data);
    setdataForEditProfile(data);
    setopenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setopenEditModal(false);
  };

  const techOptions = [
    { value: "Tech1", label: "Tech1" },
    { value: "Tech2", label: "Tech2" },
    { value: "Tech3", label: "Tech3" },
  ];

  const [selectedTechForEdit, setSelectedTechForEdit] = useState({});

  const handleTechChangeForEdit = (selectedTech) => {
    setSelectedTechForEdit(selectedTech);
  };

  useEffect(() => {
    if (dataForProfile) {
      setSelectedTechForEdit(
        dataForProfile.technology.map((item) => ({
          label: item.value,
          value: item.value,
        }))
      );
    }
  }, [dataForProfile]);

  const [isSuccessForEditProfile, setisSuccessForEditProfile] = useState(false);
  const [isErrorForEditProfile, setisErrorForEditProfile] = useState(false);
  useEffect(() => {
    if (isSuccessForEditProfile) {
      setTimeout(() => {
        setisSuccessForEditProfile(false);
      }, 3000);
    }
  }, [isSuccessForEditProfile]);

  useEffect(() => {
    if (isErrorForEditProfile) {
      setTimeout(() => {
        setisErrorForEditProfile(false);
      }, 3000);
    }
  }, [isErrorForEditProfile]);

  const initialValuesForEditProfile = {
    name: dataForEditProfile ? dataForEditProfile.name : "",
    email: dataForEditProfile ? dataForEditProfile.email : "",
    country: dataForEditProfile.address
      ? dataForEditProfile.address.country
      : "",
    state: dataForEditProfile.address ? dataForEditProfile.address.state : "",
    city: dataForEditProfile.address ? dataForEditProfile.address.city : "",
    street: dataForEditProfile.address ? dataForEditProfile.address.street : "",
    pinCode: dataForEditProfile.address
      ? dataForEditProfile.address.pinCode
      : "",
    possibleJoining: dataForEditProfile.possibleJoining
      ? dataForEditProfile.possibleJoining.slice(0, 10)
      : "",
    technology: dataForEditProfile ? dataForEditProfile.technology : "",
    noticePeriod: dataForEditProfile ? dataForEditProfile.noticePeriod : "",
    yearsOfExperience: dataForEditProfile
      ? dataForEditProfile.yearsOfExperience
      : "",
  };

  const validationSchemaForEditProfile = Yup.object({
    name: Yup.string().required("Enter the name"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the email"),
    country: Yup.string().required("Enter country"),
    state: Yup.string().required("Enter state"),
    city: Yup.string().required("Enter city"),
    street: Yup.string().required("Enter street"),
    pinCode: Yup.string()
      .required("Enter a Pincode")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),
    possibleJoining: Yup.string().required("Enter the date"),
    yearsOfExperience: Yup.string().required("Enter the experience"),
    // // technology: Yup.string().required("Enter the technology"),
    noticePeriod: Yup.string().required("Enter the notice Period"),
  });

  const onSubmitForEditProfile = async (values, { resetForm }) => {
    values.profileId = dataForEditProfile?.profileId;
    // values.technology = selectedTechForEdit;
    values.address = {
      country: values.country,
      state: values.state,
      city: values.city,
      street: values.street,
      pinCode: values.pinCode,
    };

    // values.resume = documentName;

    // values.technology = selectedTech;

    let payload = {
      profileId: dataForEditProfile?.profileId,

      name: values.name,

      email: values.email,

      address: values.address,

      possibleJoining: values.possibleJoining,

      noticePeriod: values.noticePeriod,

      technology: selectedTechForEdit,
    };

    let response = await editUploadProfile(jwtToken, payload);

    if (response.status === true) {
      setisSuccessForEditProfile(true);
      resetForm();
      getUploadProfile(jwtToken, pageNo, rowsPerPage);
    }
    if (response.status == false) {
      setisErrorForEditProfile(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValuesForEditProfile,
    onSubmit: onSubmitForEditProfile,
    validationSchema: validationSchemaForEditProfile,
    enableReinitialize: true,
  });

  const [selectedTech, setSelectedTech] = useState("");

  const handleTechChange = (selectedTech) => {
    setSelectedTech(selectedTech);
  };

  const [openCreateProfileModal, setOpenCreateProfileModal] = useState(false);

  const handleOpenCreateProfileModal = () => {
    setOpenCreateProfileModal(true);
  };

  const handleCloseCreateProfileModal = () => {
    setOpenCreateProfileModal(false);
  };

  const [isSuccessCreateProfile, setIsSuccessCreateProfile] = useState(false);
  const [isErrorCreateProfile, setIsErrorCreateProfile] = useState(false);
  useEffect(() => {
    if (isSuccessCreateProfile) {
      setTimeout(() => {
        setIsSuccessCreateProfile(false);
      }, 3000);
    }
  }, [isSuccessCreateProfile]);

  useEffect(() => {
    if (isErrorCreateProfile) {
      setTimeout(() => {
        setIsErrorCreateProfile(false);
      }, 3000);
    }
  }, [isErrorCreateProfile]);

  const initialValuesForCreateProfile = {
    name: "",
    email: "",
    address: "",
    country: "",
    state: "",
    city: "",
    street: "",
    pinCode: "",
    possibleJoining: "",
    noticePeriod: "",
    resume: "",
    technology: [],
    yearsOfExperience: "",
  };

  const validationSchemaForCreateProfile = Yup.object({
    name: Yup.string().required("Enter the profile name"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the email"),
    country: Yup.string().required("Enter country"),
    state: Yup.string().required("Enter state"),
    city: Yup.string().required("Enter city"),
    street: Yup.string().required("Enter street"),
    pinCode: Yup.string()
      .required("Enter a Pincode")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),
    possibleJoining: Yup.string().required("Enter the Possible Joining Date"),
    noticePeriod: Yup.string().required("Enter the Notice Period"),
    // resumeFile: Yup.string().required("Upload the resume"),
    // technology: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       label: Yup.string(),
    //       value: Yup.string(),
    //     })
    //   )
    //   .required("Select technologies"),
    // yearsOfExperience: Yup.string().required("Enter experience"),
  });

  const onSubmitForCreateProfile = async (values, { resetForm }) => {
    values.address = {
      country: values.country,
      state: values.state,
      city: values.city,
      street: values.street,
      pinCode: values.pinCode,
    };

    // values.resume = documentName;

    // values.technology = selectedTech;

    let payload = {
      name: values.name,

      email: values.email,

      address: values.address,

      possibleJoining: values.possibleJoining,

      noticePeriod: values.noticePeriod,

      resume: documentName,

      technology: selectedTech,
    };

    let response = await createPofile(payload, jwtToken);

    if (response.status === true) {
      setIsSuccessCreateProfile(true);
      setSelectedTech([]);

      resetForm();
      getUploadProfile(jwtToken, pageNo, rowsPerPage);
    }
    if (response.status === false) {
      setIsErrorCreateProfile(true);
    }
  };

  const [loc, setLoc] = useState("");
  const [uid, setUid] = useState("");
  const [resumeFile, setResumeFile] = useState({});

  const handleChangeForResume = (event) => {
    setResumeFile(event.target.files[0]);
  };

  const [documentName, setDocumentName] = useState("");
  var UUID;
  var location;
  var uuid = v4();

  const upload = () => {
    let filename = `${uuid}_${resumeFile.name}`;
    setDocumentName(filename);
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    var params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: filename,
      Body: resumeFile,
      ContentType: "application/pdf",
    };

    s3.upload(params, (err, data) => {
      if (err) {
      } else {
        location = data.Location;

        UUID = uuid;
        setLoc(location);
        setUid(uuid);
      }
    });
  };

  useEffect(() => {
    if (resumeFile.name === undefined) {
    } else {
      upload();
    }
  }, [resumeFile]);

  const formikForCreateProfile = useFormik({
    initialValues: initialValuesForCreateProfile,
    onSubmit: onSubmitForCreateProfile,
    validationSchema: validationSchemaForCreateProfile,
  });

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {!loading ? (
          <>
            <MDBox pt={2} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={3}
                      mb={-6}
                    >
                      <MDBox>
                        <MDTypography variant="h4" gutterBottom>
                          Profiles
                        </MDTypography>
                      </MDBox>

                      <MDBox color="text" px={2}>
                        <Tooltip title="Upload Profile">
                          <MDButton
                            color="white"
                            variant="contained"
                            type="button"
                            style={{ background: "#7D3C98", color: "white" }}
                            onClick={() => {
                              handleOpenCreateProfileModal();
                            }}
                          >
                            Upload Profile
                          </MDButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                    <MDBox pt={3}>
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={true}
                        canSearch={true}
                        entriesPerPage={{
                          defaultValue: 5,
                          entries: [5, 10, 15, 20, 25, 30],
                        }}
                        showTotalEntries={true}
                        noEndBorder
                        serverSidePagination={true}
                        paginationObj={paginationObj}
                        pageNoValue={pageNo}
                        rowsPerPageValue={rowsPerPage}
                        pageNoProp={importPageNo}
                        rowsPerPageProp={importRowsPerPage}
                      />
                    </MDBox>
                  </Card>
                </Grid>
                <Grid>
                  <Modal
                    open={openCreateProfileModal}
                    onClose={handleCloseCreateProfileModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <MDCard sx={styleForCreateProfile}>
                      <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                        <MDTypography
                          variant="h5"
                          fontWeight="medium"
                          flexGrow={1}
                        >
                          Upload Profile
                        </MDTypography>
                        <MDBox>
                          <Tooltip title="Close">
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              onClick={handleCloseCreateProfileModal}
                              style={{
                                background: "whitesmoke",
                                color: "black",
                                borderRadius: 5,
                                padding: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Tooltip>
                        </MDBox>
                      </MDBox>

                      <hr />

                      <MDBox pb={3} px={1} overflow="auto">
                        <MDBox pt={1} px={1}>
                          <MDBox
                            component="form"
                            role="form"
                            onSubmit={formikForCreateProfile.handleSubmit}
                          >
                            <MDBox mb={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Name
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: 5,
                                    marginBottom: 15,
                                  }}
                                >
                                  *
                                </span>
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="name"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForCreateProfile.handleChange}
                                onBlur={formikForCreateProfile.handleBlur}
                                value={formikForCreateProfile.values.name}
                                error={
                                  formikForCreateProfile.touched.name &&
                                  formikForCreateProfile.errors.name &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "10px",
                                  },
                                }}
                              />
                              {formikForCreateProfile.touched.name &&
                                formikForCreateProfile.errors.name && (
                                  <TextError
                                    msg={formikForCreateProfile.errors.name}
                                  />
                                )}
                            </MDBox>

                            <MDBox mb={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Email
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: 5,
                                    marginBottom: 15,
                                  }}
                                >
                                  *
                                </span>
                              </MDTypography>
                              <MDInput
                                type="text"
                                name="email"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForCreateProfile.handleChange}
                                onBlur={formikForCreateProfile.handleBlur}
                                value={formikForCreateProfile.values.email}
                                error={
                                  formikForCreateProfile.touched.email &&
                                  formikForCreateProfile.errors.email &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "10px",
                                  },
                                }}
                              />
                              {formikForCreateProfile.touched.email &&
                                formikForCreateProfile.errors.email && (
                                  <TextError
                                    msg={formikForCreateProfile.errors.email}
                                  />
                                )}
                            </MDBox>

                            <MDBox mb={1}>
                              <MDTypography
                                variant="h6"
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Address
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: 5,
                                    marginBottom: 15,
                                  }}
                                >
                                  *
                                </span>
                              </MDTypography>

                              <MDBox mb={1}>
                                <MDInput
                                  type="text"
                                  name="country"
                                  placeholder="Country"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formikForCreateProfile.handleChange}
                                  onBlur={formikForCreateProfile.handleBlur}
                                  value={formikForCreateProfile.values.country}
                                  error={
                                    formikForCreateProfile.touched.country &&
                                    formikForCreateProfile.errors.country &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "10px",
                                    },
                                  }}
                                />
                                {formikForCreateProfile.touched.country &&
                                  formikForCreateProfile.errors.country && (
                                    <TextError
                                      msg={
                                        formikForCreateProfile.errors.country
                                      }
                                    />
                                  )}
                              </MDBox>
                              <MDBox mb={1}>
                                <MDInput
                                  type="text"
                                  name="state"
                                  placeholder="State"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formikForCreateProfile.handleChange}
                                  onBlur={formikForCreateProfile.handleBlur}
                                  value={formikForCreateProfile.values.state}
                                  error={
                                    formikForCreateProfile.touched.state &&
                                    formikForCreateProfile.errors.state &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "10px",
                                    },
                                  }}
                                />
                                {formikForCreateProfile.touched.state &&
                                  formikForCreateProfile.errors.state && (
                                    <TextError
                                      msg={formikForCreateProfile.errors.state}
                                    />
                                  )}
                              </MDBox>
                              <MDBox mb={1}>
                                <MDInput
                                  type="text"
                                  name="city"
                                  placeholder="City"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formikForCreateProfile.handleChange}
                                  onBlur={formikForCreateProfile.handleBlur}
                                  value={formikForCreateProfile.values.city}
                                  error={
                                    formikForCreateProfile.touched.city &&
                                    formikForCreateProfile.errors.city &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "10px",
                                    },
                                  }}
                                />
                                {formikForCreateProfile.touched.city &&
                                  formikForCreateProfile.errors.city && (
                                    <TextError
                                      msg={formikForCreateProfile.errors.city}
                                    />
                                  )}
                              </MDBox>
                              <MDBox mb={1}>
                                <MDInput
                                  type="text"
                                  name="street"
                                  placeholder="Street"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={formikForCreateProfile.handleChange}
                                  onBlur={formikForCreateProfile.handleBlur}
                                  value={formikForCreateProfile.values.street}
                                  error={
                                    formikForCreateProfile.touched.street &&
                                    formikForCreateProfile.errors.street &&
                                    true
                                  }
                                  inputProps={{
                                    style: {
                                      height: "10px",
                                    },
                                  }}
                                />
                                {formikForCreateProfile.touched.street &&
                                  formikForCreateProfile.errors.street && (
                                    <TextError
                                      msg={formikForCreateProfile.errors.street}
                                    />
                                  )}
                              </MDBox>
                              <MDBox mb={1}></MDBox>
                              <MDInput
                                type="number"
                                name="pinCode"
                                placeholder="PinCode"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForCreateProfile.handleChange}
                                onBlur={formikForCreateProfile.handleBlur}
                                value={formikForCreateProfile.values.pinCode}
                                error={
                                  formikForCreateProfile.touched.pinCode &&
                                  formikForCreateProfile.errors.pinCode &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "10px",
                                  },
                                }}
                              />
                              {formikForCreateProfile.touched.pinCode &&
                                formikForCreateProfile.errors.pinCode && (
                                  <TextError
                                    msg={formikForCreateProfile.errors.pinCode}
                                  />
                                )}
                            </MDBox>

                            <MDBox mb={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Possible Joining Date
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: 5,
                                    marginBottom: 15,
                                  }}
                                >
                                  *
                                </span>
                              </MDTypography>
                              <MDInput
                                type="date"
                                name="possibleJoining"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForCreateProfile.handleChange}
                                onBlur={formikForCreateProfile.handleBlur}
                                value={
                                  formikForCreateProfile.values.possibleJoining
                                }
                                error={
                                  formikForCreateProfile.touched
                                    .possibleJoining &&
                                  formikForCreateProfile.errors
                                    .possibleJoining &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "10px",
                                  },
                                }}
                              />
                              {formikForCreateProfile.touched.possibleJoining &&
                                formikForCreateProfile.errors
                                  .possibleJoining && (
                                  <TextError
                                    msg={
                                      formikForCreateProfile.errors
                                        .possibleJoining
                                    }
                                  />
                                )}
                            </MDBox>

                            <MDBox mb={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Notice Period (In Day/s)
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: 5,
                                    marginBottom: 15,
                                  }}
                                >
                                  *
                                </span>
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="noticePeriod"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForCreateProfile.handleChange}
                                onBlur={formikForCreateProfile.handleBlur}
                                value={
                                  formikForCreateProfile.values.noticePeriod
                                }
                                error={
                                  formikForCreateProfile.touched.noticePeriod &&
                                  formikForCreateProfile.errors.noticePeriod &&
                                  true
                                }
                                inputProps={{
                                  maxLength: 2,
                                  style: {
                                    height: "10px",
                                  },
                                }}
                              />
                              {formikForCreateProfile.touched.noticePeriod &&
                                formikForCreateProfile.errors.noticePeriod && (
                                  <TextError
                                    msg={
                                      formikForCreateProfile.errors.noticePeriod
                                    }
                                  />
                                )}
                            </MDBox>

                            <MDBox mb={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Technology
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: 5,
                                    marginBottom: 15,
                                  }}
                                >
                                  *
                                </span>
                              </MDTypography>
                              <Select
                                className="select-css"
                                name="technology"
                                isMulti
                                value={selectedTech}
                                options={techOptions}
                                onBlur={() => {
                                  formikForCreateProfile.handleBlur({
                                    target: { name: "technology" },
                                  });
                                }}
                                onChange={(selectedOption) => {
                                  handleTechChange(selectedOption);
                                  formikForCreateProfile.handleChange(
                                    "technology"
                                  )(selectedOption.value);
                                }}
                                error={
                                  formikForCreateProfile.touched.technology &&
                                  formikForCreateProfile.errors.technology &&
                                  true
                                }
                                inputProps={{
                                  style: {
                                    height: "10px",
                                  },
                                }}
                              />

                              {formikForCreateProfile.touched.technology &&
                                formikForCreateProfile.errors.technology && (
                                  <TextError
                                    msg={
                                      formikForCreateProfile.errors.technology
                                    }
                                  />
                                )}
                            </MDBox>

                            <MDBox mb={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Experience (In Year/s)
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: 5,
                                    marginBottom: 15,
                                  }}
                                >
                                  *
                                </span>
                              </MDTypography>
                              <MDInput
                                type="number"
                                name="yearsOfExperience"
                                fullWidth
                                autoComplete="off"
                                onChange={formikForCreateProfile.handleChange}
                                onBlur={formikForCreateProfile.handleBlur}
                                value={
                                  formikForCreateProfile.values
                                    .yearsOfExperience
                                }
                                error={
                                  formikForCreateProfile.touched
                                    .yearsOfExperience &&
                                  formikForCreateProfile.errors
                                    .yearsOfExperience &&
                                  true
                                }
                                inputProps={{
                                  maxLength: 2,
                                  style: {
                                    height: "10px",
                                  },
                                }}
                              />
                              {formikForCreateProfile.touched
                                .yearsOfExperience &&
                                formikForCreateProfile.errors
                                  .yearsOfExperience && (
                                  <TextError
                                    msg={
                                      formikForCreateProfile.errors
                                        .yearsOfExperience
                                    }
                                  />
                                )}
                            </MDBox>

                            <MDBox mb={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="medium"
                                flexGrow={1}
                                style={{ fontWeight: "500", fontSize: 14 }}
                              >
                                Resume
                                <span
                                  style={{
                                    color: "red",
                                    marginLeft: 5,
                                    marginBottom: 15,
                                  }}
                                >
                                  *
                                </span>
                              </MDTypography>
                              <input
                                name="resumeFile"
                                style={{
                                  borders: "none",
                                  marginTop: "10px",
                                  width: "100%",
                                }}
                                className="choose_file"
                                type="file"
                                autoComplete="off"
                                accept=".pdf" 
                                //   name="agreement"
                                id="chooseFile"
                                onChange={(event) =>
                                  handleChangeForResume(event)
                                }
                              />
                            </MDBox>  
                            <MDBox>
                              <Collapse in={isErrorCreateProfile}>
                                <Alert
                                  severity="error"
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setIsErrorCreateProfile(false);
                                      }} 
                                    > 
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                >
                                  Please check the details. Server error!
                                </Alert>
                              </Collapse>
                              <Collapse in={isSuccessCreateProfile}> 
                                <Alert
                                  severity="success"
                                  action={
                                    <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                        setIsSuccessCreateProfile(false);
                                      }}
                                    >
                                      <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                  }
                                >
                                  Profile created successfully
                                </Alert>
                              </Collapse>
                            </MDBox>
                            <MDBox mt={2}>
                              <MDButton
                                color="white"
                                type="submit"
                                style={{
                                  background: "#7D3C98",
                                  color: "white",
                                }}
                                fullWidth
                                disabled={
                                  !formikForCreateProfile.isValid ||
                                  !selectedTech.length ||
                                  !documentName
                                }
                              >
                                Submit
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDCard>
                  </Modal>
                  <Modal
                    open={openDeleteProfileModal}
                    onClose={handleCloseProfileClientModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <MDCard sx={deleteStyle}>
                      <MDTypography>
                        Are you sure you want to delete?
                      </MDTypography>
                      <MDBox mt={2}>
                        <div style={{ display: "flex" }}>
                          <div>
                            <MDButton
                              style={{ width: "100%", marginRight: "0.5rem" }}
                              color="info"
                              variant="outlined"
                              onClick={handleCloseProfileClientModal}
                            >
                              Cancel
                            </MDButton>
                          </div>
                          <div>
                            <MDButton
                              style={{ width: "100%", marginLeft: "0.5rem" }}
                              color="error"
                              onClick={deleteProfileData}
                            >
                              Delete
                            </MDButton>
                          </div>
                        </div>
                      </MDBox>
                      <MDBox mt={2}>
                        <Collapse in={isErrorDeleteProfile}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setisErrorDeleteProfile(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Please check the details. Server error!
                          </Alert>
                        </Collapse>
                      </MDBox>
                    </MDCard>
                  </Modal>
                </Grid>
                <Modal
                  open={openViewDocumentModal}
                  onClose={handleCloseViewDocumentModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForDocument}>
                    <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h5"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        View Resume
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseViewDocumentModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>

                    <hr />

                    <MDBox pt={1} pb={3} px={1} height="100%">
                      <iframe
                        style={{
                          height: "100%",
                          width: "100%",
                          overflow: "auto",
                        }}
                        src={document}
                      ></iframe>
                    </MDBox>
                  </MDCard>
                </Modal>

                <Modal
                  open={openEditModal}
                  onClose={handleCloseEditModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MDCard sx={styleForEdit}>
                    <MDBox ml={1} mt={-1} display="flex" alignItems="center">
                      <MDTypography
                        variant="h5"
                        fontWeight="medium"
                        flexGrow={1}
                      >
                        Edit Profile
                      </MDTypography>
                      <MDBox>
                        <Tooltip title="Close">
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseEditModal}
                            style={{
                              background: "whitesmoke",
                              color: "black",
                              borderRadius: 5,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>

                    <hr />

                    <MDBox pb={2} px={1.5} py={3} overflow="auto">
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formik.handleSubmit}
                      >
                        <MDBox mb={2} mt={-2}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500" }}
                          >
                            Name
                          </MDTypography>

                          <MDInput
                            type="text"
                            name="name"
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            error={
                              formik.touched.name && formik.errors.name && true
                            }
                          />
                          {formik.touched.name && formik.errors.name && (
                            <TextError msg={formik.errors.name} />
                          )}
                        </MDBox>
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500", fontSize: 14 }}
                          >
                            Email
                            <span
                              style={{
                                color: "red",
                                marginLeft: 5,
                                marginBottom: 15,
                              }}
                            >
                              *
                            </span>
                          </MDTypography>
                          <MDInput
                            type="text"
                            name="email"
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            error={
                              formik.touched.email &&
                              formik.errors.email &&
                              true
                            }
                            inputProps={{
                              style: {
                                height: "10px",
                              },
                            }}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <TextError msg={formik.errors.email} />
                          )}
                        </MDBox>

                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            style={{ fontWeight: "500", fontSize: 14 }}
                          >
                            Address
                            <span
                              style={{
                                color: "red",
                                marginLeft: 5,
                                marginBottom: 15,
                              }}
                            >
                              *
                            </span>
                          </MDTypography>

                          <MDBox mb={1}>
                            <MDInput
                              type="text"
                              name="country"
                              placeholder="Country"
                              fullWidth
                              autoComplete="off"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.country}
                              error={
                                formik.touched.country &&
                                formik.errors.country &&
                                true
                              }
                              inputProps={{
                                style: {
                                  height: "10px",
                                },
                              }}
                            />
                            {formik.touched.country &&
                              formik.errors.country && (
                                <TextError msg={formik.errors.country} />
                              )}
                          </MDBox>
                          <MDBox mb={1}>
                            <MDInput
                              type="text"
                              name="state"
                              placeholder="State"
                              fullWidth
                              autoComplete="off"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.state}
                              error={
                                formik.touched.state &&
                                formik.errors.state &&
                                true
                              }
                              inputProps={{
                                style: {
                                  height: "10px",
                                },
                              }}
                            />
                            {formik.touched.state && formik.errors.state && (
                              <TextError msg={formik.errors.state} />
                            )}
                          </MDBox>
                          <MDBox mb={1}>
                            <MDInput
                              type="text"
                              name="city"
                              placeholder="City"
                              fullWidth
                              autoComplete="off"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.city}
                              error={
                                formik.touched.city &&
                                formik.errors.city &&
                                true
                              }
                              inputProps={{
                                style: {
                                  height: "10px",
                                },
                              }}
                            />
                            {formik.touched.city && formik.errors.city && (
                              <TextError msg={formik.errors.city} />
                            )}
                          </MDBox>
                          <MDBox mb={1}>
                            <MDInput
                              type="text"
                              name="street"
                              placeholder="Street"
                              fullWidth
                              autoComplete="off"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.street}
                              error={
                                formik.touched.street &&
                                formik.errors.street &&
                                true
                              }
                              inputProps={{
                                style: {
                                  height: "10px",
                                },
                              }}
                            />
                            {formik.touched.street && formik.errors.street && (
                              <TextError msg={formik.errors.street} />
                            )}
                          </MDBox>
                          <MDBox mb={1}></MDBox>
                          <MDInput
                            type="number"
                            name="pinCode"
                            placeholder="PinCode"
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pinCode}
                            error={
                              formik.touched.pinCode &&
                              formik.errors.pinCode &&
                              true
                            }
                            inputProps={{
                              style: {
                                height: "10px",
                              },
                            }}
                          />
                          {formik.touched.pinCode && formik.errors.pinCode && (
                            <TextError msg={formik.errors.pinCode} />
                          )}
                        </MDBox>
                        <MDBox mb={2} mt={2}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500" }}
                          >
                            Possible Joining
                          </MDTypography>
                          <MDInput
                            type="date"
                            name="possibleJoining"
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.possibleJoining}
                            error={
                              formik.touched.possibleJoining &&
                              formik.errors.possibleJoining &&
                              true
                            }
                          />
                          {formik.touched.possibleJoining &&
                            formik.errors.possibleJoining && (
                              <TextError msg={formik.errors.possibleJoining} />
                            )}
                        </MDBox>
                        <MDBox mb={2} mt={2}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500" }}
                          >
                            Notice Period(In Day/s)
                          </MDTypography>

                          <MDInput
                            type="number"
                            name="noticePeriod"
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.noticePeriod}
                            error={
                              formik.touched.noticePeriod &&
                              formik.errors.noticePeriod &&
                              true
                            }
                          />
                          {formik.touched.noticePeriod &&
                            formik.errors.noticePeriod && (
                              <TextError msg={formik.errors.noticePeriod} />
                            )}
                        </MDBox>
                        <MDBox mb={1}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500", fontSize: 14 }}
                          >
                            Technology
                          </MDTypography>

                          <Select
                            className="select-css"
                            name="technology"
                            isMulti
                            value={selectedTechForEdit}
                            options={techOptions}
                            onChange={(selectedOption) => {
                              handleTechChangeForEdit(selectedOption);
                            }}
                            inputProps={{
                              style: {
                                height: "10px",
                              },
                            }}
                          />
                        </MDBox>
                        <MDBox mb={2} mt={2}>
                          <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            flexGrow={1}
                            style={{ fontWeight: "500" }}
                          >
                            Experience (In Year/s)
                          </MDTypography>

                          <MDInput
                            type="number"
                            name="yearsOfExperience"
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.yearsOfExperience}
                            error={
                              formik.touched.yearsOfExperience &&
                              formik.errors.yearsOfExperience &&
                              true
                            }
                          />
                          {formik.touched.yearsOfExperience &&
                            formik.errors.yearsOfExperience && (
                              <TextError
                                msg={formik.errors.yearsOfExperience}
                              />
                            )}
                        </MDBox>

                        <MDBox mt={2}>
                          <Collapse in={isErrorForEditProfile}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setisErrorForEditProfile(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Please check the details. Server error!
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessForEditProfile}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setisSuccessForEditProfile(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Profile edit successfully
                            </Alert>
                          </Collapse>
                        </MDBox>
                        <MDBox mt={2}>
                          <MDButton
                            variant="contained"
                            color="success"
                            fullWidth
                            type="submit"
                            disabled={!formik.isValid}
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      </MDBox>
                      <MDBox></MDBox>
                    </MDBox>
                  </MDCard>
                </Modal>
              </Grid>
            </MDBox>
          </>
        ) : (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        {!loading ? <p className=" font-size text-center text-md-left">
          Developed and maintained by :
          <a className="link" href="http://mobillor.com/">
            <strong style={{ color: "red" }}>
              {" "}
              Mobillor Technologies Pvt. Ltd.
            </strong>
          </a>
        </p> : null}
      </DashboardLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getUploadProfileReducer: state.getUploadProfileReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    deleteUploadProfile: (jwtToken, profileId) =>
      dispatch(deleteUploadProfile(jwtToken, profileId)),
    editUploadProfile: (jwtToken, payload) =>
      dispatch(editUploadProfile(jwtToken, payload)),
    getUploadProfile: (jwtToken, pageNo, rowsPerPage) =>
      dispatch(getUploadProfile(jwtToken, pageNo, rowsPerPage)),
    createPofile: (payload, jwtToken) =>
      dispatch(createPofile(payload, jwtToken)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(ViewUploadProfile);
