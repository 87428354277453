export const GET_ALL_ACCOUNTMANAGER_REQUEST = "GET_ALL_ACCOUNTMANAGER_REQUEST";
export const GET_ALL_ACCOUNTMANAGER_SUCCESS = "GET_ALL_ACCOUNTMANAGER_SUCCESS";
export const GET_ALL_ACCOUNTMANAGER_FAILURE = "GET_ALL_ACCOUNTMANAGER_FAILURE";

export const CREATE_ACCOUNT_MANAGER_REQUEST = "CREATE_ACCOUNT_MANAGER_REQUEST";
export const CREATE_ACCOUNT_MANAGER_SUCCESS = "CREATE_ACCOUNT_MANAGER_SUCCESS";
export const CREATE_ACCOUNT_MANAGER_FAILURE = "CREATE_ACCOUNT_MANAGER_FAILURE";

export const DELETE_ACCOUNTMANAGER_REQUEST = "DELETE_ACCOUNTMANAGER_REQUEST";
export const DELETE_ACCOUNTMANAGER_SUCCESS = "DELETE_ACCOUNTMANAGER_SUCCESS";
export const DELETE_ACCOUNTMANAGER_FAILURE = "DELETE_ACCOUNTMANAGER_FAILURE";

export const EDIT_ACCOUNTMANAGER_REQUEST = "EDIT_ACCOUNTMANAGER_REQUEST";
export const EDIT_ACCOUNTMANAGER_SUCCESS = "EDIT_ACCOUNTMANAGER_SUCCESS";
export const EDIT_ACCOUNTMANAGER_FAILURE = "EDIT_ACCOUNTMANAGER_FAILURE";

export const GET_ACCOUNTMANAGER_REQUEST = "GET_ACCOUNTMANAGER_REQUEST";
export const GET_ACCOUNTMANAGER_SUCCESS = "GET_ACCOUNTMANAGER_SUCCESS";
export const GET_ACCOUNTMANAGER_FAILURE = "GET_ACCOUNTMANAGER_FAILURE";
