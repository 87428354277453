/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

// react-router-dom components
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // cookies.remove("loginDetails", { path: "/" });
    // cookies.remove("jwtForMobillorEditor", { path: "/" });
    
    cookies.remove("mobillor-talent-accelerator-user-data");
    cookies.remove("isFormFilled");  
    

    navigate("/");
    // window.location.reload();
  }, []);

  return null;
}

export default Logout;
