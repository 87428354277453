/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";

// Dashboard components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Dashboard context
import { useMaterialUIController } from "context";

// react-router-dom components
import { NavLink, useLocation } from "react-router-dom";

function SidenavCollapse({ icon, name, active, type, subRoutes, ...rest }) {
  const [controller] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;

  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const [openExpand, setOpenExpand] = useState(false);
  const openExpandHandler = () => {
    setOpenExpand(!openExpand);
  };

  return type === "collapse" ? (
    <ListItem component="li">
      <MDBox
        {...rest}
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          })
        }
      >
        <ListItemIcon
          sx={(theme) =>
            collapseIconBox(theme, {
              transparentSidenav,
              whiteSidenav,
              darkMode,
              active,
            })
          }
        >
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>

        <ListItemText
          primary={name}
          sx={(theme) =>
            collapseText(theme, {
              miniSidenav,
              transparentSidenav,
              whiteSidenav,
              active,
            })
          }
        />
      </MDBox>
    </ListItem>
  ) : (
    <>
      <ListItem button onClick={openExpandHandler}>
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseItem(theme, {
              // active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            })
          }
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, {
                transparentSidenav,
                whiteSidenav,
                darkMode,
                // active,
              })
            }
          >
            <ListItemIcon
              sx={(theme) =>
                collapseIconBox(theme, {
                  transparentSidenav,
                  whiteSidenav,
                  darkMode,
                  // active,
                })
              }
            >
              {typeof icon === "string" ? (
                <Icon sx={(theme) => collapseIcon(theme)}>{icon}</Icon>
              ) : (
                icon
              )}
            </ListItemIcon>
          </ListItemIcon>
          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                // active,
              })
            }
          />
          {openExpand ? (
            <Icon fontSize="small">expand_less</Icon>
          ) : (
            <Icon fontSize="small">expand_more</Icon>
          )}
        </MDBox>
      </ListItem>
      <Collapse in={openExpand} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subRoutes.map((single) => (
            <ListItem
              button
              key={single.key}
              component={NavLink}
              to={single.route}
              style={{ paddingLeft: "15px" }}
            >
              <MDBox
                sx={(theme) =>
                  collapseItem(theme, {
                    active: single.key === collapseName ? true : false,
                    transparentSidenav,
                    whiteSidenav,
                    darkMode,
                    sidenavColor,
                  })
                }
              >
                <ListItemIcon
                  sx={(theme) =>
                    collapseIconBox(theme, {
                      transparentSidenav,
                      whiteSidenav,
                      darkMode,
                      active: single.key === collapseName ? true : false,
                    })
                  }
                >
                  {typeof single.icon === "string" ? (
                    <Icon
                      sx={(theme) =>
                        collapseIcon(theme, {
                          active: single.key === collapseName ? true : false,
                        })
                      }
                    >
                      {single.icon}
                    </Icon>
                  ) : (
                    single.icon
                  )}
                </ListItemIcon>
                <ListItemText
                  sx={(theme) =>
                    collapseText(theme, {
                      miniSidenav,
                      transparentSidenav,
                      whiteSidenav,
                      active: single.key === collapseName ? true : false,
                    })
                  }
                  primary={single.name}
                />
              </MDBox>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
