import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

// Utils component
import TextError from "utils/TextError";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Dashboard components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";
import PasswordStrengthMeter from "utils/PasswordStrengthMeter";
import PasswordStrengthForRegistration from "utils/PasswordStrengthForRegistration";

// React-Select component and styling
import Select from "react-select";
import "./style.css";

// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Dashboard layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Dashboard example components
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Functions from store
import {
  getOtp,
  validateOtp,
  clientRegister,
  getAllReference,
} from "../../../store";

// Router Components
import { useNavigate } from "react-router-dom";

// Images
import banner from "../../../assets/banner.jpg";
import regimg from "../../../assets/8401.jpg";
import Footer from "layouts/authentication/components/Footer";

// @mui material
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Card from "@mui/material/Card";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

const Registration = ({
  getOtp,
  otpReducer,
  validateOtp,
  clientRegister,
  getAllReference,
  allReferenceReducer,
}) => {
  const notify = () => {
    toast.error("Invaid OTP", {
      position: "top-center",
    });
  };
  const notifyForOtp = () => {
    toast.success("Check your Email", {
      position: "top-center",
    });
  };

  const notifyForEmailOtp = () => {
    toast.warning("Email already Exist", {
      position: "top-center",
    });
  };

  const notifyForFailOtp = () => {
    toast.error("Server error", {
      position: "top-center",
    });
  };

  const styleForOtp = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 310,
    boxShadow: 24,
    // p: 1,
    pt: 3,
  };
  const disab1 = {
    PointerEvent: "none",
  };
  const nodisabl = {
    pointer: "cursor",
  };
  const cookies = new Cookies();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const navigate = useNavigate();
  const [hash, setHash] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const query = window.location.search.substring(1);
  const uuid = query.split("=")[1];
  let [emailForOtp, setEmailForOtp] = useState("");

  useEffect(() => {
    cookies.remove("mobillor-talent-accelerator-user-data");
  }, []);

  useEffect(() => {
    getAllReference();
  }, [getAllReference]);

  const [referencedByOptions, setReferencedByOptions] = useState([]);

  useEffect(() => {
    let tempReferencedByOptions = [];
    let data = allReferenceReducer.allReference.data
      ? allReferenceReducer.allReference.data
      : [];
    data.map((reference) => {
      tempReferencedByOptions.push({
        label: reference.companyName,
        value: reference.companyName,
      });
    });
    setReferencedByOptions(tempReferencedByOptions);
  }, [allReferenceReducer]);

  const [selectedReferencedBy, setSelectedReferencedBy] = useState("");

  const handleReferencedBy = (selectedReferencedBy) => {
    setSelectedReferencedBy(selectedReferencedBy);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    let otpData = otpReducer.otpData;
    setHash(otpData.data?.data?.hash);
    setEmail(otpData.data?.data?.email);
  }, [otpReducer]);

  const intervalRef = useRef(null);
  const [timer, setTimer] = useState("00:00");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return { total, days, hours, minutes, seconds };
  }

  function startTimer(deadline) {
    let { total, minutes, seconds } = getTimeRemaining(deadline);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      clearInterval(intervalRef.current);
    }
  }

  function clearTimer(endtime) {
    setTimer("02:00");
    if (intervalRef.current) clearInterval(intervalRef.current);
    const id = setInterval(() => {
      startTimer(endtime);
    }, 1000);
    intervalRef.current = id;
  }
  function getDeadlineTime() {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  }
  const initialValues = {
    enteredNumber: "",
  };
  const validationSchema = Yup.object({
    enteredNumber: Yup.string()
      .required("Enter the OTP")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),
  });

  const onSubmit = async (values) => {
    let payload = {
      otp: values.enteredNumber,
      email: email,
      hash: hash,
    };
    let data = await validateOtp(payload);
    if (data.status === true) {
      setOpenOtpModal(false);
      setColor("green");
      setVerifyEmailError("");
      setEmailVerified(true);
    }
    if (!data.status) {
      notify();
      setErrorMessage("Enter a valid OTP");
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const initialValuesForGenerateOtp = {
    email: "",
  };
  const validationSchemaForGenerateOtp = Yup.object({
    email: Yup.string().required("Enter the Email"),
  });

  const onSubmitForGenerateOtp = async (values) => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    clearTimer(getDeadlineTime());
    setBtnDisabled(true);
    setShowMessage(true);
    let payload = values;
    setEmailForOtp(email);
    let response = await getOtp(payload);
    if (response.status === true) {
    }
    if (response.status === false) {
      setIsErrorForGenerateOtp(true);
    }
  };

  const formikForGenerateOtp = useFormik({
    initialValues: initialValuesForGenerateOtp,
    validationSchema: validationSchemaForGenerateOtp,
    onSubmit: onSubmitForGenerateOtp,
  });

  const initialValuesForClientRequest = {
    companyName: "",
    referencedBy: "",
    dataName: "",
    email: "",
    password: "",
  };

  const validationSchemaForClientRequest = Yup.object({
    companyName: Yup.string().required("Enter the Company Name"),
    referencedBy: Yup.string().required("Enter a Reference"),
    others: Yup.string().when("referencedBy", {
      is: "Others",
      then: Yup.string().required("Enter a Reference"),
    }),
    dataName: Yup.string().required("Enter the Full Name"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the Email"),
    password: Yup.string()
      .required("Enter the Password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
  });
  const [loadingForOpenOtpModel, setLoadingForOpenOtpModel] = useState(false);
  const [isErrorCreateUser, setIsErrorCreateUser] = useState(false);
  const [isSuccessCreateUser, setIsSuccessCreateUser] = useState(false);
  const [isErrorForEmail, setIsErrorForEmail] = useState(false);

  useEffect(() => {
    if (isSuccessCreateUser) {
      setTimeout(() => {
        setIsSuccessCreateUser(false);
      }, 3000);
    }
  }, [isSuccessCreateUser]);

  useEffect(() => {
    if (isErrorCreateUser) {
      setTimeout(() => {
        setIsErrorCreateUser(false);
      }, 3000);
    }
  }, [isErrorCreateUser]);

  const [isErrorForGenerateOtp, setIsErrorForGenerateOtp] = useState(false);
  const [isSuccessForGenerateOtp, setIsSuccessForGenerateOtp] = useState(false);

  const onSubmitForClientRequest = async (values, { resetForm }) => {
    values.referencedBy =
      selectedReferencedBy.value !== "Others"
        ? selectedReferencedBy.value
        : values.others;

    let response = await clientRegister(values);
    if (response.status === true) {
      setLoading(true);
      setIsSuccessCreateUser(true);
      setTimeout(() => {
        setLoading(false);
        navigate("/login");
      }, 3000);
    }
    if (response.status === false) {
      setIsErrorCreateUser(true);
    }
  };
  const formikForClientRequest = useFormik({
    initialValues: initialValuesForClientRequest,
    onSubmit: onSubmitForClientRequest,
    validationSchema: validationSchemaForClientRequest,
  });

  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [color, setColor] = useState("red");
  const [verifyEmailError, setVerifyEmailError] = useState(
    "Please verify the Email!"
  );
  const [emailVerified, setEmailVerified] = useState(false);
  const [active, setActive] = useState(false);

  const handleEmailChange = () => {
    setActive(true);
  };

  const handleOpenOtpModal = () => {
    setOpenOtpModal(true);
  };

  const handleCloseOtpModal = () => {
    setOpenOtpModal(false);
  };

  let emailOtp = formikForClientRequest.values.email;
  let name = formikForClientRequest.values.userName;
  let payload = {
    name: name,
    email: emailOtp,
  };
  const functionForOtp = async () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    clearTimer(getDeadlineTime());
    setBtnDisabled(true);
    setShowMessage(true);
    setLoadingForOpenOtpModel(true);
    const response = await getOtp(payload);
    setLoadingForOpenOtpModel(false);
    if (response.status === true) {
      setData(true);
      notifyForOtp();
      handleOpenOtpModal();
    } else if (response.data === "Request failed with status code 409") {
      notifyForEmailOtp();
    } else {
      notifyForFailOtp();
    }
  };

  const OTPHandler = async () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    clearTimer(getDeadlineTime());
    setBtnDisabled(true);
    setShowMessage(true);
    const response = await getOtp(payload);
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      height: "fit-content",
      maxHeight: 200,
      overflow: "auto",
    }),
  };

  let topbutton = "SIGN IN"
  let pagelink = "/login"
  let topbutton5 = "Existing customer?"
  return (
    <>

      <DefaultNavbar topbutton={topbutton} pagelink={pagelink} topbutton5={topbutton5} />
      <div className="container">
        <div className="form" style={{ marginTop: "60px" }}>
          <div className="contact-info"
          // className="color-display"
          >
            <MDTypography

              variant="h4"
              // marginTop="15px"
              marginTop="-10px"
              style={{ color: "#344767", fontSize: 25 }}
            >
              Mobillor Talent Accelerator
            </MDTypography>
            <MDTypography
              marginTop="10px"
              align="justify"
              color="black"
              style={{
                fontSize: "14px",
                fontWeight: 400,
                fontStyle: "italic",
                color: "#344767",
              }}
            >
              <b>
                Platform to Accelerate on Demand hiring of Qualified and
                Pre-Evaluated Technical Resources{" "}
              </b>
            </MDTypography>
            <MDTypography
              marginTop="10px"
              align="justify"
              color="black"
              style={{
                fontSize: "14px",
                fontWeight: 400,
                fontStyle: "italic",
                color: "#344767",
              }}
            >
              Mobillor Talent Accelerator gives an opportunity to Start Ups
              and Software Product Companies to quickly On Board right
              Technical Resources to meet their demands. Mobillor Talent
              Accelerator allows you to Post your Resource Demands, which will
              be reviewed and supported by dedicated Relationship Managers
              from Mobillor. With a pool of Pre-Qualified and Readily
              Available suitable candidates our relationship managers will
              help you with the entire process of evaluation and on Boarding.
              Register Now and Accelerate your On Demand Hiring of resources
            </MDTypography>
            <img src={regimg} className="img-display"
            // style={{ width: "100%" , height: "40%" , borderTopLeftRadius: "10px", marginTop: "-2px"   }} 
            />


          </div>

          <div class="contact-form">
            <MDBox>
              <MDBox pt={1} pb={1} overflow="auto">
                <>
                  <MDBox pb={3}>
                    <MDBox pb={3}>
                      <MDBox
                        component="form"
                        role="form"
                        onSubmit={formikForClientRequest.handleSubmit}
                      >
                        <MDTypography variant="h4" fontWeight="medium" mt={-2}>
                          Registration
                        </MDTypography>
                        <MDBox mb={1} mt={2}>
                          <MDInput
                            maxLength={10}
                            label="Full Name"
                            type="text"
                            name="dataName"
                            fullWidth
                            autoComplete="off"
                            onChange={formikForClientRequest.handleChange}
                            onBlur={formikForClientRequest.handleBlur}
                            value={formikForClientRequest.values.dataName}
                            error={
                              formikForClientRequest.touched.dataName &&
                              formikForClientRequest.errors.dataName &&
                              true
                            }
                          />
                          {formikForClientRequest.touched.dataName &&
                            formikForClientRequest.errors.dataName && (
                              <TextError
                                msg={formikForClientRequest.errors.dataName}
                              />
                            )}
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            label="Company Name"
                            type="text"
                            name="companyName"
                            fullWidth
                            autoComplete="off"
                            onChange={formikForClientRequest.handleChange}
                            onBlur={formikForClientRequest.handleBlur}
                            value={formikForClientRequest.values.companyName}
                            error={
                              formikForClientRequest.touched.companyName &&
                              formikForClientRequest.errors.companyName &&
                              true
                            }
                          />
                          {formikForClientRequest.touched.companyName &&
                            formikForClientRequest.errors.companyName && (
                              <TextError
                                msg={formikForClientRequest.errors.companyName}
                              />
                            )}
                        </MDBox>
                        <MDBox mb={2}>
                          <Select
                            className="select-css-for-reg-form"
                            placeholder="Select Reference..."
                            onBlur={() => {
                              formikForClientRequest.handleBlur({
                                target: { name: "referencedBy" },
                              });
                            }}
                            captureMenuScroll={false}
                            styles={customStyles}
                            name="referencedBy"
                            value={selectedReferencedBy}
                            options={referencedByOptions}
                            onChange={(selectedOption) => {
                              handleReferencedBy(selectedOption);
                              formikForClientRequest.handleChange(
                                "referencedBy"
                              )(selectedOption.value);
                            }}
                          />

                          {formikForClientRequest.touched.referencedBy &&
                            formikForClientRequest.errors.referencedBy && (
                              <TextError
                                msg={formikForClientRequest.errors.referencedBy}
                              />
                            )}
                        </MDBox>

                        {selectedReferencedBy.value === "Others" && (
                          <MDBox mb={2}>
                            <MDInput
                              label="Enter Reference Name"
                              type="text"
                              name="others"
                              fullWidth
                              autoComplete="off"
                              onChange={formikForClientRequest.handleChange}
                              onBlur={formikForClientRequest.handleBlur}
                              value={formikForClientRequest.values.others}
                              error={
                                formikForClientRequest.touched.others &&
                                formikForClientRequest.errors.others &&
                                true
                              }
                            />
                            {formikForClientRequest.touched.others &&
                              formikForClientRequest.errors.others && (
                                <TextError
                                  msg={formikForClientRequest.errors.others}
                                />
                              )}
                          </MDBox>
                        )}

                        {/* {
                          formikForClientRequest.values.dataName && */}
                        <MDBox mb={2}>
                          <MDInput
                            disabled={emailVerified === true}
                            label="Email"
                            type="email"
                            fullWidth
                            name="email"
                            autoComplete="off"
                            onChange={formikForClientRequest.handleChange}
                            onClick={handleEmailChange}
                            onBlur={formikForClientRequest.handleBlur}
                            value={formikForClientRequest.values.email}
                            error={
                              formikForClientRequest.touched.email &&
                              formikForClientRequest.errors.email &&
                              true
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{ marginRight: 19 }}
                                >
                                  {!formikForClientRequest.errors.email &&
                                    active ? (
                                    <IconButton
                                      onClick={() => {
                                        functionForOtp();
                                      }}
                                      edge="end"
                                      style={{
                                        // fontSize: "10px",
                                        fontWeight: "600",
                                        color: color,
                                        fontFamily: "sans-serif",
                                      }}
                                    >
                                      {color === "red" ? (
                                        <span style={{ fontSize: "10px" }}>
                                          VERIFY
                                        </span>
                                      ) : (
                                        <Tooltip title="VERIFIED">
                                          <CheckCircleIcon
                                            style={{ color: "green" }}
                                          />
                                        </Tooltip>
                                      )}
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      disabled
                                      onClick={() => {
                                        functionForOtp();
                                      }}
                                      edge="end"
                                      style={{
                                        // fontSize: "10px",
                                        fontWeight: "600",
                                        color: "grey",
                                        fontFamily: "sans-serif",
                                      }}
                                    >
                                      {color === "red" ? (
                                        <span style={{ fontSize: "10px" }}>
                                          VERIFY
                                        </span>
                                      ) : (
                                        <Tooltip title="VERIFIED">
                                          <CheckCircleIcon
                                            style={{ color: "green" }}
                                          />
                                        </Tooltip>
                                      )}
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />

                          {formikForClientRequest.touched.email &&
                            formikForClientRequest.errors.email && (
                              <TextError
                                msg={formikForClientRequest.errors.email}
                              />
                            )}

                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              fontWeight: "420",
                              fontStyle: "italic",
                            }}
                          >
                            {!formikForClientRequest.errors.email && active
                              ? verifyEmailError
                              : ""}
                          </span>
                        </MDBox>
                        {/* } */}

                        <MDBox mb={0}>
                          <MDInput
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            name="password"
                            onChange={formikForClientRequest.handleChange}
                            onBlur={formikForClientRequest.handleBlur}
                            value={formikForClientRequest.values.password}
                            error={
                              formikForClientRequest.touched.password &&
                              formikForClientRequest.errors.password &&
                              true
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{ marginRight: 10 }}
                                >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? (
                                      <Visibility style={{ width: 20 }} />
                                    ) : (
                                      <VisibilityOff style={{ width: 20 }} />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <PasswordStrengthForRegistration
                            password={formikForClientRequest.values.password}
                          />
                          {formikForClientRequest.touched.password &&
                            formikForClientRequest.errors.password && (
                              <TextError
                                msg={formikForClientRequest.errors.password}
                              />
                            )}
                        </MDBox>

                        <MDBox mb={2}>
                          <Collapse in={isErrorCreateUser}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorCreateUser(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Please check the details. Server error!
                            </Alert>
                          </Collapse>
                          <Collapse in={isSuccessCreateUser}>
                            <Alert
                              severity="success"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsSuccessCreateUser(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              New client registered successfully
                            </Alert>
                          </Collapse>
                          <Collapse in={isErrorForEmail}>
                            <Alert
                              severity="error"
                              action={
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setIsErrorForEmail(false);
                                  }}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              }
                            >
                              Please check your email domain!
                            </Alert>
                          </Collapse>
                        </MDBox>

                        <MDBox>
                          {color === "red" ? (
                            <MDButton
                              style={{ backgroundColor: "red", float: "right" }}
                              color="info"
                              type="submit"
                              disabled
                            >
                              Register
                            </MDButton>
                          ) : (
                            <MDButton
                              style={{ backgroundColor: "red", float: "right" }}
                              color="info"
                              type="submit"
                              disabled={!formikForClientRequest.isValid}
                            >
                              Register
                            </MDButton>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </>
              </MDBox>
            </MDBox>
          </div>
        </div>

        <Footer />


      </div>
      <MDBox>
          <Modal
            open={openOtpModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDCard
              // sx={styleForEdit}
              sx={styleForOtp}
            >
              <MDBox  mt={-1} display="flex" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium" flexGrow={1} align="center">
                  Verify Your Email
                </MDTypography>
                <MDBox> 
                  <Tooltip title="Close">
                    <IconButton
                      style={{
                        background: "whitesmoke",
                        color: "black",
                        borderRadius: 5,
                        fontSize: "medium",
                      }}
                      aria-label="close"
                      color="inherit"
                      onClick={handleCloseOtpModal}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>

              <hr />

              <MDBox>
                <MDBox>
                  <> 
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formik.handleSubmit}
                    >
                      <MDBox mb={2} mx={3} mt={-2} align="center">
                        <MDInput
                          label="Enter OTP"
                          type="text"
                          name="enteredNumber"
                          fullWidth
                          maxLength={6}
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.enteredNumber}
                          error={
                            formik.touched.enteredNumber &&
                            formik.errors.enteredNumber &&
                            true
                          }
                        /> 
                        {formik.touched.enteredNumber &&
                          formik.errors.enteredNumber && (
                            <TextError msg={formik.errors.enteredNumber} />
                          )}
                      </MDBox>
                      <MDBox align="center">
                        {showMessage && (
                          <MDTypography fontSize="15px">
                            Check your mail inbox for the OTP
                          </MDTypography>
                        )}
                        {showMessage && (
                          <MDTypography
                            marginBottom="-40px"
                            fontSize="15px"
                            align="center"
                          > 
                            OTP expires in {timer}
                          </MDTypography>
                        )}
                        <>
                          {timer === "00:00" ? (
                            <MDBox align="center">  
                          
                              
                            <MDButton
                            align="center"
                              color="info"
                              variant="outlined"
                              onClick={OTPHandler}
                              style={{
                                marginTop: "45px",
                               
                              }}
                            >
                              {btnDisabled ? "Resend OTP" : "Generate OTP"}
                            </MDButton>
                            </MDBox> 
                          ) : (
                            <MDBox align="center">   
                            <MDButton
                            align="center" 
                              disabled
                              color="info"
                              variant="outlined"
                              style={{
                                marginTop: "45px",
                                color: "#2196f3",
                              
                              }}
                            >
                              {btnDisabled ? "Resend OTP" : "Generate OTP"}
                            </MDButton>
                            </MDBox> 
                          )}
                        </>
                      </MDBox>
                      <MDBox  mt={2} align="center">
                        <MDButton
                          size="small"
                          variant="contained"
                          color="error"
                          type="submit"
                          disabled={!formik.isValid}
                        >
                          submit
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </>
                </MDBox>
              </MDBox>
            </MDCard>
          </Modal>
        </MDBox>

        {loading && (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )} 
        {loadingForOpenOtpModel && (
          <Backdrop
            sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

      <ToastContainer />

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    otpReducer: state.otpReducer,
    otpValidateReducer: state.otpValidateReducer,
    allReferenceReducer: state.allReferenceReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOtp: (payload) => dispatch(getOtp(payload)),
    validateOtp: (payload) => dispatch(validateOtp(payload)),
    clientRegister: (payload) => dispatch(clientRegister(payload)),
    getAllReference: () => dispatch(getAllReference()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
