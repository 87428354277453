/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export const ALL_CLIENT_REQUESTS_REQUEST = "ALL_CLIENT_REQUESTS_REQUEST";
export const ALL_CLIENT_REQUESTS_SUCCESS = "ALL_CLIENT_REQUESTS_SUCCESS";
export const ALL_CLIENT_REQUESTS_FAILURE = "ALL_CLIENT_REQUESTS_FAILURE";

export const ACCEPT_AND_REJECT_CLIENT_REQUESTS_REQUEST =
  "ACCEPT_AND_REJECT_CLIENT_REQUESTS_REQUEST";
export const ACCEPT_AND_REJECT_CLIENT_REQUESTS_SUCCESS =
  "ACCEPT_AND_REJECT_CLIENT_REQUESTS_SUCCESS";
export const ACCEPT_AND_REJECT_CLIENT_REQUESTS_FAILURE =
  "ACCEPT_AND_REJECT_CLIENT_REQUESTS_FAILURE";

export const CREATE_CLIENT_REQUESTS_REQUEST = "CREATE_CLIENT_REQUESTS_REQUEST";
export const CREATE_CLIENT_REQUESTS_SUCCESS = "CREATE_CLIENT_REQUESTS_SUCCESS";
export const CREATE_CLIENT_REQUESTS_FAILURE = "CREATE_CLIENT_REQUESTS_FAILURE";

export const GET_ALL_UNASSIGNED_CLIENTS_REQUEST =
  "GET_ALL_UNASSIGNED_CLIENTS_REQUEST";
export const GET_ALL_UNASSIGNED_CLIENTS_SUCCESS =
  "GET_ALL_UNASSIGNED_CLIENTS_SUCCESS";
export const GET_ALL_UNASSIGNED_CLIENTS_FAILURE =
  "GET_ALL_UNASSIGNED_CLIENTS_FAILURE";

export const ASSIGN_CLIENT_REQUEST = "ASSIGN_CLIENT_REQUEST";
export const ASSIGN_CLIENT_SUCCESS = "ASSIGN_CLIENT_SUCCESS";
export const ASSIGN_CLIENT_FAILURE = "ASSIGN_CLIENT_FAILURE";

export const GET_CLIENTS_BY_CLIENTID_REQUEST = "ALL_CLIENT_REQUESTS_REQUEST";
export const GET_CLIENTS_BY_CLIENTID_SUCCESS =
  "GET_CLIENTS_BY_CLIENTID_SUCCESS";
export const GET_CLIENTS_BY_CLIENTID_FAILURE =
  "GET_CLIENTS_BY_CLIENTID_FAILURE";

export const GET_ALL_CLIENT_REQUEST = "GET_ALL_CLIENT_REQUEST";
export const GET_ALL_CLIENT_SUCCESS = "GET_ALL_CLIENT_SUCCESS";
export const GET_ALL_CLIENT_FAILURE = "GET_ALL_CLIENT_FAILURE";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

export const GET_CLIENT_BY_ID_REQUEST = "GET_CLIENT_BY_ID_REQUEST";
export const GET_CLIENT_BY_ID_SUCCESS = "GET_CLIENT_BY_ID_SUCCESS";
export const GET_CLIENT_BY_ID_FAILURE = "GET_CLIENT_BY_ID_FAILURE";

export const BLOCK_CLIENT_REQUEST = "BLOCK_CLIENT_REQUEST";
export const BLOCK_CLIENT_SUCCESS = "BLOCK_CLIENT_SUCCESS";
export const BLOCK_CLIENT_FAILURE = "BLOCK_CLIENT_FAILURE";
