/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_UPLOAD_PROFILE_REQUEST,
  GET_UPLOAD_PROFILE_SUCCESS,
  GET_UPLOAD_PROFILE_FAILURE,
  GET_ALL_PROFILE_REQUEST,
  GET_ALL_PROFILE_SUCCESS,
  GET_ALL_PROFILE_FAILURE,
  GET_PROFILE_BY_CLIENTID_REQUEST,
  GET_PROFILE_BY_CLIENTID_SUCCESS,
  GET_PROFILE_BY_CLIENTID_FAILURE,
  PROFILES_NOTIFICATION_REQUEST,
  PROFILES_NOTIFICATION_SUCCESS,
  PROFILES_NOTIFICATION_FAILURE,
} from "./uploadProfileTypes";

const initialState = {
  loading: false,
  profileData: {},
  error: "",
};

const getUploadProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UPLOAD_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_UPLOAD_PROFILE_SUCCESS:
      return {
        loading: false,
        profileData: action.payload,
        error: "",
      };
    case GET_UPLOAD_PROFILE_FAILURE:
      return {
        loading: false,
        profileData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForProfile = {
  loading: false,
  profileData: {},
  error: "",
};

const getAllProfileReducer = (state = initialStateForProfile, action) => {
  switch (action.type) {
    case GET_ALL_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PROFILE_SUCCESS:
      return {
        loading: false,
        profileData: action.payload,
        error: "",
      };
    case GET_ALL_PROFILE_FAILURE:
      return {
        loading: false,
        profileData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForNotification = {
  loading: false,
  profileData: {},
  error: "",
};

const profilesNotificationReducer = (
  state = initialStateForNotification,
  action
) => {
  switch (action.type) {
    case PROFILES_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROFILES_NOTIFICATION_SUCCESS:
      return {
        loading: false,
        profileData: action.payload,
        error: "",
      };
    case PROFILES_NOTIFICATION_FAILURE:
      return {
        loading: false,
        profileData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialState2 = {
  loading: false,
  profileData: {},
  error: "",
};

const getProfileByClientIdReducer = (state = initialState2, action) => {
  switch (action.type) {
    case GET_PROFILE_BY_CLIENTID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE_BY_CLIENTID_SUCCESS:
      return {
        loading: false,
        profileData: action.payload,
        error: "",
      };
    case GET_PROFILE_BY_CLIENTID_FAILURE:
      return {
        loading: false,
        profileData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export {
  getUploadProfileReducer,
  getProfileByClientIdReducer,
  profilesNotificationReducer,
  getAllProfileReducer,
};
