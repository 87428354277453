export const GET_ASSIGN_CLIENT_REQUEST = "GET_ASSIGN_CLIENT_REQUEST";
export const GET_ASSIGN_CLIENT_SUCCESS = "GET_ASSIGN_CLIENT_SUCCESS";
export const GET_ASSIGN_CLIENT_FAILURE = "GET_ASSIGN_CLIENT_FAILURE";

export const GET_PROFILES_REQUEST = "GET_PROFILES_REQUEST";
export const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS";
export const GET_PROFILES_FAILURE = "GET_PROFILES_FAILURE";

export const CREATE_PROFILE_REQUEST = "CREATE_PROFILE_REQUEST";
export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS";
export const CREATE_PROFILE_FAILURE = "CREATE_PROFILE_FAILURE";

export const CREATE_ASSIGNPROFILES_REQUEST = "CREATE_ASSIGNPROFILES_REQUEST";
export const CREATE_ASSIGNPROFILES_SUCCESS = "CREATE_ASSIGNPROFILES_SUCCESS";
export const CREATE_ASSIGNPROFILES_FAILURE = "CREATE_ASSIGNPROFILES_FAILURE";
