/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Dashboard components
import MDBox from "./components/MDBox";

// Dashboard example components
import Sidenav from "./examples/Sidenav";
import SidenavForAdmin from "./examples/Sidenav/SidenavForAdmin";
import Configurator from "./examples/Configurator";
import NumberFormat from "react-number-format";

// Dashboard themes
import theme from "./assets/theme";

// Dashboard Dark Mode themes
import themeDark from "./assets/theme-dark";

// Dashboard routes
import routes from "./routes";

// Dashboard contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "./context";

// Dashboard layouts (includes sub route components)
import Profile from "./layouts/profile";
import Login from "./layouts/authentication/login";
import Logout from "./layouts/authentication/logout";
import ResetPassword from "./layouts/authentication/reset-password";
import SetPassword from "./layouts/authentication/set-password";
import ForgotPassword from "./layouts/authentication/forgot-password";
import Registration from "layouts/authentication/registration/index";
import FormPreview from "layouts/client-form/FormPreview";

import Tooltip from "@mui/material/Tooltip";
// JWT and Cookies
import { isExpired } from "react-jwt";
import Cookies from "universal-cookie";
import routesForAdmin from "routesForAdmin";
import routesForAccount from "./routesForAccount";
import ClientForm from "layouts/client-form";
const cookies = new Cookies();

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Login details from cookies

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="none"
      justifyContent="center"
      alignItems="center"
      width="1.25rem"
      height="1.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="1rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Tooltip title="Settings">
        <Icon fontSize="small" color="inherit">
          settings
        </Icon>
      </Tooltip>
    </MDBox>
  );

  let data = cookies.get("mobillor-talent-accelerator-user-data");
  

  let forgotPasswordData = cookies.get(
    "forgot-password-mobillor-talent-accelerator"
  );

  let token = cookies.get("mta-jwtToken");
  const isMyTokenExpired = isExpired(token);

  

  if (data) {
    if (data.status) {
      if (data.data.data.roleName === "Admin") {
        if(data.data.data.isLastLogin === true) {
        return (
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand
                  routes={routesForAdmin}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
                {configsButton}
              </>
            )}
            {layout === "vr" && <Configurator />}
            <Routes>
              {getRoutes(routesForAdmin)}
              <Route
                path="*"
                element={<Navigate to="/resource-requests" replace />}
              />
              <Route
                exact
                path="/profile"
                element={<Profile />}
                key="profile"
              />
              <Route
                exact
                path="/authentication/reset-password"
                element={<ResetPassword />}
                key="resetPassword"
              />
            </Routes>
          </ThemeProvider>
        );
      }
      else {
       return (
         <ThemeProvider theme={darkMode ? themeDark : theme}>
           <CssBaseline />
           {layout === "dashboard" && (
             <>
               <Configurator />
               {configsButton}
             </>
           )}
           {layout === "vr" && <Configurator />}
           <Routes>
             {/* <Route
               exact
               path="/registration"
               element={<Registration />}
               key="registration"
             /> */}
    <Route
               exact
               path="/client-form"
               element={<ClientForm/>}
               key="client-form"
               />
             <Route
               exact
               path="/authentication/set-password"
               element={<SetPassword />}
               key="set-password"
             />
             <Route
               exact
               path="/set-new-password"
               element={<ForgotPassword />}
               key="set-new-password"
             />
             {/* <Route exact path="/" element={<Registration />} key="registration" /> */}
             <Route exact path="/login" element={<Login />} key="login" />
             {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
           </Routes>
         </ThemeProvider>
       );
      }
      }

      if (data.data.data.roleName === "Client") {
        if(data.data.data.isLastLogin === true) {
          return (
            <ThemeProvider theme={darkMode ? themeDark : theme}>
              <CssBaseline />
              {layout === "dashboard" && (
                <>
                  <Sidenav
                    color={sidenavColor}
                    brand
                    routes={routes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />
                  <Configurator />
                  {configsButton}
                </>
              )}
              {layout === "vr" && <Configurator />}
              <Routes>
                {getRoutes(routes)}
                <Route
                  exact
                  path="/profile"
                  element={<Profile />}
                  key="profile"
                />
                <Route
                  exact
                  path="/authentication/reset-password"
                  element={<ResetPassword />}
                  key="resetPassword"
                />
                <Route
                  exact
                  path="/preview"
                  element={<FormPreview />}
                  key="preview"
                />
                <Route
                  path="*"
                  element={<Navigate to="/resource-request" replace />}
                />
              </Routes>
            </ThemeProvider>
          );
        }
       else {
        return (
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {layout === "dashboard" && (
              <>
                <Configurator />
                {configsButton}
              </>
            )}
            {layout === "vr" && <Configurator />}
            <Routes>
              {/* <Route
                exact
                path="/registration"
                element={<Registration />}
                key="registration"
              /> */}
     <Route
                exact
                path="/client-form"
                element={<ClientForm/>}
                key="client-form"
                />
              <Route
                exact
                path="/authentication/set-password"
                element={<SetPassword />}
                key="set-password"
              />
              <Route
                exact
                path="/set-new-password"
                element={<ForgotPassword />}
                key="set-new-password"
              />
              {/* <Route exact path="/" element={<Registration />} key="registration" /> */}
              <Route exact path="/login" element={<Login />} key="login" />
              {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
            </Routes>
          </ThemeProvider>
        );
       }
      }
      
      if (data.data.data.roleName === "AccountManager") {
        if(data.data.data.isLastLogin === true) {
        return (
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand
                  routes={routesForAccount}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
                {configsButton}
              </>
            )}
            {layout === "vr" && <Configurator />}
            <Routes>
              {getRoutes(routesForAccount)}
              <Route
                exact
                path="/profile"
                element={<Profile />}
                key="profile"
              />
              <Route
                exact
                path="/authentication/reset-password"
                element={<ResetPassword />}
                key="resetPassword"
              />
              <Route
                path="*"
                element={<Navigate to="/resource-request" replace />}
              />
            </Routes>
          </ThemeProvider>
        );
      }
      else {
       return (
         <ThemeProvider theme={darkMode ? themeDark : theme}>
           <CssBaseline />
           {layout === "dashboard" && (
             <>
               <Configurator />
               {configsButton}
             </>
           )}
           {layout === "vr" && <Configurator />}
           <Routes>
             {/* <Route
               exact
               path="/registration"
               element={<Registration />}
               key="registration"
             /> */}
    <Route
               exact
               path="/client-form"
               element={<ClientForm/>}
               key="client-form"
               />
             <Route
               exact
               path="/authentication/set-password"
               element={<SetPassword />}
               key="set-password"
             />
             <Route
               exact
               path="/set-new-password"
               element={<ForgotPassword />}
               key="set-new-password"
             />
             {/* <Route exact path="/" element={<Registration />} key="registration" /> */}
             <Route exact path="/login" element={<Login />} key="login" />
             {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
           </Routes>
         </ThemeProvider>
       );
      }
      }
    } else {
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <Routes>
          <Route
            exact
            path="/authentication/logout"
            element={<Logout />}
            key="logout"
          />
          <Route
            exact
            path="/authentication/reset-password"
            element={<ResetPassword />}
            key="resetPassword"
          />
          <Route
            path="*"
            element={<Navigate to="/authentication/logout" replace />}
          />
        </Routes>
      </ThemeProvider>;
    }
  } else if (!forgotPasswordData) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {/* <Route
            exact
            path="/registration"
            element={<Registration />}
            key="registration"
          /> */}
            <Route
            exact
            path="/client-form"
            element={<ClientForm/>}
            key="client-form"
            />
             {/* <Route
            exact
            path="/authentication/set-password"
            element={<SetPassword />}
            key="set-password"
          /> */}
          <Route
            exact
            path="/set-new-password"
            element={<Navigate to="/" replace />}
            key="set-new-password"
          />
          <Route exact path="/" element={<Registration />} key="registration" />
          <Route exact path="/login" element={<Login />} key="login" />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  } else if (forgotPasswordData) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {/* <Route
            exact
            path="/registration"
            element={<Registration />}
            key="registration"
          /> */}
           <Route
            exact
            path="/client-form"
            element={<ClientForm/>}
            key="client-form"
            />
          {/* <Route
            exact
            path="/authentication/set-password"
            element={<SetPassword />}
            key="set-password"
          /> */}
          <Route
            exact
            path="/set-new-password"
            element={<ForgotPassword />}
            key="set-new-password"
          />
          <Route exact path="/" element={<Registration />} key="registration" />
          <Route exact path="/login" element={<Login />} key="login" />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  } else if (forgotPasswordData) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {/* <Route
            exact
            path="/registration"
            element={<Registration />}
            key="registration"
          /> */}
 <Route
            exact
            path="/client-form"
            element={<ClientForm/>}
            key="client-form"
            />
          {/* <Route
            exact
            path="/authentication/set-password"
            element={<SetPassword />}
            key="set-password"
          /> */}
          <Route
            exact
            path="/set-new-password"
            element={<ForgotPassword />}
            key="set-new-password"
          />
          <Route exact path="/" element={<Registration />} key="registration" />
          <Route exact path="/login" element={<Login />} key="login" />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {/* <Route
            exact
            path="/registration"
            element={<Registration />}
            key="registration"
          /> */}
 <Route
            exact
            path="/client-form"
            element={<ClientForm/>}
            key="client-form"
            />
          <Route
            exact
            path="/authentication/set-password"
            element={<Navigate to="/" replace />}
            key="set-password"
          />
          <Route
            exact
            path="/set-new-password"
            element={<ForgotPassword />}
            key="set-new-password"
          />
          {/* <Route exact path="/" element={<Registration />} key="registration" /> */}
          <Route exact path="/login" element={<Login />} key="login" />
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </ThemeProvider>
    );
  }
}