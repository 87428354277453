import {
  GET_OTP_FAILURE,
  GET_OTP_SUCCESS,
  GET_OTP_REQUEST,
  PUT_OTP_VALIDATE_REQUEST,
  PUT_OTP_VALIDATE_SUCCESS,
  PUT_OTP_VALIDATE_FAILURE,
  CLIENT_REGISTER_SUCCESS,
  CLIENT_REGISTER_FAILURE,
  CLIENT_REGISTER_REQUEST,
  GET_CONTENT_FOR_REGISTRATION_SUCCESS ,
  GET_CONTENT_FOR_REGISTRATION_FAILURE ,
  GET_CONTENT_FOR_REGISTRATION_REQUEST
} from "./otpTypes";
import axios from "axios";
import Config from "../../config/index";
let auth3Url = Config.auth3Url;
let baseUrl = Config.baseUrl;

const getOtpRequest = () => {
  return {
    type: GET_OTP_REQUEST,
  };
};

const getOtpSuccess = (payload) => {
  return {
    type: GET_OTP_SUCCESS,
    payload: payload,
  };
};

const getOtpFaliure = (error) => {
  return {
    type: GET_OTP_FAILURE,
    payload: error,
  }; 
}; 

const getOtp = (payload) => {
  return (dispatch) => {
    dispatch(getOtpRequest());
    let url = `${baseUrl}/clients/generate_otp?email=${payload.email}&name=${payload.name}`;
    return axios
      .post(url) 
      .then((response) => {
        dispatch(getOtpSuccess(response));
        return { status: true, data: response };
      })
      .catch((err) => {
        dispatch(getOtpFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};








const getContentForRegistrationRequest = () => {
  return {
    type: GET_CONTENT_FOR_REGISTRATION_REQUEST,
  };
};

const getContentForRegistrationSuccess = (payload) => {
  return {
    type: GET_CONTENT_FOR_REGISTRATION_SUCCESS,
    payload: payload,
  };
};

const getContentForRegistrationFaliure = (error) => {
  return {
    type: GET_CONTENT_FOR_REGISTRATION_FAILURE,
    payload: error,
  }; 
}; 

const getContentForRegistration = (payload) => {
  return (dispatch) => {
    dispatch(getContentForRegistrationRequest());
    let url = `${baseUrl}/clients/generate_otp?email=${payload.email}&name=${payload.name}`;
    return axios
      .post(url) 
      .then((response) => {
        dispatch(getOtpSuccess(response));
        return { status: true, data: response };
      })
      .catch((err) => {
        dispatch(getOtpFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};
















const getOtpValidateRequest = () => {
  return {
    type: PUT_OTP_VALIDATE_REQUEST,
  };
};

const getOtpValidateSuccess = (payload) => {
  return {
    type: PUT_OTP_VALIDATE_SUCCESS,
    payload: payload,
  };
};

const getOtpValidateFaliure = (error) => {
  return {
    type: PUT_OTP_VALIDATE_FAILURE,
    payload: error,
  };
};

const validateOtp = (payload) => {
  let Payload = {
    email : payload.email,
    otp:payload.otp ,
    hash:payload.hash
  } 
  return (dispatch) => {
    dispatch(getOtpValidateRequest());
    let url = `${baseUrl}/clients/validate_otp`;
    return axios
      .post(url , Payload)
      .then((response) => {
        dispatch(getOtpValidateSuccess(response));
        return { status: true, data: response };
      })
      .catch((err) => {
        dispatch(getOtpValidateFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

//  for client register
const clientRegisterRequest = () => {
  return {
    type: CLIENT_REGISTER_REQUEST,
  };
};

const clientRegisterSuccess = (payload) => {
  return {
    type: CLIENT_REGISTER_SUCCESS,
    payload: payload,
  };
};
                    
const clientRegisterFaliure = (error) => {
  return {
    type: CLIENT_REGISTER_FAILURE,
    payload: error,
  };
};
     
const clientRegister = (payload, jwtToken) => {
  let headers = {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  };
 
 let password = "";
  password = payload.password ? `${payload.password}` : "";

  let payload1 = {
    email: payload.email,
    userName:payload.dataName,
    companyName:payload.companyName, 
    referencedBy:payload.referencedBy
  }  
  payload.password && (payload1.password = payload.password);
  return (dispatch) => {
    dispatch(clientRegisterRequest());
    let url = `${baseUrl}/clients/new_request`; 
    return axios 
      .post(url, payload1, headers)
      .then((response) => {
        dispatch(clientRegisterSuccess(response));
        return { status: true, data: response };
      })
      .catch((err) => {
        dispatch(clientRegisterFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};
 
export { getOtp, validateOtp, clientRegister };

